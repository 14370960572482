import { React, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./errorDialog.scss";
import { Button, DialogActions, IconButton, Stack } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";

const ErrorDialog = ({ currentNode }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const isErrorDialogOpen = useSelector((state) => state.isErrorDialogOpen);
  const nodesErrorMsg = useSelector((state) => state.nodesErrorMsg);
  const nodeConfigurations = useSelector((state) => state.nodeConfigurations);
  const dispatch = useDispatch();
  const { updateErrorDialogStatus } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const buttonStyles = {
    color: "white",
    background: "#69c2d2",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "28px",
    textTransform: "none",
    ":hover": {
      background: "#40bcd2",
    },
  };

  useEffect(() => {
    initializeDialogTitle();
    intializeErrorMessage();
  }, []);

  const initializeDialogTitle = () => {
    const dlgTitle = nodeConfigurations[currentNode.id].name
      ? nodeConfigurations[currentNode.id].name
      : currentNode.data.label;
    setDialogTitle(dlgTitle);
  };

  const intializeErrorMessage = () => {
    setErrorMessage(nodesErrorMsg[currentNode.id]);
  };

  const handleClose = () => {
    updateErrorDialogStatus(false);
  };

  return (
    <Dialog
      fullWidth
      open={isErrorDialogOpen}
      aria-labelledby="Error"
      className="error-dialog"
      maxWidth="sm"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
          maxHeight: "calc(100% - 122px)",
        },
      }}
    >
      <DialogTitle id="dlg-title">
        <Stack direction="row" justifyContent="space-between">
          Erron in {dialogTitle}
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={handleClose}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <DialogContentText className="white-text">
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          sx={buttonStyles}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
