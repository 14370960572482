import { React } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./projectInfoDialog.scss";
import { Grid } from "@mui/material";

const ProjectInfoDialog = () => {
  const showProjectInfoDialog = useSelector(
    (state) => state.showProjectInfoDialog
  );
  const projectData = useSelector((state) => state.exploredProjectInfo);
  const projectName = useSelector((state) => state.currentProjName);
  const projectDescription = useSelector((state) => state.currentFgDesc);
  const projectStatus = useSelector((state) => state.currentProjectStatus);

  const dispatch = useDispatch();
  const { updateShowProjectInfoDialog } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const handleClose = () => {
    updateShowProjectInfoDialog(false);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={showProjectInfoDialog}
        className="projectInfo-dialog"
        aria-labelledby="Project Details"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Project Details"}
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={handleClose}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid className="project-data">
            <Grid container className="project-row">
              <Grid item xs={6} className="project-heading">
                Name
              </Grid>
              <Grid item xs={6} className="project-detail">
                {projectName}
              </Grid>
            </Grid>
            <Grid container className="project-row">
              <Grid item xs={6} className="project-heading">
                Description
              </Grid>
              <Grid item xs={6} className="project-detail">
                {projectDescription}
              </Grid>
            </Grid>
            <Grid container className="project-row">
              <Grid item xs={6} className="project-heading">
                Project Key
              </Grid>
              <Grid item xs={6} className="project-detail">
                {projectData.projectKey}
              </Grid>
            </Grid>
            <Grid container className="project-row">
              <Grid item xs={6} className="project-heading">
                Project Version
              </Grid>
              <Grid item xs={6} className="project-detail">
                {projectData.projVersion}
              </Grid>
            </Grid>
            <Grid container className="project-row">
              <Grid item xs={6} className="project-heading">
                Feature Group
              </Grid>
              <Grid item xs={6} className="project-detail">
                {projectData.projFg}
              </Grid>
            </Grid>
            <Grid container className="project-row">
              <Grid item xs={6} className="project-heading">
                Project Status
              </Grid>
              <Grid item xs={6} className="project-detail">
                {projectStatus}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProjectInfoDialog;
