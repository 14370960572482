import { React, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Tooltip, TextField } from "@mui/material";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/crossIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trashIcon.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/checkIcon.svg";
import "./configureVariablesDialog.scss";
import api from "../../apiInterceptor";
import { getConfigureVariablesdata, showSuccessAlert } from "utils/utils";
import {
  buttonStyles,
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  textFieldStyles,
  iconButtonStyles,
} from "./configureVariablesDialogStyle";
const savevarinfoAPITestData = require("../../assets/apiTestData/success-test-data.json");

const ConfigureVariablesDialog = () => {
  const dialogTitle = "Configure Variables";
  const isConfigureVariablesDialogOpen = useSelector(
    (state) => state.isConfigureVariablesDialogOpen
  );
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editObject, setEditObject] = useState(null);
  const [isAddVariable, setIsAddVariable] = useState(false);
  const [valueError, setValueError] = useState(null);
  const [addVariableObject, setAddVariableObject] = useState({
    name: "",
    dataType: "int",
    value: "",
    description: "",
  });
  const dataTypes = ["int", "float", "double", "string"];
  const dispatch = useDispatch();
  const {
    updateConfigureVariablesDialogStatus,
    updateIsLoading,
    updateAlertMessage,
    updateSuccessAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );

  useEffect(() => {
    getVariablesdata();
  }, []);

  const getVariablesdata = async () => {
    const data = await getConfigureVariablesdata(
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      updateIsLoading,
      updateAlertMessage
    );
    if (!data) {
      updateAlertMessage("Something went wrong. Please try again later");
      handleClose();
      return;
    }
    if (Array.isArray(data)) {
      const dataArray = convertArraysToStrings(data);
      setData(dataArray);
    }
  };

  const convertArraysToStrings = (dataArray) => {
    for (const obj of dataArray) {
      if (Array.isArray(obj.value)) {
        obj.value = obj.value.map((item) => item.toString()).join(",");
      } else if (obj.dataType !== "string") {
        obj.value = obj.value.toString();
      }
    }
    return dataArray;
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (
      name === "name" &&
      !(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))
    ) {
      return;
    } else if (name === "description" && !/^[a-zA-Z\s]*$/.test(value)) {
      return;
    }
    if (valueError) {
      setValueError(null);
    }
    const newAddVariableObject = structuredClone(addVariableObject);
    newAddVariableObject[name] = value;
    setAddVariableObject(newAddVariableObject);
  };

  const addVariable = () => {
    if (!isVariableObjectValueValid(addVariableObject)) {
      return;
    }
    setValueError(null);
    const newData = structuredClone(data);
    newData.unshift(addVariableObject);
    setData(newData);
    cancelAddingVariable();
  };

  const cancelAddingVariable = () => {
    setAddVariableObject({
      name: "",
      dataType: "int",
      value: "",
      description: "",
    });
    setIsAddVariable(false);
  };

  const handleEditInputChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    if (
      name === "name" &&
      !(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))
    ) {
      return;
    } else if (name === "description" && !/^[a-zA-Z\s]*$/.test(value)) {
      return;
    }
    if (valueError) {
      setValueError(null);
    }
    const newData = structuredClone(data);
    newData[index][name] = value;
    setData(newData);
  };

  const editVariable = (index) => {
    setEditIndex(index);
    setEditObject(data[index]);
  };

  const closeEditingVariable = (index) => {
    const newData = structuredClone(data);
    newData[index] = editObject;
    setData(newData);
    setEditIndex(-1);
    setEditObject(null);
  };

  const saveEditedVariable = (index) => {
    if (!isVariableObjectValueValid(data[index])) {
      return;
    }
    setValueError(null);
    setEditIndex(-1);
  };

  const deleteVariable = (index) => {
    const newData = structuredClone(data);
    newData.splice(index, 1);
    setData(newData);
  };

  const isVariableObjectValueValid = (obj) => {
    const valuesArray = obj.value.split(",");
    for (const value of valuesArray) {
      const isValid = validateValueWithType(value.trim(), obj.dataType);
      if (!isValid) {
        setValueError(`Invalid ${obj.dataType} value`);
        return false;
      }
    }
    return true;
  };

  const validateValueWithType = (value, dataType) => {
    switch (dataType) {
      case "int":
        return /^\d+$/.test(value);
      case "float":
      case "double":
        return /^-?\d+(\.\d+)?$/.test(value);
      case "string":
        return typeof value === "string";
      default:
        return true;
    }
  };

  const convertStringsToArrays = (dataArray) => {
    return dataArray.map((obj) => {
      if (obj.value.includes(",")) {
        const values = obj.value.split(",");
        obj.value = values.map((item) =>
          obj.dataType === "string" ? item.trim() : parseFloat(item)
        );
      } else if (obj.dataType !== "string") {
        obj.value = parseFloat(obj.value);
      }
      return obj;
    });
  };

  const handleSave = async () => {
    const dataArray = convertStringsToArrays(structuredClone(data));
    const apiUrl = BASE_API_URL + "savevarinfo";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: selectedProjectKey,
      projVersion: selectedProjVersion,
      projFg: selectedFeatureGroup,
      varInfo: dataArray,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: savevarinfoAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        handleClose();
        const msg = "Variables saved successfully!";
        showSuccessAlert(msg, updateSuccessAlertMessage);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleClose = () => {
    updateConfigureVariablesDialogStatus(false);
  };

  return (
    <Dialog
      fullWidth
      open={isConfigureVariablesDialogOpen}
      maxWidth="lg"
      aria-labelledby="Configure Variables"
      className="configure-variables-dialog"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
          maxHeight: "calc(100% - 122px)",
        },
      }}
    >
      <DialogTitle id="configure-variables-title">
        <div className="">{dialogTitle}</div>
        <div className="icons-container">
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={handleClose}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {!isAddVariable && (
          <Stack alignItems="end">
            <Button
              autoFocus
              variant="contained"
              sx={buttonStyles}
              onClick={() => setIsAddVariable(true)}
              disabled={editIndex !== -1 || userAccessRestricted}
            >
              Add variable
            </Button>
          </Stack>
        )}
        {isAddVariable && (
          <Stack>
            <Stack
              className="add-variable-container"
              direction="row"
              gap="10px"
              marginTop="20px"
            >
              <TextField
                type="text"
                name="name"
                size="small"
                label="Name"
                autoComplete="off"
                value={addVariableObject.name}
                onChange={handleChange}
                variant="outlined"
                sx={textFieldStyles}
                className="input-box"
              />
              <FormControl
                className="w-100"
                sx={formControlStyles}
                size="small"
              >
                <InputLabel sx={labelStyles}>DataType</InputLabel>
                <Select
                  name="dataType"
                  size="small"
                  label="DataType"
                  value={addVariableObject.dataType}
                  onChange={handleChange}
                  variant="outlined"
                  sx={selectStyles}
                  className="input-box"
                  MenuProps={{ sx: selectStyles }}
                >
                  {dataTypes.map((value, index) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={index + "-data-type"}
                      value={value}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                type="text"
                name="value"
                size="small"
                label="Value"
                value={addVariableObject.value}
                onChange={handleChange}
                variant="outlined"
                sx={textFieldStyles}
                autoComplete="off"
                className="input-box"
                error={valueError}
                helperText={valueError}
              />
              <TextField
                type="text"
                name="description"
                size="small"
                label="Description"
                value={addVariableObject.description}
                onChange={handleChange}
                variant="outlined"
                sx={textFieldStyles}
                autoComplete="off"
                className="input-box"
              />
            </Stack>
            <Stack
              justifyContent="end"
              direction="row"
              gap="10px"
              flexDirection="row"
              marginTop="10px"
            >
              <Button
                autoFocus
                variant="contained"
                sx={buttonStyles}
                onClick={addVariable}
                disabled={
                  !addVariableObject.name ||
                  !addVariableObject.dataType ||
                  !addVariableObject.value ||
                  !addVariableObject.description ||
                  editIndex !== -1
                }
              >
                Add variable
              </Button>
              <Button
                autoFocus
                variant="contained"
                sx={buttonStyles}
                onClick={cancelAddingVariable}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        )}
        <Stack className="table" flexDirection={"column"}>
          <Stack className="table-header" flexDirection={"row"}>
            <Box className="header-item">Name</Box>
            <Box className="header-item">DataType</Box>
            <Box className="header-item">Value</Box>
            <Box className="header-item">Description</Box>
            <Box className="header-item actions-header">Actions</Box>
          </Stack>
          {data.length > 0 &&
            data.map((input, index) => (
              <Stack
                key={index}
                className={
                  "table-row " +
                  (valueError && editIndex === index ? "edit-row" : "")
                }
                flexDirection={"row"}
              >
                {editIndex === index ? (
                  <TextField
                    type="text"
                    name="name"
                    size="small"
                    placeholder="Name"
                    value={input.name}
                    onChange={(e) => handleEditInputChange(e, index)}
                    variant="outlined"
                    sx={textFieldStyles}
                    autoComplete="off"
                    className="input-box"
                  />
                ) : (
                  <Box className="row-item">{input.name}</Box>
                )}
                {editIndex === index ? (
                  <Stack className="data-type-select">
                    <Select
                      name="dataType"
                      size="small"
                      placeholder="DataType"
                      value={input.dataType}
                      onChange={(e) => handleEditInputChange(e, index)}
                      variant="outlined"
                      sx={selectStyles}
                      MenuProps={{ sx: selectStyles }}
                    >
                      {dataTypes.map((value, index) => (
                        <MenuItem
                          sx={menuItemStyles}
                          key={index + "-data-type"}
                          value={value}
                        >
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                ) : (
                  <Box className="row-item">{input.dataType}</Box>
                )}
                {editIndex === index ? (
                  <TextField
                    type="text"
                    name="value"
                    size="small"
                    placeholder="Value"
                    value={input.value}
                    onChange={(e) => handleEditInputChange(e, index)}
                    variant="outlined"
                    sx={textFieldStyles}
                    autoComplete="off"
                    className="input-box"
                    error={valueError}
                    helperText={valueError}
                  />
                ) : (
                  <Box className="row-item">{input.value}</Box>
                )}
                {editIndex === index ? (
                  <TextField
                    type="text"
                    name="description"
                    size="small"
                    placeholder="Description"
                    value={input.description}
                    onChange={(e) => handleEditInputChange(e, index)}
                    variant="outlined"
                    sx={textFieldStyles}
                    autoComplete="off"
                    className="input-box"
                  />
                ) : (
                  <Box className="row-item">{input.description}</Box>
                )}
                <Stack className="actions-container">
                  {editIndex !== index && (
                    <Tooltip title="Edit" placement="top">
                      <IconButton
                        aria-label="edit"
                        className="edit-icon"
                        onClick={() => editVariable(index)}
                        sx={iconButtonStyles}
                        disabled={
                          isAddVariable ||
                          editIndex !== -1 ||
                          userAccessRestricted
                        }
                      >
                        <EditIcon className="icon-img"></EditIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {editIndex !== index && (
                    <Tooltip title="Delete" placement="top">
                      <IconButton
                        aria-label="delete"
                        className="delete-icon"
                        sx={iconButtonStyles}
                        onClick={() => deleteVariable(index)}
                        disabled={userAccessRestricted}
                      >
                        <TrashIcon className="icon-img"></TrashIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {editIndex === index && (
                    <Tooltip title="Save" placement="top">
                      <IconButton
                        aria-label="save"
                        className="save-icon"
                        onClick={() => saveEditedVariable(index)}
                        sx={iconButtonStyles}
                        disabled={
                          !data[index].name ||
                          !data[index].dataType ||
                          !data[index].value ||
                          !data[index].description
                        }
                      >
                        <CheckIcon className="icon-img"></CheckIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {editIndex === index && (
                    <Tooltip title="Cancel editing" placement="top">
                      <IconButton
                        aria-label="close"
                        className="close-icon"
                        onClick={() => closeEditingVariable(index)}
                      >
                        <CrossIcon className="icon-img"></CrossIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
            ))}
          {data.length === 0 && (
            <Box className="empty-text">Information Not available</Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          sx={buttonStyles}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={buttonStyles}
          onClick={handleSave}
          autoFocus
          disabled={editIndex !== -1 || userAccessRestricted}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigureVariablesDialog;
