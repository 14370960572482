import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./apiCfgForm.scss";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import {
  buttonStyles,
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  textFieldStyles,
} from "./apiCfgFormStyles";

const ApiCfgForm = ({
  isBodyParam,
  isLoginBodyParam,
  setIsLoginBodyParam,
  setIsBodyParam,
}) => {
  const apiConfigs = useSelector((state) => state.apiConfigs);
  const dispatch = useDispatch();
  const { updateApiConfigs } = bindActionCreators(actionCreators, dispatch);

  const handleBodyParamChange = (e, index, forLogin = false) => {
    const value = e.target.value;
    const name = e.target.name;
    const clonedApiConfigs = structuredClone(apiConfigs);
    if (forLogin) {
      const paramsArray = structuredClone(apiConfigs.loginAPI.bodyParams);
      paramsArray[index][name] = value;
      clonedApiConfigs.loginAPI.bodyParams = paramsArray;
    } else {
      const paramsArray = structuredClone(apiConfigs.dataAPI.bodyParams);
      paramsArray[index][name] = value;
      clonedApiConfigs.dataAPI.bodyParams = paramsArray;
    }
    updateApiConfigs(clonedApiConfigs);
  };

  const handleChange = (
    e,
    parentLevelInput = false,
    isDataApi = false,
    isApiParamInput = false,
    index = null
  ) => {
    const value = e.target.value;
    const name = e.target.name;
    if (e?.target?.type === "text" && e.target.name === "name") {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    const clonedApiConfigs = structuredClone(apiConfigs);
    if (isApiParamInput) {
      if (!isDataApi) {
        const paramsArray = structuredClone(apiConfigs.loginAPI.params);
        paramsArray[index][name] = value;
        clonedApiConfigs.loginAPI.params = paramsArray;
      } else {
        const paramsArray = structuredClone(apiConfigs.dataAPI.params);
        paramsArray[index][name] = value;
        clonedApiConfigs.dataAPI.params = paramsArray;
      }
    } else {
      if (parentLevelInput) {
        clonedApiConfigs[name] = value;
      } else {
        if (!isDataApi) {
          clonedApiConfigs.loginAPI[name] = value;
        } else {
          clonedApiConfigs.dataAPI[name] = value;
        }
      }
    }
    updateApiConfigs(clonedApiConfigs);
  };

  const addLoginBodyParams = () => {
    const clonedApiConfigs = structuredClone(apiConfigs);
    const newLoginBodyParamsArray = structuredClone(
      apiConfigs.loginAPI.bodyParams
    );
    newLoginBodyParamsArray.push({
      name: "",
      source: "",
      constant: "",
    });
    clonedApiConfigs.loginAPI.bodyParams = newLoginBodyParamsArray;
    updateApiConfigs(clonedApiConfigs);
  };

  const removeLoginBodyParam = (index) => {
    const clonedApiConfigs = structuredClone(apiConfigs);
    const newLoginBodyParamsArray = structuredClone(
      apiConfigs.loginAPI.bodyParams
    );
    newLoginBodyParamsArray.splice(index, 1);
    clonedApiConfigs.loginAPI.bodyParams = newLoginBodyParamsArray;
    updateApiConfigs(clonedApiConfigs);
  };

  const addBodyParams = () => {
    const clonedApiConfigs = structuredClone(apiConfigs);
    const newBodyParamsArray = structuredClone(apiConfigs.dataAPI.bodyParams);
    newBodyParamsArray.push({
      name: "",
      source: "",
      constant: "",
    });
    clonedApiConfigs.dataAPI.bodyParams = newBodyParamsArray;
    updateApiConfigs(clonedApiConfigs);
  };

  const removeBodyParam = (index) => {
    const clonedApiConfigs = structuredClone(apiConfigs);
    const newBodyParamsArray = structuredClone(apiConfigs.dataAPI.bodyParams);
    newBodyParamsArray.splice(index, 1);
    clonedApiConfigs.dataAPI.bodyParams = newBodyParamsArray;
    updateApiConfigs(clonedApiConfigs);
  };

  const addLoginApiParams = () => {
    const clonedApiConfigs = structuredClone(apiConfigs);
    const newLoginApiParamsArray = structuredClone(apiConfigs.loginAPI.params);
    newLoginApiParamsArray.push({
      name: "",
      source: "",
      constant: "",
    });
    clonedApiConfigs.loginAPI.params = newLoginApiParamsArray;
    updateApiConfigs(clonedApiConfigs);
  };

  const addApiParams = () => {
    const clonedApiConfigs = structuredClone(apiConfigs);
    const newApiParamsArray = structuredClone(apiConfigs.dataAPI.params);
    newApiParamsArray.push({
      name: "",
      source: "",
      constant: "",
    });
    clonedApiConfigs.dataAPI.params = newApiParamsArray;
    updateApiConfigs(clonedApiConfigs);
  };

  const removeLoginApiParam = (index) => {
    const clonedApiConfigs = structuredClone(apiConfigs);
    const newLoginApiParamsArray = structuredClone(apiConfigs.loginAPI.params);
    newLoginApiParamsArray.splice(index, 1);
    clonedApiConfigs.loginAPI.params = newLoginApiParamsArray;
    updateApiConfigs(clonedApiConfigs);
  };

  const removeApiParam = (index) => {
    const clonedApiConfigs = structuredClone(apiConfigs);
    const newApiParamsArray = structuredClone(apiConfigs.dataAPI.params);
    newApiParamsArray.splice(index, 1);
    clonedApiConfigs.dataAPI.params = newApiParamsArray;
    updateApiConfigs(clonedApiConfigs);
  };

  return (
    <Stack sx={{ marginTop: "0 !important" }}>
      <FormControl id="name" className="text-field" size="small">
        <TextField
          type="text"
          name="name"
          size="small"
          label="Name"
          value={apiConfigs["name"]}
          onChange={(evt) => handleChange(evt, true)}
          variant="outlined"
          sx={textFieldStyles}
          autoComplete="off"
        />
      </FormControl>
      <FormControl id="subType" sx={{ marginTop: "15px !important" }}>
        <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
          API direction
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="row-radio-buttons-group-label"
          name="subType"
          value={apiConfigs["subType"]}
          onChange={(e) => handleChange(e, true)}
        >
          {[
            {
              name: "Inbound",
              value: "incoming",
            },
            {
              name: "Outbound",
              value: "outgoing",
            },
          ].map((radio, radioIndex) => (
            <FormControlLabel
              key={radioIndex}
              value={radio.value}
              control={<Radio style={{ color: "#69C2D2" }} />}
              label={radio.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {apiConfigs && apiConfigs.subType === "outgoing" && (
        <Stack>
          <FormControl id="loginRequired" sx={{ marginTop: "15px !important" }}>
            <Stack direction="row" alignItems="center" gap="20px">
              <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
                Enable authentication
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="loginRequired"
                value={apiConfigs ? apiConfigs.loginRequired : ""}
                onChange={(e) => handleChange(e, true)}
              >
                {[
                  {
                    name: "Yes",
                    value: "yes",
                  },
                  {
                    name: "No",
                    value: "no",
                  },
                ].map((radio, radioIndex) => (
                  <FormControlLabel
                    key={radioIndex}
                    value={radio.value}
                    control={<Radio style={{ color: "#69C2D2" }} />}
                    label={radio.name}
                  />
                ))}
              </RadioGroup>
            </Stack>
          </FormControl>
          {apiConfigs.loginRequired === "yes" && (
            <Accordion
              sx={{
                background: "#151c24;",
                color: "rgb(239, 241, 241) !important",
                marginTop: "10px",
              }}
            >
              <AccordionSummary
                expandIcon={<ChevronDown className="icon" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: "#191818",
                }}
              >
                <Typography>Login Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl id="url" className="text-field" size="small">
                  <TextField
                    type="text"
                    name="url"
                    size="small"
                    label="Url/Endpoint"
                    value={apiConfigs ? apiConfigs.loginAPI.url : ""}
                    onChange={(evt) => handleChange(evt)}
                    variant="outlined"
                    sx={textFieldStyles}
                    autoComplete="off"
                    required
                  />
                </FormControl>
                <FormControl
                  id="method"
                  sx={formControlStyles}
                  className="w-100"
                  size="small"
                >
                  <InputLabel sx={labelStyles}>Method</InputLabel>
                  <Select
                    placeholder="Select option"
                    name="method"
                    label="Method"
                    size="small"
                    value={apiConfigs ? apiConfigs.loginAPI.method : ""}
                    onChange={(evt) => handleChange(evt)}
                    required
                    sx={selectStyles}
                    inputProps={{ sx: selectStyles }}
                    MenuProps={{ sx: selectStyles }}
                    autoWidth={true}
                  >
                    <MenuItem value="get" sx={menuItemStyles}>
                      <Tooltip title="Get" placement="right">
                        <Typography noWrap>Get</Typography>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem value="post" sx={menuItemStyles}>
                      <Tooltip title="Post" placement="right">
                        <Typography noWrap>Post</Typography>
                      </Tooltip>
                    </MenuItem>
                  </Select>
                </FormControl>
                <Box marginTop={"15px"}>
                  <Box className="add-more-parms-button-container">
                    <Button
                      sx={buttonStyles}
                      className="add-more-parms-button"
                      onClick={addLoginApiParams}
                    >
                      Add Login Api parameters
                    </Button>
                  </Box>
                  {apiConfigs.loginAPI.params.length > 0 && (
                    <Stack className="params-box">
                      {apiConfigs.loginAPI.params.map((input, index) => (
                        <Box
                          className="params-container"
                          key={"loginParam" + index}
                        >
                          <Box className="w-100">
                            <FormControl
                              id={"name" + index}
                              className="text-field"
                              size="small"
                            >
                              <TextField
                                type="text"
                                name="name"
                                size="small"
                                label="Name"
                                value={input ? input.name : ""}
                                onChange={(evt) =>
                                  handleChange(evt, false, false, true, index)
                                }
                                autoComplete="off"
                                variant="outlined"
                                sx={textFieldStyles}
                                required
                              />
                            </FormControl>
                            <FormControl
                              id={"source" + index}
                              sx={formControlStyles}
                              className="w-100"
                              size="small"
                            >
                              <InputLabel sx={labelStyles}>Source</InputLabel>
                              <Select
                                placeholder="Select option"
                                name="source"
                                label="Source"
                                size="small"
                                value={input ? input.source : ""}
                                onChange={(evt) =>
                                  handleChange(evt, false, false, true, index)
                                }
                                required
                                sx={selectStyles}
                                inputProps={{ sx: selectStyles }}
                                MenuProps={{ sx: selectStyles }}
                                autoWidth={true}
                              >
                                <MenuItem value="stream" sx={menuItemStyles}>
                                  <Tooltip title="Stream" placement="right">
                                    <Typography noWrap>Stream</Typography>
                                  </Tooltip>
                                </MenuItem>
                                <MenuItem value="constant" sx={menuItemStyles}>
                                  <Tooltip title="constant" placement="right">
                                    <Typography noWrap>Constant</Typography>
                                  </Tooltip>
                                </MenuItem>
                              </Select>
                            </FormControl>
                            {input.source === "constant" && (
                              <FormControl
                                id={"constant" + index}
                                className="value-field"
                                size="small"
                              >
                                <TextField
                                  type="text"
                                  name="constant"
                                  size="small"
                                  label="constant"
                                  value={input ? input.constant : ""}
                                  onChange={(evt) =>
                                    handleChange(evt, false, false, true, index)
                                  }
                                  variant="outlined"
                                  sx={textFieldStyles}
                                  autoComplete="off"
                                  required
                                />
                              </FormControl>
                            )}
                          </Box>
                          <IconButton
                            aria-label="close"
                            className="close-icon"
                            onClick={() => removeLoginApiParam(index)}
                          >
                            <CloseIcon></CloseIcon>
                          </IconButton>
                        </Box>
                      ))}
                    </Stack>
                  )}
                </Box>
                {apiConfigs.loginAPI.method === "post" && (
                  <Stack>
                    <FormControl
                      id="isLoginBodyParam"
                      sx={{ marginTop: "15px !important" }}
                    >
                      <FormLabel
                        id="row-radio-buttons-group-label"
                        sx={labelStyles}
                      >
                        Choose one
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="loginRequired"
                        value={isLoginBodyParam}
                        onChange={(e) => {
                          setIsLoginBodyParam(e.target.value);
                        }}
                      >
                        {[
                          {
                            name: "Body params",
                            value: "yes",
                          },
                          {
                            name: "Api body",
                            value: "no",
                          },
                        ].map((radio, radioIndex) => (
                          <FormControlLabel
                            key={radioIndex}
                            value={radio.value}
                            control={<Radio style={{ color: "#69C2D2" }} />}
                            label={radio.name}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    {isLoginBodyParam === "yes" ? (
                      <Box marginTop={"15px"}>
                        <Box className="add-more-parms-button-container">
                          <Button
                            sx={buttonStyles}
                            className="add-more-parms-button"
                            onClick={addLoginBodyParams}
                          >
                            Add login body parameters
                          </Button>
                        </Box>
                        {apiConfigs.loginAPI.bodyParams.length > 0 && (
                          <Stack className="params-box">
                            {apiConfigs.loginAPI.bodyParams.map(
                              (input, index) => (
                                <Box className="params-container" key={index}>
                                  <Box className="w-100">
                                    <FormControl
                                      id={"name" + index}
                                      className="text-field"
                                      size="small"
                                    >
                                      <TextField
                                        type="text"
                                        name="name"
                                        size="small"
                                        label="Name"
                                        value={input ? input.name : ""}
                                        onChange={(evt) =>
                                          handleBodyParamChange(
                                            evt,
                                            index,
                                            true
                                          )
                                        }
                                        autoComplete="off"
                                        variant="outlined"
                                        sx={textFieldStyles}
                                        required
                                      />
                                    </FormControl>
                                    <FormControl
                                      id={"source" + index}
                                      sx={formControlStyles}
                                      className="w-100"
                                      size="small"
                                    >
                                      <InputLabel sx={labelStyles}>
                                        Source
                                      </InputLabel>
                                      <Select
                                        placeholder="Select option"
                                        name="source"
                                        label="Source"
                                        size="small"
                                        value={input ? input.source : ""}
                                        onChange={(evt) =>
                                          handleBodyParamChange(
                                            evt,
                                            index,
                                            true
                                          )
                                        }
                                        required
                                        sx={selectStyles}
                                        inputProps={{
                                          sx: selectStyles,
                                        }}
                                        MenuProps={{ sx: selectStyles }}
                                        autoWidth={true}
                                      >
                                        <MenuItem
                                          value="stream"
                                          sx={menuItemStyles}
                                        >
                                          <Tooltip
                                            title="Stream"
                                            placement="right"
                                          >
                                            <Typography noWrap>
                                              Stream
                                            </Typography>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem
                                          value="constant"
                                          sx={menuItemStyles}
                                        >
                                          <Tooltip
                                            title="constant"
                                            placement="right"
                                          >
                                            <Typography noWrap>
                                              Constant
                                            </Typography>
                                          </Tooltip>
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    {input.source === "constant" && (
                                      <FormControl
                                        id={"constant" + index}
                                        className="value-field"
                                        size="small"
                                      >
                                        <TextField
                                          type="text"
                                          name="constant"
                                          size="small"
                                          label="constant"
                                          value={input ? input.constant : ""}
                                          onChange={(evt) =>
                                            handleBodyParamChange(
                                              evt,
                                              index,
                                              true
                                            )
                                          }
                                          variant="outlined"
                                          sx={textFieldStyles}
                                          autoComplete="off"
                                          required
                                        />
                                      </FormControl>
                                    )}
                                  </Box>
                                  <IconButton
                                    aria-label="close"
                                    className="close-icon"
                                    onClick={() => removeLoginBodyParam(index)}
                                  >
                                    <CloseIcon></CloseIcon>
                                  </IconButton>
                                </Box>
                              )
                            )}
                          </Stack>
                        )}
                      </Box>
                    ) : (
                      <FormControl
                        id="body"
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="body"
                          size="small"
                          label="Api body"
                          value={apiConfigs ? apiConfigs.loginAPI.body : ""}
                          onChange={(evt) => handleChange(evt)}
                          variant="outlined"
                          sx={textFieldStyles}
                          autoComplete="off"
                          multiline
                          minRows={2}
                          maxRows={4}
                          required
                        />
                      </FormControl>
                    )}
                  </Stack>
                )}
                <FormControl
                  id="authentication"
                  sx={formControlStyles}
                  className="w-100"
                  size="small"
                >
                  <InputLabel sx={labelStyles}>Response type</InputLabel>
                  <Select
                    placeholder="Select option"
                    name="authentication"
                    label="Response type"
                    size="small"
                    value={apiConfigs ? apiConfigs.loginAPI.authentication : ""}
                    onChange={(evt) => handleChange(evt)}
                    required
                    sx={selectStyles}
                    inputProps={{ sx: selectStyles }}
                    MenuProps={{ sx: selectStyles }}
                    autoWidth={true}
                  >
                    <MenuItem value="header" sx={menuItemStyles}>
                      <Tooltip title="Header" placement="right">
                        <Typography noWrap>Header</Typography>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem value="cookie" sx={menuItemStyles}>
                      <Tooltip title="Cookie" placement="right">
                        <Typography noWrap>Cookie</Typography>
                      </Tooltip>
                    </MenuItem>
                  </Select>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion
            sx={{
              background: "#151c24;",
              color: "rgb(239, 241, 241) !important",
              marginTop: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={<ChevronDown className="icon" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                backgroundColor: "#191818",
              }}
            >
              <Typography>Api Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl id="url" className="text-field" size="small">
                <TextField
                  type="text"
                  name="url"
                  size="small"
                  label="Url/Endpoint"
                  value={apiConfigs ? apiConfigs.dataAPI.url : ""}
                  onChange={(evt) => handleChange(evt, false, true)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                  required
                />
              </FormControl>
              <FormControl
                id="method"
                sx={formControlStyles}
                className="w-100"
                size="small"
              >
                <InputLabel sx={labelStyles}>Method</InputLabel>
                <Select
                  placeholder="Select option"
                  name="method"
                  label="Method"
                  size="small"
                  value={apiConfigs ? apiConfigs.dataAPI.method : ""}
                  onChange={(evt) => handleChange(evt, false, true)}
                  required
                  sx={selectStyles}
                  inputProps={{ sx: selectStyles }}
                  MenuProps={{ sx: selectStyles }}
                  autoWidth={true}
                >
                  <MenuItem value="get" sx={menuItemStyles}>
                    <Tooltip title="Get" placement="right">
                      <Typography noWrap>Get</Typography>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem value="post" sx={menuItemStyles}>
                    <Tooltip title="Post" placement="right">
                      <Typography noWrap>Post</Typography>
                    </Tooltip>
                  </MenuItem>
                </Select>
              </FormControl>
              <Box marginTop={"15px"}>
                <Box className="add-more-parms-button-container">
                  <Button
                    sx={buttonStyles}
                    className="add-more-parms-button"
                    onClick={addApiParams}
                  >
                    Add Api parameters
                  </Button>
                </Box>
                {apiConfigs.dataAPI.params.length > 0 && (
                  <Stack className="params-box">
                    {apiConfigs.dataAPI.params.map((input, index) => (
                      <Box className="params-container" key={index}>
                        <Box className="w-100">
                          <FormControl
                            id={"name" + index}
                            className="text-field"
                            size="small"
                          >
                            <TextField
                              type="text"
                              name="name"
                              size="small"
                              label="Name"
                              value={input ? input.name : ""}
                              onChange={(evt) =>
                                handleChange(evt, false, true, true, index)
                              }
                              autoComplete="off"
                              variant="outlined"
                              sx={textFieldStyles}
                              required
                            />
                          </FormControl>
                          <FormControl
                            id={"source" + index}
                            sx={formControlStyles}
                            className="w-100"
                            size="small"
                          >
                            <InputLabel sx={labelStyles}>Source</InputLabel>
                            <Select
                              placeholder="Select option"
                              name="source"
                              label="Source"
                              size="small"
                              value={input ? input.source : ""}
                              onChange={(evt) =>
                                handleChange(evt, false, true, true, index)
                              }
                              required
                              sx={selectStyles}
                              inputProps={{ sx: selectStyles }}
                              MenuProps={{ sx: selectStyles }}
                              autoWidth={true}
                            >
                              <MenuItem value="stream" sx={menuItemStyles}>
                                <Tooltip title="Stream" placement="right">
                                  <Typography noWrap>Stream</Typography>
                                </Tooltip>
                              </MenuItem>
                              <MenuItem value="constant" sx={menuItemStyles}>
                                <Tooltip title="constant" placement="right">
                                  <Typography noWrap>Constant</Typography>
                                </Tooltip>
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {input.source === "constant" && (
                            <FormControl
                              id={"constant" + index}
                              className="value-field"
                              size="small"
                            >
                              <TextField
                                type="text"
                                name="constant"
                                size="small"
                                label="constant"
                                value={input ? input.constant : ""}
                                onChange={(evt) =>
                                  handleChange(evt, false, true, true, index)
                                }
                                variant="outlined"
                                sx={textFieldStyles}
                                autoComplete="off"
                                required
                              />
                            </FormControl>
                          )}
                        </Box>
                        <IconButton
                          aria-label="close"
                          className="close-icon"
                          onClick={() => removeApiParam(index)}
                        >
                          <CloseIcon></CloseIcon>
                        </IconButton>
                      </Box>
                    ))}
                  </Stack>
                )}
              </Box>
              {apiConfigs.dataAPI.method === "post" && (
                <Stack>
                  <FormControl
                    id="isBodyParam"
                    sx={{ marginTop: "15px !important" }}
                  >
                    <FormLabel
                      id="row-radio-buttons-group-label"
                      sx={labelStyles}
                    >
                      Choose one
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="row-radio-buttons-group-label"
                      name="isBodyParam"
                      value={isBodyParam}
                      onChange={(e) => {
                        setIsBodyParam(e.target.value);
                      }}
                    >
                      {[
                        {
                          name: "Body params",
                          value: "yes",
                        },
                        {
                          name: "Api body",
                          value: "no",
                        },
                      ].map((radio, radioIndex) => (
                        <FormControlLabel
                          key={radioIndex}
                          value={radio.value}
                          control={<Radio style={{ color: "#69C2D2" }} />}
                          label={radio.name}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  {isBodyParam === "yes" ? (
                    <Box marginTop={"15px"}>
                      <Box className="add-more-parms-button-container">
                        <Button
                          sx={buttonStyles}
                          className="add-more-parms-button"
                          onClick={addBodyParams}
                        >
                          Add body parameters
                        </Button>
                      </Box>
                      {apiConfigs.dataAPI.bodyParams.length > 0 && (
                        <Stack className="params-box">
                          {apiConfigs.dataAPI.bodyParams.map((input, index) => (
                            <Box className="params-container" key={index}>
                              <Box className="w-100">
                                <FormControl
                                  id={"name" + index}
                                  className="text-field"
                                  size="small"
                                >
                                  <TextField
                                    type="text"
                                    name="name"
                                    size="small"
                                    label="Name"
                                    value={input ? input.name : ""}
                                    onChange={(evt) =>
                                      handleBodyParamChange(evt, index)
                                    }
                                    autoComplete="off"
                                    variant="outlined"
                                    sx={textFieldStyles}
                                    required
                                  />
                                </FormControl>
                                <FormControl
                                  id={"source" + index}
                                  sx={formControlStyles}
                                  className="w-100"
                                  size="small"
                                >
                                  <InputLabel sx={labelStyles}>
                                    Source
                                  </InputLabel>
                                  <Select
                                    placeholder="Select option"
                                    name="source"
                                    label="Source"
                                    size="small"
                                    value={input ? input.source : ""}
                                    onChange={(evt) =>
                                      handleBodyParamChange(evt, index)
                                    }
                                    required
                                    sx={selectStyles}
                                    inputProps={{ sx: selectStyles }}
                                    MenuProps={{ sx: selectStyles }}
                                    autoWidth={true}
                                  >
                                    <MenuItem
                                      value="stream"
                                      sx={menuItemStyles}
                                    >
                                      <Tooltip title="Stream" placement="right">
                                        <Typography noWrap>Stream</Typography>
                                      </Tooltip>
                                    </MenuItem>
                                    <MenuItem
                                      value="constant"
                                      sx={menuItemStyles}
                                    >
                                      <Tooltip
                                        title="constant"
                                        placement="right"
                                      >
                                        <Typography noWrap>Constant</Typography>
                                      </Tooltip>
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                {input.source === "constant" && (
                                  <FormControl
                                    id={"constant" + index}
                                    className="value-field"
                                    size="small"
                                  >
                                    <TextField
                                      type="text"
                                      name="constant"
                                      size="small"
                                      label="constant"
                                      value={input ? input.constant : ""}
                                      onChange={(evt) =>
                                        handleBodyParamChange(evt, index)
                                      }
                                      variant="outlined"
                                      sx={textFieldStyles}
                                      autoComplete="off"
                                      required
                                    />
                                  </FormControl>
                                )}
                              </Box>
                              <IconButton
                                aria-label="close"
                                className="close-icon"
                                onClick={() => removeBodyParam(index)}
                              >
                                <CloseIcon></CloseIcon>
                              </IconButton>
                            </Box>
                          ))}
                        </Stack>
                      )}
                    </Box>
                  ) : (
                    <FormControl id="body" className="text-field" size="small">
                      <TextField
                        type="text"
                        name="body"
                        size="small"
                        label="Api body"
                        value={apiConfigs ? apiConfigs.dataAPI.body : ""}
                        onChange={(evt) => handleChange(evt, false, true)}
                        variant="outlined"
                        sx={textFieldStyles}
                        autoComplete="off"
                        multiline
                        minRows={2}
                        maxRows={4}
                        required
                      />
                    </FormControl>
                  )}
                </Stack>
              )}
              <FormControl id="respKey" className="text-field" size="small">
                <TextField
                  type="text"
                  name="respKey"
                  size="small"
                  label="Response key"
                  value={apiConfigs ? apiConfigs.dataAPI.respKey : ""}
                  onChange={(evt) => handleChange(evt, false, true)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                  required
                />
              </FormControl>
              <FormControl id="dataKey" className="text-field" size="small">
                <TextField
                  type="text"
                  name="dataKey"
                  size="small"
                  label="Data key"
                  value={apiConfigs ? apiConfigs.dataAPI.dataKey : ""}
                  onChange={(evt) => handleChange(evt, false, true)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </Stack>
      )}
    </Stack>
  );
};

export default ApiCfgForm;
