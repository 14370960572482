import { Box, Stack } from "@mui/material";
import CpuUsageGraph from "graphs/cpuUsageGraph/cpuUsageGraph";
import MemoryUsageGraph from "graphs/memoryUsageGraph/memoryUsageGraph";
import React, { useEffect, useRef } from "react";
import "./sidebarGraph.scss";
import api from "apiInterceptor";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "state";
const fetchresusageAPITestData = require("../../assets/apiTestData/fetchresusage-test-data.json");

const SidebarGraph = () => {
  const PROCESSOR_USAGE_POLL_TIME = localStorage.getItem(
    "PROCESSOR_USAGE_POLL_TIME"
  );
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const isPolling = useSelector((state) => state.isPolling);
  const cpuUsageInfo = useSelector((state) => state.cpuUsageInfo);
  const memoryUsageInfo = useSelector((state) => state.memoryUsageInfo);
  const cpuUsageArr = useRef([]);
  const memoryUsageArr = useRef([]);
  const pollInterval = useRef([]);
  const dispatch = useDispatch();
  const { updateCpuUsage, updateMemoryUsage, updateAlertMessage } =
    bindActionCreators(actionCreators, dispatch);
  cpuUsageArr.current = cpuUsageInfo;
  memoryUsageArr.current = memoryUsageInfo;

  useEffect(() => {
    performPolling(isPolling);
    pollInterval.current = setInterval(() => {
      performPolling(isPolling);
    }, PROCESSOR_USAGE_POLL_TIME);
    return () => {
      clearInterval(pollInterval.current);
    };
  }, [isPolling]);

  const updateCpuAndMemoryUsageLists = (procStats) => {
    const newCpuUsageArr = [...cpuUsageArr.current, procStats.CpuUsage];
    const newMemoryUsageArr = [...memoryUsageArr.current, procStats.MemUsage];
    if (newCpuUsageArr.length > 50) {
      newCpuUsageArr.shift();
    }
    if (newMemoryUsageArr.length > 50) {
      newMemoryUsageArr.shift();
    }
    cpuUsageArr.current = newCpuUsageArr;
    memoryUsageArr.current = newMemoryUsageArr;
    updateCpuUsage(newCpuUsageArr);
    updateMemoryUsage(newMemoryUsageArr);
  };

  const performPolling = async (isPolling) => {
    if (isPolling) {
      return;
    }
    try {
      const apiUrl = BASE_API_URL + "fetchresusage";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
      };
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: fetchresusageAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      if (response.data.status === 200) {
        const processorStats = response.data.data.posts[0].ProcessorUtilization;
        updateCpuAndMemoryUsageLists(processorStats);
        updateAlertMessage(null);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        clearInterval(pollInterval.current);
      }
    } catch (error) {
      console.error(error);
      clearInterval(pollInterval.current);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  return (
    <Stack className="sidebar-graph-container">
      <Stack>
        <Box className="graph-header">Cpu Usage (%)</Box>
        <CpuUsageGraph />
      </Stack>
      <Stack className="memory-usage-container">
        <Box className="graph-header">Memory Usage (%)</Box>
        <MemoryUsageGraph />
      </Stack>
    </Stack>
  );
};

export default SidebarGraph;
