import BrandLogo from "../../assets/icons/CILogo.svg";
import userLogo from "../../assets/icons/user_avatar.svg";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./navbar.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Menu, MenuItem, Stack, Tooltip, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeErrorIcon.svg";
import { ReactComponent as ViewErrorIcon } from "../../assets/icons/viewErrorIcon.svg";
import { logoStyle, menuItemStyles, menuStyles } from "./navbarStyle";
import api from "apiInterceptor";
import AlertErrorDialog from "modals/alertErrorDialog/alertErrorDialog";

const Navbar = () => {
  let USER_MANAGEMENT_ENABLED = localStorage.getItem("USER_MANAGEMENT_ENABLED");
  USER_MANAGEMENT_ENABLED =
    USER_MANAGEMENT_ENABLED === "true" || USER_MANAGEMENT_ENABLED === true
      ? true
      : false;
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const USER_MANAGEMENT_URL = localStorage.getItem("USER_MANAGEMENT_URL");
  const userName = localStorage.getItem("USER_NAME");
  const dispatch = useDispatch();
  const {
    updateAlertMessage,
    updateSuccessAlertMessage,
    updateInfoAlertMessage,
    updateIsLoading,
    updateAlertErrorDialogStatus,
  } = bindActionCreators(actionCreators, dispatch);
  const navigate = useNavigate();
  const alertMessage = useSelector((state) => state.alertMessage);
  const isAlertErrorDialogOpen = useSelector(
    (state) => state.isAlertErrorDialogOpen
  );
  const successAlertMessage = useSelector((state) => state.successAlertMessage);
  const infoAlertMessage = useSelector((state) => state.infoAlertMessage);
  const [logoutAnchorEl, setLogoutAnchorEl] = useState(null);
  const openLogoutMenu = Boolean(logoutAnchorEl);

  const handleDialogOpen = () => {
    updateAlertErrorDialogStatus(true);
  };

  const navigateToDashboard = () => {
    navigate("/");
  };

  const hideAlert = () => {
    updateAlertMessage(null);
  };

  const hideSuccessAlert = () => {
    updateSuccessAlertMessage(null);
  };

  const hideInfoAlert = () => {
    updateInfoAlertMessage(null);
  };

  const handleMenuClick = (evt) => {
    setLogoutAnchorEl(evt.currentTarget);
  };

  const handleMenuClose = () => {
    setLogoutAnchorEl(null);
  };

  const logout = () => {
    const userManagementLink = document.createElement("a");
    userManagementLink.href = USER_MANAGEMENT_URL;
    userManagementLink.click();
  };

  const handleLogout = async () => {
    const apiUrl = BASE_API_URL + "logout";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {};
    updateIsLoading(true);
    try {
      const response = await api.post(apiUrl, payload, { headers: headers });
      updateIsLoading(false);
      if (response.data.status === 200) {
        logout();
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";

      updateAlertMessage(errorMessage);
    }
  };

  return (
    <div
      className={`navbar ${
        alertMessage || successAlertMessage || infoAlertMessage ? "error" : ""
      }`}
    >
      <div className="nav-items">
        <span onClick={navigateToDashboard}>OpenDataAccelerator</span>
        <div className="nav-btns">
          <Tooltip title={userName}>
            <div onClick={handleMenuClick} className="menu-container">
              <img src={userLogo} alt="brand-logo" style={logoStyle}></img>
              <p>{userName}</p>
            </div>
          </Tooltip>
          <div>
            <img src={BrandLogo} alt="brand-logo" style={logoStyle}></img>
          </div>
        </div>
        {USER_MANAGEMENT_ENABLED && (
          <Menu
            id="logout-menu"
            anchorEl={logoutAnchorEl}
            open={openLogoutMenu}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "menu-button",
            }}
            sx={menuStyles}
          >
            <MenuItem sx={menuItemStyles} onClick={handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        )}
      </div>
      {successAlertMessage ? (
        <Alert onClose={() => hideSuccessAlert()} severity="success">
          {successAlertMessage}
        </Alert>
      ) : infoAlertMessage ? (
        <Alert onClose={() => hideInfoAlert()} severity="info">
          {infoAlertMessage}
        </Alert>
      ) : alertMessage ? (
        <Alert
          action={
            <Stack direction="row" marginTop="-4px">
              <IconButton
                aria-label="view-error"
                className="view-error-icon"
                onClick={handleDialogOpen}
              >
                <ViewErrorIcon />
              </IconButton>
              <IconButton
                aria-label="close"
                className="close-icon"
                onClick={hideAlert}
                sx={{ width: "35px", height: "35px" }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          }
          severity="error"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            maxHeight="32px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            <span className="alert-message">{alertMessage}</span>
          </Stack>
        </Alert>
      ) : (
        <div />
      )}
      {isAlertErrorDialogOpen && (
        <AlertErrorDialog
          alertMessage={alertMessage}
          isDialogOpen={isAlertErrorDialogOpen}
          setIsDialogOpen={updateAlertErrorDialogStatus}
        ></AlertErrorDialog>
      )}
    </div>
  );
};
export default Navbar;
