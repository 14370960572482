import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./filterDialog.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionMark.svg";
import { useReactFlow } from "reactflow";
import Filter from "components/filter/filter";
import {
  fetchFeatures,
  getConnectedDataSources,
  getConnectedNodes,
  handleFilterSaveDisable,
  initializeFilterInfo,
  prepareColumns,
} from "utils/utils";
import { Snackbar } from "@mui/material";
import api from "apiInterceptor";
import CustomTooltip from "components/Tooltip/CustomTooltip";
const nodeInfo = require("../../assets/testdata/node-info-testdata.json");
const getprojcfgAPITestData = require("../../assets/apiTestData/getprojcfg-test-data.json");

const FilterDialog = (modalProps) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const modalType = "filterDialog";
  const { getNode } = useReactFlow();
  const selectedNode = modalProps.selectedNode;
  const nodesFlow = modalProps.nodesFlow;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const columnsInfo = [];
  const [allColumns, setAllColumns] = useState([]);
  const [numColumns, setNumColumns] = useState([]);
  const [catColumns, setCatColumns] = useState([]);
  const [dateColumns, setDateColumns] = useState([]);
  const [mixedColumns, setMixedColumns] = useState([]);
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const modalForm = useSelector((state) => state.modalForm);
  const pipeline = useSelector((state) => state.pipeline);
  const filterCfg = useSelector((state) => state.filterCfg);
  const statementNames = useSelector((state) => state.statementNames);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const detailedMsgTooltipData = useSelector(
    (state) => state.detailedMsgTooltipData
  );
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const dispatch = useDispatch();
  const {
    updateModalForm,
    updateNodeConfigurations,
    updateFilterCfg,
    updateStatementNames,
    updateIsLoading,
    updateAlertMessage,
    updateSnackbarMsg,
  } = bindActionCreators(actionCreators, dispatch);

  const buttonStyles = {
    color: "white",
    background: "#69c2d2",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "28px",
    textTransform: "none",
    ":hover": {
      background: "#40bcd2",
    },
    ":disabled": {
      background: "rgb(213 205 205 / 59%)",
    },
  };

  useEffect(() => {
    const absentDs = handleDataSourceAbsence();
    if (absentDs) {
      return;
    }
    updateFilterCfg([]);
    getNodeLevelInfo();
    updateStatementNames([{}]);
    if (nodeConfigurations[selectedNode.id]) {
      setTimeout(() => updateModalForm(nodeConfigurations[selectedNode.id]), 1);
      if (nodeConfigurations[selectedNode.id].filterPres) {
        const initializedFilterInfo = initializeFilterInfo(
          nodeConfigurations,
          filterCfg,
          statementNames,
          selectedNode
        );
        updateStatementNames(initializedFilterInfo.newStatementNames);
        updateFilterCfg(initializedFilterInfo.newFilterCfg);
      }
    } else {
      updateModalForm({});
    }
  }, []);

  useEffect(() => {
    let disableSave = false;
    disableSave = handleFilterSaveDisable(filterCfg);
    if (disableSave) {
      setIsSaveDisabled(true);
      return;
    }
    disableSave = false;
    setIsSaveDisabled(false);
  }, [filterCfg]);

  const handleDataSourceAbsence = () => {
    if (connectedDataSources.length === 0) {
      updateAlertMessage("Datasource is not present");
      modalProps.closeModal(modalType);
      return true;
    }
    return false;
  };

  const getNodeLevelInfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        nodeType: selectedNode.data.nodeType,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        const nodeColsInfo = result.columnsInfo
          ? result.columnsInfo
          : nodeInfo.columnsInfo;
        initializeColumns(nodeColsInfo);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        modalProps.closeModal(modalType);
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const connectedDataSources = getConnectedDataSources(
    nodesFlow,
    selectedNode,
    getNode
  );
  const connectedNodes = getConnectedNodes(nodesFlow, selectedNode, getNode);

  const initializeColumns = async (nodeColsInfo) => {
    for (let connectedNode of connectedNodes) {
      const features = await fetchFeatures(
        connectedNode.dSource,
        connectedNode.stageId,
        selectedProjectKey,
        selectedProjVersion,
        selectedFeatureGroup,
        updateIsLoading,
        updateAlertMessage
      );
      if (!features) {
        updateSnackbarMsg("Couldn't fetch features. Please save and try again");
        return;
      }
      columnsInfo.push(features);
    }
    const allColumnValues = prepareColumns(columnsInfo, nodeColsInfo);
    setAllColumns(allColumnValues.columns);
    setNumColumns(allColumnValues.allColumnsInfo.numerical);
    setCatColumns(allColumnValues.allColumnsInfo.categorical);
    setDateColumns(allColumnValues.allColumnsInfo.date);
    setMixedColumns(allColumnValues.allColumnsInfo.mixed);
  };

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    const dsMap = pipeline.clientMetaData.dsMap;
    const currentNodeName = nodeConfigs.filterCfg[0].dataCfg.cfg.name;
    for (let nodeId of Object.keys(dsMap)) {
      if (selectedNode.id !== nodeId && currentNodeName === dsMap[nodeId]) {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    const newFormData = structuredClone(modalForm);
    newFormData["filterPres"] = true;
    newFormData["filterCfg"] = [{}];
    newFormData.filterCfg[0]["dataCfg"] = structuredClone(filterCfg[0]);
    const duplicatedName = checkNodeNameDuplicacy(newFormData);
    if (duplicatedName) {
      updateSnackbarMsg("Name already taken. Please enter a unique name");
      return;
    }
    newNodeConfigurations[selectedNode.id] = structuredClone(newFormData);
    newNodeConfigurations[selectedNode.id]["inputDs"] = connectedDataSources[0];
    newNodeConfigurations[selectedNode.id]["sourceInfo"] = {};
    for (let connectedNode of connectedNodes) {
      if (connectedNode.stageId) {
        newNodeConfigurations[selectedNode.id].sourceInfo[
          connectedNode.dSource
        ] = "output";
      } else {
        newNodeConfigurations[selectedNode.id].sourceInfo[
          connectedNode.dSource
        ] = "input";
      }
    }
    updateNodeConfigurations(newNodeConfigurations);
    modalProps.unHighlightProblematicNode(selectedNode);
    setTimeout(
      () => modalProps.saveConfig(newNodeConfigurations, selectedNode.id),
      1
    );
    modalProps.closeModal(modalType);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Dialog
        open={selectedNode !== null}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <div>Filter</div>
          <div>
            {detailedMsgTooltipData[selectedNode.data.nodeType] ? (
              <IconButton>
                <CustomTooltip
                  title={detailedMsgTooltipData[selectedNode.data.nodeType]}
                >
                  <QuestionMarkIcon width={25} height={25}></QuestionMarkIcon>
                </CustomTooltip>
              </IconButton>
            ) : (
              <></>
            )}
            <IconButton onClick={() => modalProps.closeModal(modalType)}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Filter
            connectedDataSources={connectedDataSources}
            allColumns={allColumns}
            numColumns={numColumns}
            catColumns={catColumns}
            dateColumns={dateColumns}
            mixedColumns={mixedColumns}
            dsMap={pipeline.clientMetaData.dsMap}
            isMultiFilter={false}
            index={0}
            showDescription={true}
          ></Filter>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" gap="10px" paddingX="40px" paddingY="20px">
            <Button
              variant="contained"
              onClick={handleSubmit}
              autoFocus
              sx={buttonStyles}
              disabled={isSaveDisabled || userAccessRestricted}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={() => modalProps.closeModal(modalType)}
              autoFocus
              sx={buttonStyles}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default FilterDialog;
