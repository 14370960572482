import { React, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./backupDialog.scss";
import api from "../../apiInterceptor";
import { buttonStyles, textFieldStyles } from "./backupDialogStyles";
import { showSuccessAlert } from "utils/utils";
const createbackupAPITestData = require("../../assets/apiTestData/success-test-data.json");

const BackupDialog = () => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [backupName, setBackupName] = useState(null);
  const [backupDesc, setBackupDesc] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const showBackupDialog = useSelector((state) => state.showBackupDialog);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedProjFg = useSelector((state) => state.selectedFeatureGroup);
  const dispatch = useDispatch();
  const {
    updateShowBackupDialog,
    updateIsLoading,
    updateAlertMessage,
    updateSuccessAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    const isDisabled = handleSaveButtonDisabling();
    setIsSaveDisabled(isDisabled);
  }, [backupName, backupDesc]);

  const handleSaveButtonDisabling = () => {
    if (!backupName || !backupDesc) {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    updateShowBackupDialog(false);
  };

  const createBackup = async () => {
    const apiUrl = BASE_API_URL + "createbackup";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: selectedProjectKey,
      projVersion: selectedProjVersion,
      projFg: selectedProjFg,
      backupInfo: {
        name: backupName,
        descr: backupDesc,
      },
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: createbackupAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      } else if (response.data.status === 200) {
        handleClose();
        const msg = "Backup created!";
        showSuccessAlert(msg, updateSuccessAlertMessage);
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    if (!/^[a-zA-Z0-9_]*$/.test(value)) {
      return;
    }
    setBackupName(value);
  };

  return (
    <Dialog
      fullWidth
      open={showBackupDialog}
      aria-labelledby="Backup"
      className="backup-dialog"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
          maxHeight: "calc(100% - 122px)",
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        {"Backup"}
        <IconButton
          aria-label="close"
          className="close-icon"
          onClick={handleClose}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <Stack>
            <Stack>
              <FormControl id="backupName" className="text-field" size="small">
                <TextField
                  type="text"
                  name="backupName"
                  label="Backup Name"
                  value={backupName ? backupName : ""}
                  size="small"
                  onChange={handleChange}
                  variant="outlined"
                  autoComplete="off"
                  sx={textFieldStyles}
                  required
                />
              </FormControl>
              <FormControl id="backupDesc" className="text-field" size="small">
                <TextField
                  type="text"
                  name="backupDesc"
                  label="Backup Description"
                  value={backupDesc ? backupDesc : ""}
                  size="small"
                  onChange={(evt) => setBackupDesc(evt.target.value)}
                  variant="outlined"
                  autoComplete="off"
                  sx={textFieldStyles}
                  required
                />
              </FormControl>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          sx={buttonStyles}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={buttonStyles}
          onClick={createBackup}
          disabled={isSaveDisabled}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BackupDialog;
