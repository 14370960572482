import { React, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./deleteDialog.scss";
import { handleCopyModeDisabling } from "utils/utils";

const DeleteDialog = ({
  deletableItem,
  deleteHandler,
  deletionType,
  projectName = null,
  projVersion = null,
  projFg = null,
  handleMenuClose = null,
  closeNodeDeleteDialog,
  flowInfo,
  updateNodesOpacity,
}) => {
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const isDeleteDialogOpen = useSelector((state) => state.isDeleteDialogOpen);
  const dispatch = useDispatch();
  const {
    updateDeleteDialogStatus,
    updateEnableCopyMode,
    updateCopyNodesList,
  } = bindActionCreators(actionCreators, dispatch);

  const buttonStyles = {
    color: "white",
    background: "#69c2d2",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "28px",
    textTransform: "none",
    ":hover": {
      background: "#40bcd2",
    },
  };

  useEffect(() => {
    if (handleMenuClose) {
      handleMenuClose();
    }
    initializeDialogTitle();
    intializeDeleteMessage();
  }, []);

  const initializeDialogTitle = () => {
    if (
      deletionType === "node" ||
      deletionType === "node-backspace" ||
      deletionType === "multiple-nodes"
    ) {
      setDialogTitle("Delete Node");
    } else if (deletionType === "project") {
      setDialogTitle("Delete Project");
    } else if (deletionType === "backup") {
      setDialogTitle("Delete Backup");
    } else if (deletionType === "job") {
      setDialogTitle("Delete Job");
    }
  };

  const intializeDeleteMessage = () => {
    if (deletionType === "project") {
      if (projFg) {
        setDeleteMessage(
          `Are you sure you want to delete the ${projFg} feature group from version ${projVersion} of ${projectName} project ?`
        );
      } else if (projVersion) {
        setDeleteMessage(
          `Are you sure you want to delete the version ${projVersion} of ${projectName} project ?`
        );
      } else {
        setDeleteMessage(
          `Are you sure you want to delete ${projectName} project ?`
        );
      }
    } else if (deletionType === "node" || deletionType === "node-backspace") {
      setDeleteMessage(
        "This action will affect the pipeline configurations. Are you sure you want to delete this node ?"
      );
    } else if (deletionType === "backup") {
      setDeleteMessage("Are you sure you want to delete this backup ?");
    } else if (deletionType === "multiple-nodes") {
      setDeleteMessage(
        "This action will affect the pipeline configurations. Are you sure you want to delete selected nodes ?"
      );
    } else if (deletionType === "job") {
      setDeleteMessage(
        `Are you sure you want to delete ${deletableItem?.job_id} job ?`
      );
    }
  };

  const handleClose = () => {
    if (deletionType === "node-backspace") {
      closeNodeDeleteDialog();
    } else {
      updateDeleteDialogStatus(false);
    }
  };

  const handleDelete = async () => {
    if (deletionType === "project") {
      deleteHandler(deletableItem, projVersion, projFg);
    } else if (deletionType === "node" || deletionType === "node-backspace") {
      let node = [];
      node.push(deletableItem);
      deleteHandler(node);
    } else if (deletionType === "backup" || deletionType === "job") {
      deleteHandler();
    } else if (deletionType === "multiple-nodes") {
      await deleteHandler();
      handleCopyModeDisabling(
        flowInfo,
        updateEnableCopyMode,
        updateCopyNodesList,
        updateNodesOpacity
      );
    }
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      open={deletionType === "node-backspace" ? true : isDeleteDialogOpen}
      aria-labelledby="Delete"
      className="delete-confirmation-dialog"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
          maxHeight: "calc(100% - 122px)",
        },
      }}
    >
      <DialogTitle id="dlg-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText className="white-text">
          {deleteMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={buttonStyles}
          onClick={handleDelete}
          autoFocus
        >
          Delete
        </Button>
        <Button
          autoFocus
          variant="contained"
          sx={buttonStyles}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
