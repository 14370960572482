/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual, isEmpty } from "lodash";
import ReactFlow, {
  MiniMap,
  Background,
  BackgroundVariant,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  getIncomers,
  getConnectedEdges,
  useReactFlow,
  applyEdgeChanges,
  updateEdge,
  useKeyPress,
} from "reactflow";
import { bindActionCreators } from "redux";
import CustomModal from "../../modals/customModal/customModal";
import { actionCreators } from "../../state";
import CustomNode from "../customNode/customNode";
import Toolbar from "../toolbar/toolbar";
import "./dndFlow.scss";
import { useParams } from "react-router-dom";
import PlaygroundToolbar from "../playgroundToolbar/playgroundToolbar";
import FilterDialog from "modals/filterDialog/filterDialog";
import ValueMappingDialog from "modals/valueMappingDialog/valueMappingDialog";
import ExportNodeDialog from "modals/exportNodeDialog/exportNodeDialog";
import ImportConfigDialog from "modals/importConfigDialog/importConfigDialog";
import MultiFilterDialog from "modals/multifilterDialog/multiFilterDialog";
import ConfigureVariablesDialog from "modals/configureVariablesDialog/configureVariablesDialog";
import api from "../../apiInterceptor";
import BackupDialog from "modals/backupDialog/backupDialog";
import RestoreDialog from "modals/restoreDialog/restoreDialog";
import {
  getOutgoingDsNodes,
  getPreviousUnStagedNode,
  handleModellingNodesRedirect,
  simulateMouseClick,
  getAndUpdateTooltipData,
} from "utils/utils";
import DeleteDialog from "modals/deleteDialog/deleteDialog";
import ApiDialog from "modals/apiDialog/apiDialog";
import PipelineDialog from "modals/pipelineDialog/pipelineDialog";
import PipelineExecutionDialog from "modals/pipelineExecutionDialog/pipelineExecutionDialog";
import DatasourcesDialog from "modals/dataSourcesDialog/dataSourcesDialog";
import ProjectInfoDialog from "modals/projectInfoDialog/projectInfoDialog";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { IconButton, Snackbar } from "@mui/material";
import DataCleansingHandlerNodeDialog from "modals/DataCleansingHandlerNodeDialog/DataCleansingHandlerNodeDialog";
const labelMap = require("../../assets/testdata/label-map.json");
const uiModalJson = require("../../assets/testdata/modal-ui.json");
const nodeModalJson = require("../../assets/testdata/node-info.json");
const restoredPipeline = require("../../assets/testdata/backend-pipeline.json");
const savefecfgTestdata = require("../../assets/testdata/savefecfg-testdata.json");
const getfecfgAPITestData = require("../../assets/apiTestData/getfecfg-test-data.json");
const savefecfgAPITestData = require("../../assets/apiTestData/savefecfg-test-data.json");
const pollProgAPITestData = require("../../assets/apiTestData/poll-prog-test-data.json");
const fetchresusageAPITestData = require("../../assets/apiTestData/fetchresusage-test-data.json");
const startexecutionAPITestData = require("../../assets/apiTestData/success-test-data.json");
const listprojectsAPITestData = require("../../assets/apiTestData/listprojects-test-data.json");
const modellingPollapiAPITestData = require("../../assets/apiTestData/modelling-pollapi-test-data.json");
// const tableData = require("../../assets/testdata/dashboard-test-data.json");

const initialNodes = [];
let id = 1;
const getId = (isStage) => {
  return isStage ? `stagenode_${id++}` : `dndnode_${id++}`;
};
const nodeTypes = {
  customNode: CustomNode,
};
const modalMapping = {
  customModal: CustomModal,
  filterDialog: FilterDialog,
  multiFilterDialog: MultiFilterDialog,
  valueMappingDialog: ValueMappingDialog,
  exportNodeDialog: ExportNodeDialog,
  dataCleansingHandlerNodeDialog: DataCleansingHandlerNodeDialog,
  apiDialog: ApiDialog,
  pipelineDialog: PipelineDialog,
};
const DnDFlow = () => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const PROGRESS_POLL_TIME = localStorage.getItem("PROGRESS_POLL_TIME");
  const PROCESSOR_USAGE_POLL_TIME = localStorage.getItem(
    "PROCESSOR_USAGE_POLL_TIME"
  );
  let IS_LIST_PROJECTS_GET_TYPE = localStorage.getItem(
    "IS_LIST_PROJECTS_GET_TYPE"
  );
  IS_LIST_PROJECTS_GET_TYPE =
    IS_LIST_PROJECTS_GET_TYPE === "true" || IS_LIST_PROJECTS_GET_TYPE === true
      ? true
      : false;
  const backSpacePressed = useKeyPress("Backspace");
  const param = useParams();
  const projectKey = param.projectKey;
  const projVersion = param.projVersion;
  const featureGroup = param.projFg;
  const reactFlowWrapper = useRef(null);
  const proOptions = { hideAttribution: true };
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { setViewport, getNode, getEdges, setCenter } = useReactFlow();
  const doubleClickTimeoutRef = useRef(null);
  const [activeModal, setActiveModal] = useState(null);
  const pipeline = useSelector((state) => state.pipeline);
  const [toolbarPosition, setToolbarPosition] = useState(null);
  const [deletableNode, setDeletableNode] = useState(null);
  const [restored, setRestored] = useState(true);
  // const [previousUI, setPreviousUI] = useState(null);
  const [showStopBtn, setShowStopBtn] = useState(false);
  const [disableStopBtn, setDisableStopBtn] = useState(false);
  const [showResumeBtn, setShowResumeBtn] = useState(false);
  const [disableBackupAndRestoreBtn, setDisableBackupAndRestoreBtn] =
    useState(false);
  const [showDisabledButtons, setShowDisabledButtons] = useState(true);
  const [deletableEdge, setDeletableEdge] = useState(null);
  const [showNodeDeleteDialog, setShowNodeDeleteDialog] = useState(false);

  const [allProjectsInfo, setAllProjectsInfo] = useState([]);
  const [checkboxList, setCheckboxList] = useState([]);
  const [nodesBeforeCollapse, setNodesBeforeCollapse] = useState([]);
  const [executionTerminator, setExecutionTerminator] = useState({
    dSource: null,
    stageId: null,
  });
  let intervalIds = useRef([]);
  let configurations = {};
  let stack = [];
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const visitedStageNodes = useSelector((state) => state.visitedStageNodes);
  const errorNodes = useSelector((state) => state.errorNodes);
  const isPolling = useSelector((state) => state.isPolling);
  const cpuUsageInfo = useSelector((state) => state.cpuUsageInfo);
  const memoryUsageInfo = useSelector((state) => state.memoryUsageInfo);
  const cpuUsageArr = useRef([]);
  const memoryUsageArr = useRef([]);
  const isConfigureVariablesDialogOpen = useSelector(
    (state) => state.isConfigureVariablesDialogOpen
  );
  const isSummaryDialogOpen = useSelector((state) => state.isSummaryDialogOpen);
  const isDeleteDialogOpen = useSelector((state) => state.isDeleteDialogOpen);
  const isErrorDialogOpen = useSelector((state) => state.isErrorDialogOpen);
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const showImportConfigDialog = useSelector(
    (state) => state.showImportConfigDialog
  );
  const currentProjectStatus = useSelector(
    (state) => state.currentProjectStatus
  );
  const nodesErrorMsg = useSelector((state) => state.nodesErrorMsg);
  const validationErrorNodes = useSelector(
    (state) => state.validationErrorNodes
  );
  const showBackupDialog = useSelector((state) => state.showBackupDialog);
  const enableCopyMode = useSelector((state) => state.enableCopyMode);
  const showRestoreDialog = useSelector((state) => state.showRestoreDialog);
  const showProjectInfoDialog = useSelector(
    (state) => state.showProjectInfoDialog
  );
  const showPipelineExecutionDialog = useSelector(
    (state) => state.showPipelineExecutionDialog
  );
  const modellingNodesData = useSelector((state) => state.modellingNodesData);
  const currentProjName = useSelector((state) => state.currentProjName);
  const currentFgDesc = useSelector((state) => state.currentFgDesc);
  const copyNodesList = useSelector((state) => state.copyNodesList);
  const showPartialExecutionDialog = useSelector(
    (state) => state.showPartialExecutionDialog
  );
  const startDs = useSelector((state) => state.startDs);
  const globalCollapseEnabled = useSelector(
    (state) => state.globalCollapseEnabled
  );
  const collapsedDs = useSelector((state) => state.collapsedDs);
  const showDatasourcesDialog = useSelector(
    (state) => state.showDatasourcesDialog
  );

  let pollFurther = useRef(false);
  cpuUsageArr.current = cpuUsageInfo;
  memoryUsageArr.current = memoryUsageInfo;
  const dispatch = useDispatch();
  const {
    updateTable,
    updateModalForm,
    updatecurrentFormType,
    updateModalUI,
    updatePipeline,
    updateNodeConfigurations,
    updateVisitedStageNodes,
    updateErrorNodes,
    updateIsPlaygroundLoading,
    updateAlertMessage,
    updateProjectKey,
    updateProjVersion,
    updateFeatureGroup,
    updateShowImportConfigDialog,
    updateIsPolling,
    updateCpuUsage,
    updateMemoryUsage,
    updateNodesErrorMsg,
    updateValidationErrorNodes,
    updateSnackbarMsg,
    updatePivotStatCfg,
    updateShowPipelineExecutionDialog,
    updateModellingNodesData,
    updateCurrentProjectName,
    updateCurrentFgDesc,
    updateCopyNodesList,
    updateEnableCopyMode,
    updateCurrentProjectStatus,
    updateConfigureVariablesDialogStatus,
    updateDeleteDialogStatus,
    updateSummaryDialogStatus,
    updateErrorDialogStatus,
    updateShowBackupDialog,
    updateShowRestoreDialog,
    updateStartDs,
    updateShortMsgTooltipData,
    updateDetailedMsgTooltipData,
    updateInfoAlertMessage,
    updateCollapsedDs,
    updateGlobalCollapse,
    updateShowProjectInfoDialog,
    updateAlertErrorDialogStatus,
    updateExploredProject,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    loadPlayground();
    setProjectInfo();
    return () => {
      if (intervalIds.current.length > 0) {
        intervalIds.current.forEach((intervalId) => {
          clearInterval(intervalId);
        });
        intervalIds.current = [];
      }
    };
  }, [reactFlowInstance, projectKey, projVersion, featureGroup]);

  const setProjectInfo = () => {
    const exploredProjectObj = {
      projectKey: param.projectKey,
      projVersion: param.projVersion,
      projFg: param.projFg,
    };
    updateExploredProject(exploredProjectObj);
  };

  const loadPlayground = () => {
    resetUseStateVariables();
    initializeStore();
    updateProjectKey(projectKey);
    updateProjVersion(projVersion);
    updateFeatureGroup(featureGroup);
    getTooltipData();
    if (restoredPipeline && !restored) {
      restoreUI(restoredPipeline);
    }
    fetchPipeline();
  };

  useEffect(() => {
    if (deletableNode && !globalCollapseEnabled && collapsedDs.length === 0) {
      setShowNodeDeleteDialog(true);
    } else if (deletableEdge) {
      onEdgeDelete(deletableEdge);
    }
  }, [backSpacePressed]);

  useEffect(() => {
    if (globalCollapseEnabled) {
      updateInfoAlertMessage("collapse mode enabled");
    } else {
      updateInfoAlertMessage(null);
    }
  }, [globalCollapseEnabled]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const nodesUI = reactFlowInstance.toObject();
  //     if (isEqual(previousUI, nodesUI)) {
  //       return;
  //     } else {
  //       setPreviousUI(nodesUI);
  //       if (!isEmpty(pipeline)) {
  //         saveConfigurations();
  //       }
  //     }
  //   }, 60000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [reactFlowInstance, previousUI, pipeline]);

  const fetchPipeline = async () => {
    try {
      const apiUrl = BASE_API_URL + "getfecfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: projectKey,
        cfgProjectKey: projectKey,
        projVersion: projVersion,
        projFg: featureGroup,
      };
      updateIsPlaygroundLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getfecfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
        });
      }
      updateIsPlaygroundLoading(false);
      if (response.data.status === 200) {
        const feCfg = response.data.data.posts[0];
        if (!isEmpty(feCfg)) {
          restoreUI(feCfg);
        } else {
          setShowDisabledButtons(false);
        }
        if (!isEmpty(feCfg) && reactFlowInstance) {
          updateIsPolling(true);
          const cpuAndMemoryUsagePollInterval = setInterval(() => {
            getCpuAndMemoryUsage(cpuAndMemoryUsagePollInterval);
          }, PROCESSOR_USAGE_POLL_TIME);
          const pollInterval = setInterval(() => {
            performPolling(pollInterval, feCfg, cpuAndMemoryUsagePollInterval);
          }, PROGRESS_POLL_TIME);
          intervalIds.current.push(pollInterval);
          intervalIds.current.push(cpuAndMemoryUsagePollInterval);
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsPlaygroundLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleProjNameAndFgDescAndState = (restoredPipeline) => {
    if (restoredPipeline.pname && !currentProjName) {
      updateCurrentProjectName(restoredPipeline.pname);
    }
    if (!USING_TEST_DATA) {
      getProjectDescriptionAndState();
    }
  };

  const getProjectDescriptionAndState = async () => {
    const projects = await getProjects(null);
    if (projects && projects.length > 0) {
      const featureGroups = getFeatureGroups(projects, projectKey, projVersion);
      const currentFg = featureGroups.find((fg) => fg.projFg === featureGroup);
      updateCurrentFgDesc(currentFg.description);
      updateCurrentProjectStatus(currentFg.state);
    }
  };

  const getTooltipData = async () => {
    getAndUpdateTooltipData(
      updateIsPlaygroundLoading,
      updateShortMsgTooltipData,
      updateDetailedMsgTooltipData,
      updateAlertMessage
    );
  };

  const getFeatureGroups = (projects, pkey, versionId) => {
    let targetProj = null;
    targetProj = projects.find((proj) => proj.projectKey === pkey);
    const targetVersion = targetProj.versionInfo.find(
      (ver) => ver.vname === versionId
    );
    return targetVersion.fgInfo;
  };

  const handleCollapsedNodes = (newNodeConfigs, restoredPipeline) => {
    let flow = restoredPipeline.clientMetaData.flow;
    const allNodes = flow.nodes;
    const allEdges = flow.edges;
    const clonedCollapsedDs = structuredClone(collapsedDs);
    let dsWithStagesCount = 0;
    allNodes.forEach((flowNode) => {
      if (restoredPipeline[flowNode.id]?.stageCount) {
        dsWithStagesCount++;
      }
    });
    allNodes.forEach((node) => {
      if (node.hidden) {
        const nodeId = node.id;
        const source = node.id;
        const target = newNodeConfigs?.[nodeId]?.inputDs;
        allEdges.forEach((edge) => {
          if (
            edge.source === source &&
            edge.target === target &&
            !edge.animated
          ) {
            if (!clonedCollapsedDs.includes(edge.target)) {
              clonedCollapsedDs.push(edge.target);
            }
          }
        });
      }
    });
    if (
      clonedCollapsedDs.length === dsWithStagesCount &&
      clonedCollapsedDs.length > 0
    ) {
      updateGlobalCollapse(true);
    }
    updateCollapsedDs(clonedCollapsedDs);
  };

  const restoreUI = (
    restoredPipeline,
    isImported = false,
    uploadReqd = false
  ) => {
    let flow = restoredPipeline.clientMetaData.flow;
    if (!flow) {
      updateAlertMessage("Invalid Project !");
    } else if (flow.nodes.length === 0) {
      return;
    } else {
      id = Number(flow.nodes.at(-1).id.split("_")[1]) + 1;
      let newErrorNodes = [];
      flow.nodes.forEach((flowNode) => {
        if (flowNode.className.includes("error") && isImported) {
          flowNode.className = flowNode.className.split(" ")[0];
          flowNode.className += " error";
          newErrorNodes.push(flowNode);
        } else if (isImported) {
          flowNode.className = flowNode.className.split(" ")[0];
        } else if (!isImported && flowNode.className.includes("error")) {
          newErrorNodes.push(flowNode);
        }
      });
      if (uploadReqd) {
        const localFileUploadNodes = restoredPipeline.localFileUploadNodes;
        localFileUploadNodes.forEach((localFileNode) => {
          restoredPipeline[localFileNode].dataCfg.cfg.file = null;
        });
        const resp = handleImportErrorNodeHighlighting(
          localFileUploadNodes,
          flow.nodes,
          newErrorNodes
        );
        flow.nodes = resp.flowNodes;
        newErrorNodes = resp.errorNodes;
      }
      const { x = 0, y = 0, zoom = 1 } = flow.viewport;
      handleProjNameAndFgDescAndState(restoredPipeline);
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
      setViewport({ x, y, zoom });
      updatePipeline(restoredPipeline);
      restoreVisitedStageAndCollapsedNodesAndConfigs(restoredPipeline);
      updateShowImportConfigDialog(false);
      updateErrorNodes(newErrorNodes);
      setRestored(true);
      if (isImported) {
        saveConfigurations(false, restoredPipeline);
        updateModellingNodesData({});
      }
    }
  };

  const handleImportErrorNodeHighlighting = (
    dependentNodes,
    flowNodes,
    errorNodes
  ) => {
    let flow = flowNodes;
    if (dependentNodes.length > 0) {
      for (let dependentNode of dependentNodes) {
        const errorNode = structuredClone(
          flowNodes.find((node) => node.id === dependentNode)
        );
        if (errorNode) {
          errorNode.className += " error";
          const updatedNodes = flow
            .map((node) => {
              if (node.id === dependentNode) {
                return errorNode;
              } else {
                return node;
              }
            })
            .filter((node) => node !== null);
          flow = updatedNodes;
          errorNodes.push(errorNode);
        }
      }
    }
    return {
      flowNodes: flow,
      errorNodes: errorNodes,
    };
  };

  const restoreVisitedStageAndCollapsedNodesAndConfigs = (restoredPipeline) => {
    const newNodeConfigs = {};
    const newVisitedStageNodes = [];
    restoredPipeline.dataSourceOrder.forEach((node) => {
      newNodeConfigs[node] = restoredPipeline[node].dataCfg.cfg;
      for (let stage of restoredPipeline[node].stageOrder) {
        newNodeConfigs[restoredPipeline[node][stage].id] =
          restoredPipeline[node][stage].cfg;
        if (!newVisitedStageNodes.includes(restoredPipeline[node][stage].id)) {
          newVisitedStageNodes.push(restoredPipeline[node][stage].id);
        }
      }
    });
    if (restoredPipeline.mlNodes) {
      restoredPipeline.mlNodes.forEach((node) => {
        newNodeConfigs[node] = restoredPipeline[node].dataCfg.cfg;
      });
    }
    handleCollapsedNodes(newNodeConfigs, restoredPipeline);
    updateNodeConfigurations(newNodeConfigs);
    updateVisitedStageNodes(newVisitedStageNodes);
  };

  const resetUseStateVariables = () => {
    setNodes([]);
    setEdges([]);
    setActiveModal(null);
    setToolbarPosition(null);
    setDeletableNode(null);
    setRestored(true);
    setShowStopBtn(false);
    setShowStopBtn(false);
    setDisableStopBtn(false);
    setShowResumeBtn(false);
    setDisableBackupAndRestoreBtn(false);
    setShowDisabledButtons(true);
    setDeletableEdge(null);
    setShowNodeDeleteDialog(false);
    setAllProjectsInfo([]);
    setCheckboxList([]);
    setExecutionTerminator({
      dSource: null,
      stageId: null,
    });
  };

  const initializeStore = () => {
    updatePipeline({});
    updateTable(null);
    updateModalForm(null);
    updateModalUI(null);
    updateNodeConfigurations(null);
    updateVisitedStageNodes([]);
    updateErrorNodes([]);
    updateAlertMessage(null);
    updateInfoAlertMessage(null);
    updateShowImportConfigDialog(false);
    updateIsPolling(false);
    updatePivotStatCfg([]);
    updateIsPlaygroundLoading(false);
    updateShowPipelineExecutionDialog(false);
    updateModellingNodesData({});
    updateSnackbarMsg(null);
    updateEnableCopyMode(false);
    updateCopyNodesList([]);
    updateValidationErrorNodes([]);
    updateNodesErrorMsg({});
    updateConfigureVariablesDialogStatus(false);
    updateDeleteDialogStatus(false);
    updateSummaryDialogStatus(false);
    updateErrorDialogStatus(false);
    updateShowBackupDialog(false);
    updateShowRestoreDialog(false);
    updateShowPipelineExecutionDialog(false);
    updateCollapsedDs([]);
    updateGlobalCollapse(false);
    updateShowProjectInfoDialog(false);
    updateAlertErrorDialogStatus(false);
  };

  // clears the inputDs and sourceInfo on edge removal
  const handleSourceInfoAndInputDs = (
    clonedPipeline,
    outgoingNode,
    incomingNodeId,
    clonedNodeConfigs
  ) => {
    const incomingNode = getNode(incomingNodeId);
    const incomingDsNode = getPreviousUnStagedNode(
      clonedPipeline.clientMetaData.flow,
      incomingNode
    );
    if (
      clonedPipeline[outgoingNode.id].dataCfg.cfg.sourceInfo &&
      incomingDsNode
    ) {
      delete clonedPipeline[outgoingNode.id].dataCfg.cfg.sourceInfo[
        incomingDsNode.id
      ];
    }
    if (clonedPipeline[outgoingNode.id].dataCfg.cfg.inputDs) {
      if (
        ["join", "stack"].includes(outgoingNode.data.nodeType) &&
        incomingDsNode
      ) {
        clonedPipeline[outgoingNode.id].dataCfg.cfg.inputDs.splice(
          clonedPipeline[outgoingNode.id].dataCfg.cfg.inputDs.indexOf(
            incomingDsNode.id
          ),
          1
        );
        if (clonedPipeline[outgoingNode.id].dataCfg.cfg.rightData) {
          if (
            clonedPipeline[outgoingNode.id].dataCfg.cfg.rightData.includes(
              incomingDsNode.id
            )
          ) {
            clonedPipeline[outgoingNode.id].dataCfg.cfg.rightData.splice(
              clonedPipeline[outgoingNode.id].dataCfg.cfg.rightData.indexOf(
                incomingDsNode.id
              ),
              1
            );
          }
        }
        if (clonedPipeline[outgoingNode.id].dataCfg.cfg.leftData) {
          if (
            clonedPipeline[outgoingNode.id].dataCfg.cfg.leftData ===
            incomingDsNode.id
          ) {
            clonedPipeline[outgoingNode.id].dataCfg.cfg.leftData = "";
          }
        }
      } else {
        clonedPipeline[outgoingNode.id].dataCfg.cfg.inputDs = "";
      }
      if (outgoingNode.data.nodeType === "split") {
        if (
          clonedPipeline[outgoingNode.id].dataCfg.cfg.splitType === "split-excl"
        ) {
          if (!nodeConfigurations[outgoingNode.id]?.excluDs) {
            clonedPipeline[outgoingNode.id].dataCfg.cfg.excluDs = "";
          }
        }
      }
    }
    clonedNodeConfigs[outgoingNode.id] =
      clonedPipeline[outgoingNode.id].dataCfg.cfg;
  };

  const makeConnection = (params) => {
    setEdges((eds) => addEdge(params, eds));
  };

  const edgeRestrictionOnStageNode = (
    flow,
    connectionReceivingNode,
    outgoingEdges,
    connectionMakingNode
  ) => {
    const clonedPipeline = structuredClone(pipeline);
    const sourceNodeOfConnectionMakingNode = getPreviousUnStagedNode(
      flow,
      connectionMakingNode
    );
    if (!sourceNodeOfConnectionMakingNode) {
      return false;
    }
    let receivingNodeInputDs = null;
    if (!connectionReceivingNode.data.isStage) {
      if (
        clonedPipeline[connectionReceivingNode.id] &&
        clonedPipeline[connectionReceivingNode.id].dataCfg
      ) {
        receivingNodeInputDs =
          clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.inputDs;
      }
    } else {
      const outgoingDsNodes = getOutgoingDsNodes(flow, connectionReceivingNode);
      for (let outgoingDsNode of outgoingDsNodes) {
        const inputDsOfOutGoingDsNode =
          clonedPipeline[outgoingDsNode.id].dataCfg.cfg.inputDs;
        if (
          Array.isArray(inputDsOfOutGoingDsNode) &&
          inputDsOfOutGoingDsNode.includes(sourceNodeOfConnectionMakingNode.id)
        ) {
          return false;
        }
      }
    }
    let hasStageNode = false;
    let hasDataSourceNode = false;
    for (let edge of outgoingEdges) {
      const node = flow.nodes.find((node) => node.id === edge.source);
      if (node.data.isStage) {
        hasStageNode = true;
      } else {
        hasDataSourceNode = true;
      }
    }
    if (
      ((hasDataSourceNode || hasStageNode) &&
        connectionReceivingNode.data.isStage) ||
      (hasStageNode && !connectionReceivingNode.data.isStage)
    ) {
      return false;
    } else if (Array.isArray(receivingNodeInputDs)) {
      if (receivingNodeInputDs.includes(sourceNodeOfConnectionMakingNode.id)) {
        return false;
      }
    } else {
      if (receivingNodeInputDs === sourceNodeOfConnectionMakingNode.id) {
        return false;
      }
    }
  };

  const edgeRestrictionOnNonStageNode = (
    flow,
    connectionReceivingNode,
    outgoingEdges,
    connectionMakingNode
  ) => {
    const clonedPipeline = structuredClone(pipeline);
    const sourceNodeOfConnectionMakingNode = getPreviousUnStagedNode(
      clonedPipeline.clientMetaData.flow,
      connectionMakingNode
    );
    if (!sourceNodeOfConnectionMakingNode) {
      return false;
    }
    let receivingNodeInputDs = null;
    if (!connectionReceivingNode.data.isStage) {
      if (
        clonedPipeline[connectionReceivingNode.id] &&
        clonedPipeline[connectionReceivingNode.id].dataCfg
      ) {
        receivingNodeInputDs =
          clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.inputDs;
      }
    } else {
      const outgoingDsNodes = getOutgoingDsNodes(flow, connectionReceivingNode);
      for (let outgoingDsNode of outgoingDsNodes) {
        const inputDsOfOutGoingDsNode =
          clonedPipeline[outgoingDsNode.id].dataCfg.cfg.inputDs;
        if (
          Array.isArray(inputDsOfOutGoingDsNode) &&
          inputDsOfOutGoingDsNode.includes(connectionMakingNode.id)
        ) {
          return false;
        }
      }
    }
    let hasStageNode = false;
    for (let edge of outgoingEdges) {
      const node = flow.nodes.find((node) => node.id === edge.source);
      if (node.data.isStage) {
        hasStageNode = true;
        break;
      }
    }
    if (hasStageNode && connectionReceivingNode.data.isStage) {
      return false;
    } else if (Array.isArray(receivingNodeInputDs)) {
      if (receivingNodeInputDs.includes(sourceNodeOfConnectionMakingNode.id)) {
        return false;
      }
    } else {
      if (receivingNodeInputDs === sourceNodeOfConnectionMakingNode.id) {
        return false;
      }
    }
  };

  const restrictMoreThanOneConnection = (
    connectionReceivingNode,
    oldIncomingNode = null
  ) => {
    let incomingEdges = null;
    if (oldIncomingNode) {
      incomingEdges = getEdges().filter(
        (edge) =>
          edge.source === connectionReceivingNode.id &&
          edge.target !== oldIncomingNode.id
      );
    } else {
      incomingEdges = getEdges().filter(
        (edge) => edge.source === connectionReceivingNode.id
      );
    }
    if (incomingEdges.length < 1) {
      return true;
    } else {
      return false;
    }
  };

  const onConnect = (params) => {
    if (isPolling || globalCollapseEnabled || collapsedDs.length) {
      return;
    }
    const connectionReceivingNode = getNode(params.source);
    const connectionMakingNode = getNode(params.target);
    const flow = reactFlowInstance.toObject();
    const outgoingEdges = getEdges().filter(
      (edge) => edge.target === connectionMakingNode.id
    );
    const isConnectionRestricted = checkRestrictedConnection(
      connectionMakingNode,
      connectionReceivingNode,
      flow,
      outgoingEdges
    );
    if (isConnectionRestricted) {
      return false;
    } else {
      makeConnection(params);
    }
    const clonedPipeline = structuredClone(pipeline);
    const clonedNodeConfigs = structuredClone(nodeConfigurations);
    if (!connectionReceivingNode.data.isStage) {
      updateInputDsAndSourceInfoOfConnectionReceivingNode(
        clonedPipeline,
        connectionMakingNode,
        connectionReceivingNode,
        clonedNodeConfigs
      );
    } else {
      const outgoingDsNodes = getOutgoingDsNodes(flow, connectionReceivingNode);
      for (let outgoingDsNode of outgoingDsNodes) {
        updateInputDsAndSourceInfoOfConnectionReceivingNode(
          clonedPipeline,
          connectionMakingNode,
          outgoingDsNode,
          clonedNodeConfigs,
          true
        );
      }
    }
    updateNodeConfigurations(clonedNodeConfigs);
    setTimeout(() => {
      saveConfig(null, null, clonedPipeline);
    }, 1);
  };

  const onEdgesChange = (changes) => {
    if (isPolling || globalCollapseEnabled || collapsedDs.length) {
      return;
    }
    setEdges((eds) => applyEdgeChanges(changes, eds));
  };

  const onEdgeDelete = (edge) => {
    if (
      isPolling ||
      edge[0].animated ||
      globalCollapseEnabled ||
      collapsedDs.length
    ) {
      return;
    }
    const clonedPipeline = structuredClone(pipeline);
    const clonedNodeConfigs = structuredClone(nodeConfigurations);
    const newEdges = structuredClone(clonedPipeline.clientMetaData.flow.edges);
    newEdges.splice(newEdges.indexOf(edge), 1);
    const outgoingNode = nodes.find((node) => node.id === edge[0].source);
    if (outgoingNode.data.isStage) {
      deleteStageNode(clonedPipeline, outgoingNode, clonedNodeConfigs);
    } else {
      const incomingNodeId = edge[0].target;
      handleSourceInfoAndInputDs(
        clonedPipeline,
        outgoingNode,
        incomingNodeId,
        clonedNodeConfigs
      );
    }
    clonedPipeline.clientMetaData.flow.edges = newEdges;
    updatePipeline(clonedPipeline);
    updateNodeConfigurations(clonedNodeConfigs);
    if (edge) {
      const changes = [
        {
          id: edge[0].id,
          type: "remove",
        },
      ];
      onEdgesChange(changes);
    }
    setDeletableEdge(null);
  };

  const restrictDsConnectionToSelf = (
    connectionReceivingNode,
    connectionMakingNode
  ) => {
    const clonedPipeline = structuredClone(pipeline);
    const sourceNodeOfConnectionMakingNode = getPreviousUnStagedNode(
      clonedPipeline.clientMetaData.flow,
      connectionMakingNode
    );
    if (connectionReceivingNode.id === sourceNodeOfConnectionMakingNode.id) {
      return true;
    }
    return false;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const checkRestrictedConnection = (
    connectionMakingNode,
    connectionReceivingNode,
    flow,
    outgoingEdges,
    oldIncomingNode = null
  ) => {
    if (connectionMakingNode.data.isStage) {
      // restricting a stage node to not connect to more than one stage nodes or datasource nodes and stage node simultaneously.
      const allowConnection = edgeRestrictionOnStageNode(
        flow,
        connectionReceivingNode,
        outgoingEdges,
        connectionMakingNode
      );
      if (allowConnection === false) {
        updateSnackbarMsg(
          "Invalid Operation. One Feature Engineering node cannot be connected to more than one Feature engineering node. Please make use of clone node to create a new branch."
        );
        return true;
      }
    } else {
      // restricting a non-stage node to not connect with more than one stage node.
      const allowConnection = edgeRestrictionOnNonStageNode(
        flow,
        connectionReceivingNode,
        outgoingEdges,
        connectionMakingNode
      );
      if (allowConnection === false) {
        updateSnackbarMsg(
          "Invalid Operation. Multiple Feature engineering node cannot be connected to the Data Node. Please make use of clone node to create a new branch."
        );
        return true;
      }
    }
    if (connectionReceivingNode.data.allowOneIncomingEdge) {
      const allowConnection = restrictMoreThanOneConnection(
        connectionReceivingNode,
        oldIncomingNode
      );
      if (allowConnection === false) {
        updateSnackbarMsg(
          "Invalid Operation. Multiple inputs is not allowed for the selected node."
        );
        return true;
      }
    }
    if (!connectionReceivingNode.data.isStage) {
      const connectedToSelf = restrictDsConnectionToSelf(
        connectionReceivingNode,
        connectionMakingNode
      );
      if (connectedToSelf) {
        updateSnackbarMsg("Invalid Operation. Cannot be connected to itself.");
        return true;
      }
    }
    if (
      connectionMakingNode.data.nodeKind === "model-dev" &&
      connectionReceivingNode.data.nodeKind !== "model-score"
    ) {
      updateSnackbarMsg(
        "Invalid Operation. Selected node cannot be connected to model development node."
      );
      return true;
    }
    return false;
  };

  const updateInputDsAndSourceInfoOfConnectionReceivingNode = (
    clonedPipeline,
    connectionMakingNode,
    connectionReceivingNode,
    clonedNodeConfigs,
    forNextDs = false
  ) => {
    const connectionMakingDsNode = getPreviousUnStagedNode(
      clonedPipeline.clientMetaData.flow,
      connectionMakingNode
    );
    if (connectionMakingDsNode) {
      if (!clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.sourceInfo) {
        clonedPipeline[connectionReceivingNode.id].dataCfg.cfg["sourceInfo"] =
          {};
      }
      clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.sourceInfo[
        connectionMakingDsNode.id
      ] = connectionMakingNode.data.isStage || forNextDs ? "output" : "input";
      if (
        !clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.inputDs ||
        clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.inputDs
          .length === 0
      ) {
        if (["join", "stack"].includes(connectionReceivingNode.data.nodeType)) {
          clonedPipeline[connectionReceivingNode.id].dataCfg.cfg["inputDs"] =
            [];
        } else {
          clonedPipeline[connectionReceivingNode.id].dataCfg.cfg["inputDs"] =
            "";
        }
      }
      const inputDsOfConnectionReceivingNode = structuredClone(
        clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.inputDs
      );
      if (Array.isArray(inputDsOfConnectionReceivingNode)) {
        clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.inputDs.push(
          connectionMakingDsNode.id
        );
      } else {
        clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.inputDs =
          connectionMakingDsNode.id;
      }
      if (connectionReceivingNode.data.nodeType === "join") {
        if (
          !clonedPipeline[connectionReceivingNode.id].dataCfg.cfg["rightData"]
        ) {
          clonedPipeline[connectionReceivingNode.id].dataCfg.cfg["rightData"] =
            [];
        }
        clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.rightData.push(
          connectionMakingDsNode.id
        );
        clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.jKind =
          clonedPipeline[connectionReceivingNode.id].dataCfg.cfg.inputDs
            .length > 2
            ? "yes"
            : "no";
      }
      clonedNodeConfigs[connectionReceivingNode.id] =
        clonedPipeline[connectionReceivingNode.id].dataCfg.cfg;
    }
  };

  const updateInputDsAndSourceInfoOfNextDsNode = (
    flow,
    incomingNode,
    outgoingNode,
    clonedPipeline,
    clonedNodeConfigs
  ) => {
    const incomingUnStagedNode = getPreviousUnStagedNode(flow, incomingNode);
    if (!incomingUnStagedNode) {
      return;
    }
    const outgoingDsNodes = getOutgoingDsNodes(flow, outgoingNode);
    for (let outgoingDs of outgoingDsNodes) {
      if (clonedPipeline[outgoingDs.id]) {
        if (clonedPipeline[outgoingDs.id].dataCfg.cfg.sourceInfo) {
          delete clonedPipeline[outgoingDs.id].dataCfg.cfg.sourceInfo[
            incomingUnStagedNode.id
          ];
        }
        if (["join", "stack"].includes(outgoingDs.data.nodeType)) {
          if (
            clonedPipeline[outgoingDs.id].dataCfg.cfg.inputDs.includes(
              incomingUnStagedNode.id
            )
          ) {
            clonedPipeline[outgoingDs.id].dataCfg.cfg.inputDs.splice(
              clonedPipeline[outgoingDs.id].dataCfg.cfg.inputDs.indexOf(
                incomingUnStagedNode.id
              ),
              1
            );
          }
          if (clonedPipeline[outgoingDs.id].dataCfg.cfg.rightData) {
            if (
              clonedPipeline[outgoingDs.id].dataCfg.cfg.rightData.includes(
                incomingUnStagedNode.id
              )
            ) {
              clonedPipeline[outgoingDs.id].dataCfg.cfg.rightData.splice(
                clonedPipeline[outgoingDs.id].dataCfg.cfg.rightData.indexOf(
                  incomingUnStagedNode.id
                ),
                1
              );
            }
          }
          if (clonedPipeline[outgoingDs.id].dataCfg.cfg.leftData) {
            if (
              clonedPipeline[outgoingDs.id].dataCfg.cfg.leftData ===
              incomingUnStagedNode.id
            ) {
              clonedPipeline[outgoingDs.id].dataCfg.cfg.leftData = "";
            }
          }
        } else {
          clonedPipeline[outgoingDs.id].dataCfg.cfg.inputDs = "";
        }
        if (outgoingDs.data.nodeType === "split") {
          if (
            clonedPipeline[outgoingDs.id].dataCfg.cfg?.splitType ===
            "split-excl"
          ) {
            if (!nodeConfigurations[outgoingDs.id]?.excluDs) {
              clonedPipeline[outgoingDs.id].dataCfg.cfg.excluDs = "";
            }
          }
        }
        clonedNodeConfigs[outgoingDs.id] =
          clonedPipeline[outgoingDs.id].dataCfg.cfg;
      }
    }
  };

  const onEdgeUpdate = (oldEdge, newConnection) => {
    if (
      isPolling ||
      oldEdge.animated ||
      globalCollapseEnabled ||
      collapsedDs.length
    ) {
      return;
    }
    const clonedPipeline = structuredClone(pipeline);
    const outgoingNode = nodes.find((node) => node.id === oldEdge.source);
    const incomingNode = nodes.find((node) => node.id === oldEdge.target);
    const connectionReceivingNode = nodes.find(
      (node) => node.id === newConnection.source
    );
    const connectionMakingNode = nodes.find(
      (node) => node.id === newConnection.target
    );
    let flow = reactFlowInstance.toObject();
    const outgoingEdges = getEdges().filter(
      (edge) =>
        edge.target === connectionMakingNode.id &&
        edge.source !== outgoingNode.id
    );
    const isConnectionRestricted = checkRestrictedConnection(
      connectionMakingNode,
      connectionReceivingNode,
      flow,
      outgoingEdges,
      incomingNode
    );
    const incomingUnStagedNode = getPreviousUnStagedNode(flow, incomingNode);
    if (isConnectionRestricted || !incomingUnStagedNode) {
      return;
    }
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
    const clonedNodeConfigs = structuredClone(nodeConfigurations);
    // if old outgoing node was a stage node
    if (outgoingNode.data.isStage) {
      if (!incomingNode.data.isStage) {
        // if old incoming node was a ds type of node then clear all its stages
        for (let stage of clonedPipeline[incomingNode.id].stageOrder) {
          delete clonedPipeline[incomingNode.id][stage];
        }
        clonedPipeline[incomingNode.id].stageOrder = [];
        clonedPipeline[incomingNode.id].stageCount = 0;
      } else {
        // if old incoming node was stage node then modify the stages of previous ds node
        const stageOrderArray = structuredClone(
          clonedPipeline[incomingUnStagedNode.id].stageOrder
        );
        for (
          let i = stageOrderArray.indexOf(outgoingNode.id);
          i < stageOrderArray.length;
          i++
        ) {
          delete clonedPipeline[incomingUnStagedNode.id][stageOrderArray[i]];
          if (
            clonedPipeline[incomingUnStagedNode.id].stageOrder.includes(
              stageOrderArray[i]
            )
          ) {
            clonedPipeline[incomingUnStagedNode.id].stageOrder.splice(
              clonedPipeline[incomingUnStagedNode.id].stageOrder.indexOf(
                stageOrderArray[i]
              ),
              1
            );
            clonedPipeline[incomingUnStagedNode.id].stageCount--;
          }
        }
      }
      // clears the old incoming ds from the input ds and sourceInfo of the next ds node in chain
      updateInputDsAndSourceInfoOfNextDsNode(
        flow,
        incomingNode,
        outgoingNode,
        clonedPipeline,
        clonedNodeConfigs
      );
    } else {
      // removes the previous ds node from the old outgoing ds node
      handleSourceInfoAndInputDs(
        clonedPipeline,
        outgoingNode,
        incomingNode.id,
        clonedNodeConfigs
      );
    }
    // if the connection receiving node is a ds type of node then insert the previous ds of connection making node in its inputDs and sourceInfo
    if (!connectionReceivingNode.data.isStage) {
      updateInputDsAndSourceInfoOfConnectionReceivingNode(
        clonedPipeline,
        connectionMakingNode,
        connectionReceivingNode,
        clonedNodeConfigs
      );
    } else {
      const outgoingDsNodes = getOutgoingDsNodes(flow, connectionReceivingNode);
      for (let outgoingDsNode of outgoingDsNodes) {
        updateInputDsAndSourceInfoOfConnectionReceivingNode(
          clonedPipeline,
          connectionMakingNode,
          outgoingDsNode,
          clonedNodeConfigs,
          true
        );
      }
    }
    updateNodeConfigurations(clonedNodeConfigs);
    setTimeout(() => {
      saveConfig(null, null, clonedPipeline);
    }, 1);
  };

  const removeFromErrorNodes = (node) => {
    let newErrorNodes = structuredClone(errorNodes);
    const errorNode = newErrorNodes.find(
      (existingNode) => existingNode.id === node.id
    );
    if (errorNode) {
      newErrorNodes.splice(newErrorNodes.indexOf(errorNode), 1);
      updateErrorNodes(newErrorNodes);
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event) => {
    if (
      isPolling ||
      enableCopyMode ||
      globalCollapseEnabled ||
      collapsedDs.length
    ) {
      return;
    }
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("nodeType");
    const nodeClass = event.dataTransfer.getData("nodeClass");
    const modalType = event.dataTransfer.getData("modalType");
    const nodeKind = event.dataTransfer.getData("nodeKind");
    const isStage =
      event.dataTransfer.getData("isStage") === "true" ? true : false;
    const allowOneIncomingEdge =
      event.dataTransfer.getData("allowOneIncomingEdge") === "true"
        ? true
        : false;
    // check if the dropped element is valid
    if (typeof type === "undefined" || !type) {
      return;
    }
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNode = {
      id: getId(isStage),
      type: "customNode",
      name: type,
      className: nodeClass + " error",
      position,
      data: {
        label: labelMap[type],
        nodeType: type,
        modalType: modalType,
        nodeKind: nodeKind,
        isStage: isStage,
        allowOneIncomingEdge: allowOneIncomingEdge,
      },
      ariaLabel: labelMap[type],
    };
    let newErrorNodes = structuredClone(errorNodes);
    newErrorNodes.push(newNode);
    updateErrorNodes(newErrorNodes);
    setNodes((nds) => nds.concat(newNode));
    setTimeout(() => {
      saveConfig();
    }, 1);
  };

  const addDuplicatedNode = (sourceNode) => {
    const drawingArea = document.querySelector(".dndflow .react-flow__pane");
    simulateMouseClick(drawingArea);
    let newNode = structuredClone(sourceNode);
    newNode.position.y -= 60;
    newNode.id = getId(sourceNode.data.isStage);
    newNode.ariaLabel =
      sourceNode.ariaLabel.split("_")[0] + "_" + newNode.id.split("_")[1];
    if (sourceNode.className.includes("error")) {
      let newErrorNodes = structuredClone(errorNodes);
      newErrorNodes.push(newNode);
      updateErrorNodes(newErrorNodes);
    }
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    newNodeConfigurations[newNode.id] = structuredClone(
      nodeConfigurations[sourceNode.id]
    );
    if (["fileUpload", "pySpark", "python"].includes(newNode.data.nodeType)) {
      newNodeConfigurations[newNode.id].fileName = newNode.ariaLabel;
    } else if (newNode.data.nodeType === "filter") {
      newNodeConfigurations[newNode.id].filterCfg[0].dataCfg.cfg.name =
        newNode.ariaLabel;
    } else {
      newNodeConfigurations[newNode.id].name = newNode.ariaLabel;
    }
    newNodeConfigurations[newNode.id].inputDs = "";
    newNodeConfigurations[newNode.id].sourceInfo = {};
    if (newNode.data.nodeType === "join") {
      newNodeConfigurations[newNode.id].leftData = "";
      newNodeConfigurations[newNode.id].rightData = [];
    } else if (newNode.data.nodeType === "split") {
      if (newNodeConfigurations[newNode.id].splitType === "split-excl") {
        if (!newNodeConfigurations[newNode.id].excluDs) {
          newNodeConfigurations[newNode.id].excluDs = "";
        }
      }
    }
    updateNodeConfigurations(newNodeConfigurations);
    setNodes((nds) => nds.concat(newNode));
    setTimeout(() => {
      saveConfig(null, null, null, newNodeConfigurations);
    }, 1);
  };

  // Next few functions help delete a Node from the pipeline.
  const removeNodeFromDataSourceAndLocalFileUploadNodes = (configs, node) => {
    if (configs.dataSourceOrder?.includes(node.id)) {
      configs.dataSourceOrder.splice(
        configs.dataSourceOrder.indexOf(node.id),
        1
      );
      configs.clientMetaData.dsCount--;
      delete configs.clientMetaData.dsMap[node.id];
    }
    if (configs.localFileUploadNodes?.includes(node.id)) {
      configs.localFileUploadNodes.splice(
        configs.localFileUploadNodes.indexOf(node.id),
        1
      );
    }
    if (configs.apiNodes?.includes(node.id)) {
      configs.apiNodes.splice(configs.apiNodes.indexOf(node.id), 1);
    }
    if (configs.pipelineNodes?.includes(node.id)) {
      configs.pipelineNodes.splice(configs.pipelineNodes.indexOf(node.id), 1);
    }
    if (configs.mlNodes?.includes(node.id)) {
      configs.mlNodes.splice(configs.mlNodes.indexOf(node.id), 1);
    }
  };

  const deleteStageNode = (clonedPipeline, node, clonedNodeConfigs) => {
    const flow = clonedPipeline.clientMetaData.flow;
    let incomingNodeId = null;
    let incomingEdge = flow.edges.find((edge) => edge.source === node.id);
    if (incomingEdge) {
      incomingNodeId = incomingEdge.target;
      const incomingNode = getNode(incomingNodeId);
      if (incomingNode) {
        if (!incomingNode.data.isStage && clonedPipeline[incomingNodeId]) {
          for (let stage of clonedPipeline[incomingNodeId].stageOrder) {
            delete clonedPipeline[incomingNodeId][stage];
          }
          clonedPipeline[incomingNodeId].stageOrder = [];
          clonedPipeline[incomingNodeId].stageCount = 0;
        } else {
          const incomingUnStagedNode = getPreviousUnStagedNode(
            flow,
            incomingNode
          );
          if (incomingUnStagedNode && clonedPipeline[incomingUnStagedNode.id]) {
            const stageOrderArray = structuredClone(
              clonedPipeline[incomingUnStagedNode.id].stageOrder
            );
            for (
              let i = stageOrderArray.indexOf(node.id);
              i < stageOrderArray.length;
              i++
            ) {
              delete clonedPipeline[incomingUnStagedNode.id][
                stageOrderArray[i]
              ];
              if (
                clonedPipeline[incomingUnStagedNode.id].stageOrder.includes(
                  stageOrderArray[i]
                )
              ) {
                clonedPipeline[incomingUnStagedNode.id].stageOrder.splice(
                  clonedPipeline[incomingUnStagedNode.id].stageOrder.indexOf(
                    stageOrderArray[i]
                  ),
                  1
                );
                clonedPipeline[incomingUnStagedNode.id].stageCount--;
              }
            }
          }
        }
        updateInputDsAndSourceInfoOfNextDsNode(
          flow,
          incomingNode,
          node,
          clonedPipeline,
          clonedNodeConfigs
        );
      }
    }
  };

  const deleteDSNode = (
    clonedPipeline,
    node,
    clonedNodeConfigs,
    clonedVisitedStageNodes
  ) => {
    if (clonedPipeline[node.id]?.stageCount) {
      for (let stage of clonedPipeline[node.id].stageOrder) {
        if (clonedVisitedStageNodes.includes(stage)) {
          clonedVisitedStageNodes.splice(
            clonedVisitedStageNodes.indexOf(stage),
            1
          );
        }
      }
    }
    const flow = clonedPipeline.clientMetaData.flow;
    updateInputDsAndSourceInfoOfNextDsNode(
      flow,
      node,
      node,
      clonedPipeline,
      clonedNodeConfigs
    );
    delete clonedPipeline[node.id];
  };

  const deleteFromVisitedStagedNodes = (nodeId, clonedVisitedStageNodes) => {
    if (clonedVisitedStageNodes.includes(nodeId)) {
      clonedVisitedStageNodes.splice(
        clonedVisitedStageNodes.indexOf(nodeId),
        1
      );
    }
  };

  const deleteNodeFromPipeline = (node) => {
    const clonedPipeline = structuredClone(pipeline);
    const clonedVisitedStageNodes = structuredClone(visitedStageNodes);
    const clonedNodeConfigs = structuredClone(nodeConfigurations);
    if (!clonedPipeline) {
      return;
    }
    deleteNode(
      clonedPipeline,
      clonedNodeConfigs,
      node,
      clonedVisitedStageNodes
    );
    clonedPipeline.clientMetaData.flow = reactFlowInstance.toObject();
    updatePipeline(clonedPipeline);
    updateVisitedStageNodes(clonedVisitedStageNodes);
    deleteNodeConfigurations(clonedNodeConfigs, node);
  };

  const deleteNodeConfigurations = (clonedNodeConfigs, node) => {
    const nodeID = node?.id;
    if (nodeID) {
      removeDeletedNodeReferences(nodeID, clonedNodeConfigs);
      delete clonedNodeConfigs[nodeID];
    }
    updateNodeConfigurations(clonedNodeConfigs);
  };

  const removeDeletedNodeReferences = (deletedNodeID, clonedNodeConfigs) => {
    const nodes = Object.keys(clonedNodeConfigs);
    nodes.forEach((nodeID) => {
      if (clonedNodeConfigs[nodeID]?.splitType === "split-excl") {
        if (clonedNodeConfigs[nodeID]?.excluDs === deletedNodeID) {
          clonedNodeConfigs[nodeID].excluDs = "";
        }
      } else if (clonedNodeConfigs[nodeID]?.leftData === deletedNodeID) {
        clonedNodeConfigs[nodeID].leftData = "";
      }
    });
  };

  // Next few functions help to highlight and unhighlight nodes that are problematic.
  const unHighlightProblematicNode = (updatedNode) => {
    if (updatedNode.className.includes("error")) {
      updatedNode.className = updatedNode.className.split(" ")[0];
      const updatedNodes = nodes.map((node) =>
        node.id === updatedNode.id ? updatedNode : node
      );
      setNodes(updatedNodes);
      removeFromErrorNodes(updatedNode);
    }
  };

  const highlightProblematicNodes = (
    dependentNodes,
    deletableNode = null,
    isImported = false,
    animatedNodes = false
  ) => {
    if (dependentNodes.length > 0) {
      let newNodes = animatedNodes ? animatedNodes : structuredClone(nodes);
      const newErrorNodes = structuredClone(errorNodes);
      for (let dependentNode of dependentNodes) {
        let errorNode = null;
        if (isImported) {
          errorNode = structuredClone(
            newNodes.find((node) => node.id === dependentNode)
          );
        } else {
          errorNode = structuredClone(
            newNodes.find((node) => node.id === dependentNode.id)
          );
        }
        if (errorNode) {
          errorNode.className = errorNode.className.split(" ")[0] + " error";
          const updatedNodes = newNodes
            .map((node) => {
              if (isImported) {
                if (node.id === dependentNode) {
                  return errorNode;
                } else {
                  return node;
                }
              } else {
                if (node.id === dependentNode.id) {
                  return errorNode;
                } else if (node.id === deletableNode[0].id) {
                  return null;
                } else {
                  return node;
                }
              }
            })
            .filter((node) => node !== null);
          newNodes = updatedNodes;
          if (!newErrorNodes.find((errorNd) => errorNd.id === errorNode.id)) {
            newErrorNodes.push(errorNode);
          }
        }
      }
      if (animatedNodes) {
        return newNodes;
      } else {
        setNodes(newNodes);
      }
      updateErrorNodes(newErrorNodes);
    }
  };

  const deleteMultiNodesFromUI = () => {
    setNodes(
      nodes.filter((n) => {
        if (copyNodesList.find((copyNd) => copyNd.id === n.id)) {
          return false;
        }
        return true;
      })
    );
    setEdges(
      copyNodesList.reduce((acc, node) => {
        const connectedEdges = getConnectedEdges([node], edges);
        const remainingEdges = acc.filter(
          (edge) => !connectedEdges.includes(edge)
        );
        return [...remainingEdges];
      }, edges)
    );
  };

  const deleteNode = (
    clonedPipeline,
    clonedNodeConfigs,
    nd,
    clonedVisitedStageNodes
  ) => {
    removeNodeFromDataSourceAndLocalFileUploadNodes(clonedPipeline, nd);
    if (nd.data.isStage) {
      deleteStageNode(clonedPipeline, nd, clonedNodeConfigs);
      deleteFromVisitedStagedNodes(nd.id, clonedVisitedStageNodes);
    } else {
      deleteDSNode(
        clonedPipeline,
        nd,
        clonedNodeConfigs,
        clonedVisitedStageNodes
      );
    }
  };

  const deleteMultiNodesFromConfigs = () => {
    const clonedPipeline = structuredClone(pipeline);
    const clonedVisitedStageNodes = structuredClone(visitedStageNodes);
    const clonedNodeConfigs = structuredClone(nodeConfigurations);
    if (!clonedPipeline) {
      return;
    }
    copyNodesList.forEach((nd) => {
      removeFromErrorNodes(nd);
      deleteNode(
        clonedPipeline,
        clonedNodeConfigs,
        nd,
        clonedVisitedStageNodes
      );
      if (nd.id) {
        removeDeletedNodeReferences(nd.id, clonedNodeConfigs);
        delete clonedNodeConfigs[nd.id];
      }
    });
    clonedPipeline.clientMetaData.flow = reactFlowInstance.toObject();
    updateNodeConfigurations(clonedNodeConfigs);
    updatePipeline(clonedPipeline);
    updateVisitedStageNodes(clonedVisitedStageNodes);
  };

  const handleMultiNodeDeletion = async () => {
    if (isPolling || globalCollapseEnabled || collapsedDs.length) {
      return;
    }
    deleteMultiNodesFromUI();
    setTimeout(() => {
      deleteMultiNodesFromConfigs();
    }, 1);
  };

  const onNodesDelete = (deleted) => {
    if (isPolling || globalCollapseEnabled || collapsedDs.length) {
      return;
    }
    setNodes(nodes.filter((n) => n.id !== deleted[0].id));
    setEdges(
      deleted.reduce((acc, node) => {
        const connectedEdges = getConnectedEdges([node], edges);
        // code to highlight the next node on node deletion
        // if (incomers.length > 0) {
        //   highlightProblematicNodes(incomers, deleted);
        // }
        const remainingEdges = acc.filter(
          (edge) => !connectedEdges.includes(edge)
        );
        return [...remainingEdges];
      }, edges)
    );
    removeFromErrorNodes(deleted[0]);
    setTimeout(() => {
      deleteNodeFromPipeline(deleted[0]);
      setDeletableNode(null);
      setToolbarPosition(null);
    }, 2);
  };

  const updateSubtypeForWindowFeaturesNode = (
    nodeConfigs,
    stageNode,
    configurations,
    incomingUnStagedNode
  ) => {
    configurations = structuredClone(configurations);
    let newNodeConfigurations = structuredClone(nodeConfigurations);
    let nType, nSubtype;
    if (nodeConfigs?.[stageNode.id]) {
      nSubtype = nodeConfigs[stageNode.id].subtype;
      nType = nodeConfigs[stageNode.id].type;
    } else if (newNodeConfigurations[stageNode.id]) {
      nSubtype = newNodeConfigurations[stageNode.id].subtype;
      nType = newNodeConfigurations[stageNode.id].type;
    } else {
      nSubtype =
        configurations[incomingUnStagedNode.id][stageNode.id].cfg.subtype;
      nType = configurations[incomingUnStagedNode.id][stageNode.id].cfg.type;
    }
    configurations[incomingUnStagedNode.id][stageNode.id].subtype = nSubtype;
    configurations[incomingUnStagedNode.id][stageNode.id].type = nType;
    if (nType === "win-collate") {
      configurations[incomingUnStagedNode.id][stageNode.id].subtype =
        "win-collate";
    }
    return configurations;
  };

  const updateStageNodeSubtype = (
    nodeConfigs,
    stageNode,
    configurations,
    incomingUnStagedNode
  ) => {
    configurations = structuredClone(configurations);
    let newNodeConfigurations = structuredClone(nodeConfigurations);
    let subTypeAsNodeKind = [
      "interaction",
      "ratio",
      "stat-txn",
      "num-arith",
      "ren-col",
      "sort-cfg",
      "drop-dup",
      "drop-features",
      "missing-imp",
      "type-conv",
      "uid-add",
      "fix-val",
      "extr-str",
      "rep-val",
      "collapse",
      "pad",
      "value-map",
      "obs-win",
      "perf-win",
      "bin-comp",
      "perwinTarget",
      "fill",
      "precision",
      "dpdExtract",
    ];
    let nSubtype = "";
    if (subTypeAsNodeKind.includes(stageNode.data.nodeKind)) {
      nSubtype = stageNode.data.nodeKind;
    } else if (stageNode.data.nodeKind === "trend-shift") {
      nSubtype = "per-stat";
    } else if (stageNode.data.nodeKind === "agg") {
      nSubtype = "agg-single";
    } else if (stageNode.data.nodeKind === "date-fn") {
      if (nodeConfigs?.[stageNode.id]) {
        nSubtype = nodeConfigs[stageNode.id].subType;
      } else if (newNodeConfigurations[stageNode.id]) {
        nSubtype = newNodeConfigurations[stageNode.id].subType;
      }
    }
    if (
      stageNode.data.nodeKind === "interaction" ||
      stageNode.data.nodeKind === "ratio"
    ) {
      if (nodeConfigs?.[stageNode.id] && nodeConfigs[stageNode.id].subType) {
        nSubtype = nodeConfigs[stageNode.id].subType;
      } else if (
        newNodeConfigurations[stageNode.id] &&
        newNodeConfigurations[stageNode.id].subType
      ) {
        nSubtype = newNodeConfigurations[stageNode.id].subType;
      }
    }
    configurations[incomingUnStagedNode.id][stageNode.id].subtype = nSubtype;
    if (stageNode.data.nodeType === "windowFeatures") {
      configurations = updateSubtypeForWindowFeaturesNode(
        nodeConfigs,
        stageNode,
        configurations,
        incomingUnStagedNode
      );
    }
    return configurations;
  };

  const updateStageConfigurations = (
    nodeConfigs,
    stageNode,
    configurations,
    incomingUnStagedNode
  ) => {
    configurations = structuredClone(configurations);
    let newNodeConfigurations = structuredClone(nodeConfigurations);
    let cfg;
    if (nodeConfigs?.[stageNode.id]) {
      cfg = nodeConfigs[stageNode.id];
    } else if (newNodeConfigurations[stageNode.id]) {
      cfg = newNodeConfigurations[stageNode.id];
    } else {
      cfg = configurations[incomingUnStagedNode.id][stageNode.id]
        ? configurations[incomingUnStagedNode.id][stageNode.id].cfg
        : {};
    }
    if (!configurations[incomingUnStagedNode.id][stageNode.id]) {
      configurations[incomingUnStagedNode.id].stageCount++;
      configurations[incomingUnStagedNode.id][stageNode.id] = {
        id: stageNode.id,
        type: stageNode.data.nodeKind,
        subtype: "",
        cfg: {},
      };
    }
    configurations[incomingUnStagedNode.id][stageNode.id].cfg = cfg;
    configurations[incomingUnStagedNode.id][stageNode.id].cfg.inputDs =
      incomingUnStagedNode.id;
    configurations = updateStageNodeSubtype(
      nodeConfigs,
      stageNode,
      configurations,
      incomingUnStagedNode
    );
    return configurations;
  };

  const initializeStageConfigurations = (
    nodeConfigs,
    stageNode,
    configurations,
    incomingUnStagedNode
  ) => {
    configurations = structuredClone(configurations);
    let newNodeConfigurations = structuredClone(nodeConfigurations);
    const newVisitedStageNodes = structuredClone(visitedStageNodes);
    newVisitedStageNodes.push(stageNode.id);
    updateVisitedStageNodes(newVisitedStageNodes);
    configurations[incomingUnStagedNode.id].stageCount++;
    let cfg = {};
    if (nodeConfigs?.[stageNode.id]) {
      cfg = nodeConfigs[stageNode.id];
    } else if (newNodeConfigurations[stageNode.id]) {
      cfg = newNodeConfigurations[stageNode.id];
    }
    configurations[incomingUnStagedNode.id][stageNode.id] = {
      id: stageNode.id,
      type: stageNode.data.nodeKind,
      subtype: "",
      cfg: cfg,
    };
    configurations = updateStageNodeSubtype(
      nodeConfigs,
      stageNode,
      configurations,
      incomingUnStagedNode
    );
    if (
      !configurations[incomingUnStagedNode.id][stageNode.id].cfg.inputDs ||
      configurations[incomingUnStagedNode.id][stageNode.id].cfg.inputDs
        .length === 0
    ) {
      configurations[incomingUnStagedNode.id][stageNode.id].cfg.inputDs = "";
    }
    configurations[incomingUnStagedNode.id][stageNode.id].cfg.inputDs =
      incomingUnStagedNode.id;
    return configurations;
  };

  const updateStagesForPreviousNode = (
    nodeConfigs,
    stageNode,
    configurations,
    incomingUnStagedNode
  ) => {
    configurations = structuredClone(configurations);
    if (!incomingUnStagedNode) {
      return configurations;
    }
    if (visitedStageNodes.includes(stageNode.id) || nodeConfigs) {
      configurations = updateStageConfigurations(
        nodeConfigs,
        stageNode,
        configurations,
        incomingUnStagedNode
      );
    } else {
      configurations = initializeStageConfigurations(
        nodeConfigs,
        stageNode,
        configurations,
        incomingUnStagedNode
      );
    }
    return configurations;
  };

  const updateDsMap = (configurations, node, nodeConfigs) => {
    configurations["clientMetaData"].dsCount = configurations["clientMetaData"]
      .dsCount
      ? configurations["clientMetaData"].dsCount
      : 0;
    if (["fileUpload", "pySpark", "python"].includes(node.name)) {
      let fileName;
      if (nodeConfigs?.[node.id]) {
        fileName = nodeConfigs[node.id].fileName;
      } else if (nodeConfigurations?.[node.id]) {
        fileName = nodeConfigurations[node.id].fileName;
      } else {
        fileName = configurations[node.id]["dataCfg"]["cfg"].fileName;
      }
      configurations["clientMetaData"]["dsMap"][node.id] = fileName;
    } else if (node.name === "filter") {
      let cfgName = "";
      if (nodeConfigs?.[node.id]?.filterCfg) {
        cfgName = nodeConfigs[node.id].filterCfg[0].dataCfg.cfg.name;
      } else if (nodeConfigurations?.[node.id]?.filterCfg) {
        cfgName = nodeConfigurations[node.id].filterCfg[0].dataCfg.cfg.name;
      } else if (configurations[node.id]["dataCfg"]?.["cfg"].filterCfg) {
        cfgName =
          configurations[node.id]["dataCfg"]["cfg"].filterCfg[0].dataCfg.cfg
            .name;
      }
      configurations["clientMetaData"]["dsMap"][node.id] = cfgName;
    } else {
      let nName;
      if (nodeConfigs?.[node.id]?.name) {
        nName = nodeConfigs[node.id].name;
      } else if (nodeConfigurations?.[node.id]) {
        nName = nodeConfigurations[node.id].name;
      } else {
        nName = configurations[node.id]["dataCfg"]["cfg"].name;
      }
      configurations["clientMetaData"]["dsMap"][node.id] = nName;
    }
    configurations["clientMetaData"].dsCount =
      configurations.dataSourceOrder.length;
    return configurations;
  };

  const initializeClientMetaData = (configurations, flow) => {
    configurations = structuredClone(configurations);
    flow = structuredClone(flow);
    configurations["clientMetaData"] = configurations.clientMetaData
      ? configurations.clientMetaData
      : {};
    configurations["clientMetaData"]["dsMap"] = configurations["clientMetaData"]
      .dsMap
      ? configurations["clientMetaData"].dsMap
      : {};
    flow.nodes.forEach((flowNode) => {
      if (flowNode.className.includes("error")) {
        flowNode.className = flowNode.className.split(" ")[0];
        flowNode.className += " error";
      }
      // else {
      //   flowNode.className = flowNode.className.split(" ")[0];
      // }
    });
    setNodes(flow.nodes);
    configurations["clientMetaData"]["flow"] = flow;
    return { configurations: configurations, flow: flow };
  };

  const initializeNonStageNodeConfigurations = (
    configurations,
    nodeConfigs,
    node,
    configsWithCopiedNodes
  ) => {
    configurations = structuredClone(configurations);
    configurations[node.id] = {};
    if (!configurations.dataSourceOrder.includes(node.id)) {
      if (
        node.data.nodeKind !== "model-dev" &&
        node.data.nodeKind !== "model-score"
      ) {
        configurations.dataSourceOrder.push(node.id);
      }
    }
    configurations[node.id]["stageOrder"] = [];
    configurations[node.id]["stageCount"] = 0;
    configurations[node.id]["dataCfg"] = {};
    configurations[node.id]["dataCfg"]["type"] = node.data.nodeKind;
    let cfg = {};
    if (nodeConfigs?.[node.id]) {
      cfg = nodeConfigs[node.id];
    } else if (nodeConfigurations?.[node.id]) {
      cfg = nodeConfigurations[node.id];
    } else if (configsWithCopiedNodes?.[node.id]) {
      cfg = configsWithCopiedNodes[node.id];
    }
    if (!nodeConfigurations?.[node.id] && !configsWithCopiedNodes?.[node.id]) {
      cfg["modalFormEmpty"] = true;
    }
    configurations[node.id]["dataCfg"]["cfg"] = cfg;
    return configurations;
  };

  const updateLocalFileUploadNodes = (configurations, nodeConfigs, node) => {
    configurations = structuredClone(configurations);
    if (nodeConfigs?.[node.id]) {
      if (
        nodeConfigs[node.id].sourceType === "file" &&
        !configurations.localFileUploadNodes.includes(node.id)
      ) {
        configurations.localFileUploadNodes.push(node.id);
      }
    } else if (nodeConfigurations?.[node.id]) {
      if (
        nodeConfigurations[node.id].sourceType === "file" &&
        !configurations.localFileUploadNodes.includes(node.id)
      ) {
        configurations.localFileUploadNodes.push(node.id);
      }
    } else {
      if (
        configurations[node.id].sourceType === "file" &&
        !configurations.localFileUploadNodes.includes(node.id)
      ) {
        configurations.localFileUploadNodes.push(node.id);
      }
    }
    return configurations;
  };

  const updateApiNodes = (configurations, nodeConfigs, node) => {
    configurations = structuredClone(configurations);
    if (nodeConfigs?.[node.id]) {
      if (!configurations.apiNodes.includes(node.id)) {
        configurations.apiNodes.push(node.id);
      }
    } else if (nodeConfigurations?.[node.id]) {
      if (!configurations.apiNodes.includes(node.id)) {
        configurations.apiNodes.push(node.id);
      }
    } else {
      if (!configurations.apiNodes.includes(node.id)) {
        configurations.apiNodes.push(node.id);
      }
    }
    return configurations;
  };

  const updatePipelineNodes = (configurations, nodeConfigs, node) => {
    configurations = structuredClone(configurations);
    if (nodeConfigs?.[node.id]) {
      if (!configurations.pipelineNodes?.includes(node.id)) {
        configurations.pipelineNodes.push(node.id);
      }
    } else if (nodeConfigurations?.[node.id]) {
      if (!configurations.pipelineNodes?.includes(node.id)) {
        configurations.pipelineNodes.push(node.id);
      }
    } else {
      if (!configurations.pipelineNodes?.includes(node.id)) {
        configurations.pipelineNodes.push(node.id);
      }
    }
    return configurations;
  };

  const updateMlNodes = (configurations, nodeConfigs, node) => {
    configurations = structuredClone(configurations);
    if (nodeConfigs?.[node.id]) {
      if (!configurations.mlNodes?.includes(node.id)) {
        if (node.data.nodeType === "modelDevelopment") {
          configurations.mlNodes.unshift(node.id);
        } else if (node.data.nodeType === "modelScoring") {
          configurations.mlNodes.push(node.id);
        }
      }
    } else if (nodeConfigurations?.[node.id]) {
      if (!configurations.mlNodes?.includes(node.id)) {
        if (node.data.nodeType === "modelDevelopment") {
          configurations.mlNodes.unshift(node.id);
        } else if (node.data.nodeType === "modelScoring") {
          configurations.mlNodes.push(node.id);
        }
      }
    } else {
      if (!configurations.mlNodes?.includes(node.id)) {
        if (node.data.nodeType === "modelDevelopment") {
          configurations.mlNodes.unshift(node.id);
        } else if (node.data.nodeType === "modelScoring") {
          configurations.mlNodes.push(node.id);
        }
      }
    }
    return configurations;
  };

  const updateNonStageNodeConfigurations = (
    configurations,
    nodeConfigs,
    node
  ) => {
    configurations = structuredClone(configurations);
    // updates the cfg in pipeline for an existing node
    if (node.data.nodeType === "split") {
      let subType;
      if (nodeConfigs?.[node.id]) {
        subType = nodeConfigs[node.id].splitType;
      } else if (nodeConfigurations?.[node.id]) {
        subType = nodeConfigurations[node.id].splitType;
      } else {
        subType = configurations[node.id]["dataCfg"]["cfg"]?.splitType;
      }
      if (subType) {
        configurations[node.id]["dataCfg"]["subtype"] = subType;
      }
    } else if (node.data.nodeType === "filter") {
      let subType;
      if (nodeConfigs?.[node.id]) {
        subType = nodeConfigs[node.id].filterCfg[0].dataCfg.subtype;
      } else if (nodeConfigurations?.[node.id]) {
        subType = nodeConfigurations[node.id].filterCfg[0].dataCfg.subtype;
      } else {
        subType =
          configurations[node.id]["dataCfg"]["cfg"].filterCfg[0].dataCfg
            .subtype;
      }
      configurations[node.id]["dataCfg"]["subtype"] = subType;
    } else if (node.data.nodeKind === "script") {
      let subType;
      if (node.data.nodeType === "python") {
        subType = "python";
      } else if (node.data.nodeType === "pySpark") {
        subType = "pyspark";
      }
      configurations[node.id]["dataCfg"]["subtype"] = subType;
    }
    let cfg;
    if (nodeConfigs?.[node.id]) {
      cfg = nodeConfigs[node.id];
    } else if (nodeConfigurations?.[node.id]) {
      cfg = nodeConfigurations[node.id];
    } else {
      cfg = configurations[node.id]["dataCfg"]["cfg"];
    }
    configurations[node.id]["dataCfg"]["cfg"] = cfg;
    if (node.data.nodeType === "fileUpload") {
      configurations = updateLocalFileUploadNodes(
        configurations,
        nodeConfigs,
        node
      );
    } else if (node.data.nodeType === "api") {
      configurations = updateApiNodes(configurations, nodeConfigs, node);
    } else if (node.data.nodeType === "pipeline") {
      configurations = updatePipelineNodes(configurations, nodeConfigs, node);
    } else if (
      node.data.nodeType === "modelDevelopment" ||
      node.data.nodeType === "modelScoring"
    ) {
      configurations = updateMlNodes(configurations, nodeConfigs, node);
    }
    return configurations;
  };

  const organiseDsOrder = (dataSourceOrder, configurations) => {
    for (let [dsIndex, ds] of dataSourceOrder.entries()) {
      const inputDs = configurations[ds].dataCfg.cfg.inputDs;
      if (!inputDs) {
        continue;
      }
      if (typeof inputDs === "string") {
        const inputDsIndex = dataSourceOrder.indexOf(inputDs);
        if (inputDsIndex > dsIndex) {
          dataSourceOrder.splice(inputDsIndex, 1);
          dataSourceOrder.splice(dsIndex, 0, inputDs);
          return organiseDsOrder(dataSourceOrder, configurations);
        }
      } else {
        for (let inputDsItem of inputDs) {
          const inputDsIndex = dataSourceOrder.indexOf(inputDsItem);
          if (inputDsIndex > dsIndex) {
            dataSourceOrder.splice(inputDsIndex, 1);
            dataSourceOrder.splice(dsIndex, 0, inputDsItem);
            return organiseDsOrder(dataSourceOrder, configurations);
          }
        }
      }
    }
    return dataSourceOrder;
  };

  const rearrangeDatasourceOrder = (configurations) => {
    const clonedConfigurations = structuredClone(configurations);
    const datasourceOrder = clonedConfigurations.dataSourceOrder;
    let updatedDatasourceOrder = datasourceOrder;
    try {
      updatedDatasourceOrder = organiseDsOrder(datasourceOrder, configurations);
    } catch (err) {
      if (err.stack.includes("Maximum call stack size exceeded")) {
        updateAlertMessage(
          "Loop detected , please remove the loop and try again"
        );
      }
      console.error(err);
    }
    return updatedDatasourceOrder;
  };

  const saveConfig = (
    nodeConfigs = null,
    currentNodeId = null,
    clonedPipeline = null,
    configsWithCopiedNodes = null
  ) => {
    configurations = clonedPipeline
      ? structuredClone(clonedPipeline)
      : structuredClone(pipeline);
    if (!configurations.dataSourceOrder) {
      configurations["dataSourceOrder"] = [];
    }
    if (!configurations.localFileUploadNodes) {
      configurations["localFileUploadNodes"] = [];
    }
    if (!configurations.apiNodes) {
      configurations["apiNodes"] = [];
    }
    if (!configurations.pipelineNodes) {
      configurations["pipelineNodes"] = [];
    }
    if (!configurations.mlNodes) {
      configurations["mlNodes"] = [];
    }
    let flow = reactFlowInstance.toObject();
    if (reactFlowInstance && flow) {
      const configAndFlowObj = initializeClientMetaData(configurations, flow);
      configurations = configAndFlowObj.configurations;
      flow = configAndFlowObj.flow;
      flow.nodes.forEach((node) => {
        if (!node.data.isStage && (!configurations[node.id] || nodeConfigs)) {
          if (!configurations[node.id]) {
            configurations = initializeNonStageNodeConfigurations(
              configurations,
              nodeConfigs,
              node,
              configsWithCopiedNodes
            );
          } else {
            configurations = updateNonStageNodeConfigurations(
              configurations,
              nodeConfigs,
              node
            );
          }
          if (
            node.name !== "modelDevelopment" &&
            node.name !== "modelScoring"
          ) {
            configurations = updateDsMap(configurations, node, nodeConfigs);
          }
        } else if (node.data.isStage) {
          // updates the stages for a node
          const incomingUnStagedNode = getPreviousUnStagedNode(flow, node);
          configurations = updateStagesForPreviousNode(
            nodeConfigs,
            node,
            configurations,
            incomingUnStagedNode
          );
          if (incomingUnStagedNode) {
            const stageNodesChain = getStageNodesChain(
              flow,
              incomingUnStagedNode
            );
            configurations[incomingUnStagedNode.id].stageOrder =
              stageNodesChain;
            configurations[incomingUnStagedNode.id].stageCount =
              stageNodesChain.length;
          }
        }
        // if (
        //   !node.data.isStage &&
        //   !configurations.dataSourceOrder.includes(node.id) &&
        //   node.data.nodeKind !== "model-dev" &&
        //   node.data.nodeKind !== "model-score"
        // ) {
        //   configurations.dataSourceOrder.push(node.id);
        // }
      });
      const datasourceOrder = rearrangeDatasourceOrder(configurations);
      configurations.dataSourceOrder = datasourceOrder;
      if (nodeConfigs) {
        configurations = updateNodesLabel(
          nodeConfigs,
          configurations,
          currentNodeId
        );
        saveConfigurations(true, configurations, currentNodeId);
      }
      updatePipeline(configurations);
    }
  };

  const updateNodesLabel = (nodeConfigs, configurations, currentNodeId) => {
    configurations = structuredClone(configurations);
    const flow = reactFlowInstance.toObject();
    const nodeObj = flow.nodes.find((node) => node.id === currentNodeId);
    const currentNodeCfg = nodeConfigs[currentNodeId];
    if (!nodeObj || !currentNodeCfg) {
      return;
    }
    if (currentNodeCfg.name?.length >= 1) {
      nodeObj.ariaLabel = currentNodeCfg.name;
    } else if (currentNodeCfg.fileName?.length >= 1) {
      nodeObj.ariaLabel = currentNodeCfg.fileName;
    } else if (
      nodeObj.data.nodeType === "filter" &&
      currentNodeCfg.filterCfg &&
      currentNodeCfg.filterCfg[0] &&
      (currentNodeCfg.filterCfg[0].dataCfg?.cfg?.name?.length || 0) >= 1
    ) {
      nodeObj.ariaLabel = currentNodeCfg.filterCfg[0].dataCfg.cfg.name;
    } else {
      nodeObj.ariaLabel = nodeObj.data.label;
    }
    const updatedNodes = nodes.map((node) =>
      node.id === currentNodeId ? nodeObj : node
    );
    setNodes(updatedNodes);
    flow.nodes[flow.nodes.indexOf(nodeObj)] = nodeObj;
    configurations.clientMetaData.flow = flow;
    return configurations;
  };

  const getStageNodesChain = (flow, beg) => {
    let chain = [];
    chain = getNextUnStagedNode(flow, beg, chain);
    return chain;
  };

  const getNextUnStagedNode = (flow, node, chain) => {
    const outgoingEdges = flow.edges.filter((edge) => edge.target === node.id);
    let outgoingNode = null;
    let hasStageNode = false;
    for (let outgoingEdge of outgoingEdges) {
      outgoingNode = flow.nodes.find((nd) => nd.id === outgoingEdge.source);
      if (outgoingNode.data.isStage) {
        chain.push(outgoingNode.id);
        hasStageNode = true;
        break;
      }
    }
    if (outgoingEdges.lenth === 0 || !hasStageNode) {
      return chain;
    }
    return getNextUnStagedNode(flow, outgoingNode, chain);
  };

  const handleCopySelection = (nodeToCopy, nodeClasses) => {
    const nodeKind = nodeToCopy.data.nodeKind;
    if (
      nodeKind === "add" ||
      nodeKind === "pipeline" ||
      nodeClasses.includes("error")
    ) {
      return;
    }
    const currentNode = nodes.find((nd) => nd.id === nodeToCopy.id);
    if (nodeClasses.includes("copy-mode")) {
      nodeClasses.splice(nodeClasses.indexOf("copy-mode"), 1);
    }
    currentNode.className = nodeClasses.join(" ");
    setNodes(nodes);
    const drawingArea = document.querySelector(".dndflow .react-flow__pane");
    simulateMouseClick(drawingArea);
    const newCopyNodesList = structuredClone(copyNodesList);
    if (!newCopyNodesList.find((copyNd) => copyNd.id === nodeToCopy.id)) {
      newCopyNodesList.push(nodeToCopy);
      updateCopyNodesList(newCopyNodesList);
    }
  };

  const checkTarget = (edge, id) => {
    let edges = edge.filter((ed) => {
      return ed.source !== id;
    });
    return edges;
  };

  const getUpdatedNodes = (childNodeID, allNodes, isGlobalCollapseEnabled) => {
    allNodes.forEach((nd) => {
      if (childNodeID.includes(nd.id)) {
        if (isGlobalCollapseEnabled) {
          nd.hidden = true;
        } else if (isGlobalCollapseEnabled === false) {
          nd.hidden = false;
        } else {
          nd.hidden = !nd.hidden;
        }
      }
    });
    return allNodes;
  };

  const getUpdatedEdges = (
    childEdgeID,
    currentDs,
    outgoingDatasources,
    allEdges,
    isGlobalCollapseEnabled
  ) => {
    for (let outgoingDs of outgoingDatasources) {
      const dashedEdge = {
        id: "substitute_edge" + outgoingDs + "-" + currentDs.id,
        source: outgoingDs,
        target: currentDs.id,
        animated: true,
      };
      const dashedEdgeIndex = allEdges.findIndex(
        (ed) => ed.id === dashedEdge.id
      );
      if (isGlobalCollapseEnabled) {
        if (dashedEdgeIndex === -1) {
          allEdges.push(dashedEdge);
        }
      } else if (isGlobalCollapseEnabled === false) {
        if (dashedEdgeIndex !== -1) {
          allEdges.splice(dashedEdgeIndex, 1);
        }
      } else {
        if (dashedEdgeIndex !== -1) {
          allEdges.splice(dashedEdgeIndex, 1);
        } else {
          allEdges.push(dashedEdge);
        }
      }
    }
    allEdges.forEach((edge) => {
      if (childEdgeID.includes(edge.id)) {
        if (isGlobalCollapseEnabled) {
          edge.hidden = true;
        } else if (isGlobalCollapseEnabled === false) {
          edge.hidden = false;
        } else {
          edge.hidden = !edge.hidden;
        }
      }
    });
    return allEdges;
  };

  const updateConnectedEdgesAndOutgoingDs = (
    childedge,
    outgoingDatasources,
    connectedEdges
  ) => {
    for (let edge of childedge) {
      let connectionReceivingNode = getNode(edge.source);
      let connectionMakingNode = getNode(edge.target);
      if (
        connectionReceivingNode.data.isStage ||
        connectionMakingNode.data.isStage
      ) {
        connectedEdges.push(edge);
      }
      if (
        !connectionReceivingNode.data.isStage &&
        connectionMakingNode.data.isStage
      ) {
        outgoingDatasources.push(connectionReceivingNode.id);
      }
    }
  };

  const updateNodesAndEdges = (
    node,
    outgoingDatasources,
    outgoers,
    connectedEdges,
    allNodes,
    allEdges,
    isGlobalCollapseEnabled
  ) => {
    connectedEdges = Array.from(new Set(connectedEdges));
    let childNodeID = outgoers.map((node) => {
      return node.id;
    });
    let childEdgeID = connectedEdges.map((edge) => {
      return edge.id;
    });
    const updatedNodes = getUpdatedNodes(
      childNodeID,
      allNodes,
      isGlobalCollapseEnabled
    );
    const updatedEdges = getUpdatedEdges(
      childEdgeID,
      node,
      outgoingDatasources,
      allEdges,
      isGlobalCollapseEnabled
    );
    return {
      newNodes: updatedNodes,
      newEdges: updatedEdges,
    };
  };

  const collapseStages = (
    node,
    allNodes,
    allEdges,
    isGlobalCollapseEnabled = null
  ) => {
    let lastNode = null;
    let outgoingDatasources = [];
    let outgoers = [];
    let connectedEdges = [];
    stack.push(node);
    while (stack.length > 0) {
      lastNode = stack.pop();
      let childnode = getIncomers(lastNode, nodes, edges);
      let childedge = checkTarget(
        getConnectedEdges([lastNode], edges),
        node.id
      );
      for (let goer of childnode) {
        if (goer.data.isStage) {
          stack.push(goer);
          outgoers.push(goer);
        }
      }
      if (!outgoers.length) {
        return {
          newNodes: allNodes,
          newEdges: allEdges,
        };
      }
      updateConnectedEdgesAndOutgoingDs(
        childedge,
        outgoingDatasources,
        connectedEdges
      );
    }
    let updatedNodesAndEdges = updateNodesAndEdges(
      node,
      outgoingDatasources,
      outgoers,
      connectedEdges,
      allNodes,
      allEdges,
      isGlobalCollapseEnabled
    );
    return updatedNodesAndEdges;
  };

  const updateNodes = (newNodes) => {
    setNodes(newNodes);
  };

  const updateEdges = (newEdges) => {
    setEdges(newEdges);
  };

  const onNodeClick = (event, node) => {
    const nodeClasses = node.className.split(" ");
    if (isPolling && !nodeClasses.includes("completed")) {
      return;
    }
    if (enableCopyMode) {
      handleCopySelection(node, nodeClasses);
    } else {
      setDeletableNode(node);
      if (doubleClickTimeoutRef.current) {
        clearTimeout(doubleClickTimeoutRef.current);
        doubleClickTimeoutRef.current = null;
        setDeletableNode(null);
      } else {
        doubleClickTimeoutRef.current = setTimeout(() => {
          doubleClickTimeoutRef.current = null;
          setToolbarPosition({ x: event.clientX - 90, y: event.clientY - 140 });
        }, 300);
      }
    }
  };

  const onNodeDoubleClick = (event, node) => {
    if (isPolling || enableCopyMode) {
      return;
    }
    clearTimeout(doubleClickTimeoutRef.current);
    const props = {
      nodeInfo: nodeModalJson,
      uiConfig: uiModalJson,
      selectedNode: node,
      closeModal: closeModal,
      nodesFlow: reactFlowInstance.toObject(),
      saveConfig: saveConfig,
      unHighlightProblematicNode: unHighlightProblematicNode,
    };
    let ModalComponent = modalMapping[node.data.modalType];
    if (
      node.data.nodeKind === "exp-data" &&
      node.data.modalType !== "exportNodeDialog"
    ) {
      ModalComponent = modalMapping["exportNodeDialog"];
    }
    setActiveModal(<ModalComponent {...props} />);
  };

  const closeModal = (modalType) => {
    updateModalForm(null);
    updateModalUI(null);
    updateTable(null);
    updatecurrentFormType("fileUpload");
    setActiveModal(null);
    updateSnackbarMsg(null);
    updatePivotStatCfg([]);
  };

  const handleSingleClickOnPage = (event) => {
    const clickedElement = event.target;
    const clickedParentElement = event.target.parentElement;
    // if clicked anywhere except the edge then set deletable edge as null
    if (clickedElement.className.baseVal !== "react-flow__edge-interaction") {
      setDeletableEdge(null);
    }
    if (toolbarPosition) {
      // if clicked on error/summary/delete/partialExecution/dialog/dialog buttons/outside summary, error and delete node dialogs then let toolbar as it is
      if (
        (typeof clickedElement.className === "string" &&
          (clickedElement.className.includes("show-error-btn") ||
            clickedElement.className.includes("summary-btn") ||
            clickedElement.className.includes("run-till-now-btn") ||
            clickedElement.className.includes("delete-btn"))) ||
        (!clickedParentElement.closest(".MuiDialogActions-root") &&
          clickedParentElement.closest(".MuiDialog-container")) ||
        isSummaryDialogOpen ||
        isErrorDialogOpen ||
        isDeleteDialogOpen ||
        showPartialExecutionDialog
      ) {
        return;
      }
      // if clicked anywhere on the screen except the node then hide toolbar
      if (!clickedParentElement.closest(".react-flow__nodes")) {
        setToolbarPosition(null);
        setDeletableNode(null);
      }
    }
  };

  const handleNodeValidationError = (
    errorConfigs,
    fromNodeAnimation = false,
    animatedNodes = null
  ) => {
    const errorNodesList = [];
    const newNodesErrMsg = {};
    errorConfigs.forEach((errorCfg) => {
      if (errorCfg.dSource && errorCfg.stageId) {
        errorNodesList.push(errorCfg.stageId);
        newNodesErrMsg[errorCfg.stageId] = errorCfg.msg;
      } else {
        errorNodesList.push(errorCfg.dSource);
        newNodesErrMsg[errorCfg.dSource] = errorCfg.msg;
      }
    });
    updateNodesErrorMsg(newNodesErrMsg);
    updateValidationErrorNodes(errorNodesList);
    if (fromNodeAnimation) {
      return highlightProblematicNodes(
        errorNodesList,
        null,
        true,
        animatedNodes
      );
    } else {
      highlightProblematicNodes(errorNodesList, null, true);
    }
  };

  const saveConfigurations = async (
    fromButton = false,
    updatedPipeline = null,
    savedNodeId = null
  ) => {
    const payload = updatedPipeline
      ? structuredClone(updatedPipeline)
      : structuredClone(pipeline);
    payload["projectKey"] = projectKey;
    payload["projVersion"] = projVersion;
    payload["projFg"] = featureGroup;
    payload["saveType"] = fromButton ? "fullmode" : "automode";
    payload["pname"] = currentProjName;
    payload["description"] = currentFgDesc;
    const apiUrl = BASE_API_URL + "savefecfg";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    if (fromButton) {
      let problematicNodes = null;
      if (savedNodeId) {
        const errorNodesInstance = JSON.parse(JSON.stringify(errorNodes));
        problematicNodes = errorNodesInstance.filter(
          (node) => node.id !== savedNodeId
        );
      } else {
        problematicNodes = structuredClone(errorNodes);
      }
      if (problematicNodes.length > 0) {
        updateAlertMessage(
          "There is an error in pipeline — Please save the configurations for the highlighted " +
            problematicNodes[0].name +
            " node or delete it and save again"
        );
      } else if (isEmpty(pipeline)) {
        updateAlertMessage(
          "Pipeline is empty — Please do some configurations and then try to save again"
        );
      } else {
        updateAlertMessage(null);
        updateIsPlaygroundLoading(true);
        try {
          let response = {};
          if (USING_TEST_DATA) {
            response = {
              data: savefecfgAPITestData,
            };
          } else {
            response = await api.post(apiUrl, payload, {
              headers: headers,
            });
          }
          updateIsPlaygroundLoading(false);
          if (response.data.status === 200) {
            updateValidationErrorNodes([]);
            updateNodesErrorMsg({});
            handleModellingNodesRedirect(
              payload,
              savedNodeId,
              projectKey,
              projVersion,
              featureGroup,
              updateIsPlaygroundLoading,
              handleMultipleModellingNodesAnimation,
              updateAlertMessage
            );
          } else if (response.data.status === 404) {
            if (response.data.data.reason) {
              updateAlertMessage(response.data.data.reason);
            } else {
              updateAlertMessage(
                "Something went wrong. Please try again later"
              );
            }
            if (response.data.data.posts.length) {
              handleNodeValidationError(response.data.data.posts[0]);
            }
          }
        } catch (error) {
          console.log(error);
          updateIsPlaygroundLoading(false);
          const errorMessage =
            "Something went wrong. Please contact the administrator";
          updateAlertMessage(errorMessage);
        }
      }
    } else {
      try {
        let response = {};
        if (USING_TEST_DATA) {
          response = {
            data: savefecfgAPITestData,
          };
        } else {
          response = await api.post(apiUrl, payload, { headers: headers });
        }
        if (response.data.status === 404) {
          if (response.data.data.reason) {
            updateAlertMessage(response.data.data.reason);
          } else {
            updateAlertMessage("Something went wrong. Please try again later");
          }
        }
      } catch (error) {
        console.log(error);
        updateIsPlaygroundLoading(false);
        const errorMessage =
          "Something went wrong. Please contact the administrator";
        updateAlertMessage(errorMessage);
      }
    }
  };

  const focusOnNode = (focusNode) => {
    const x = focusNode.position.x + focusNode.width / 2;
    const y = focusNode.position.y + focusNode.height / 2;
    const zoom = 1.85;
    setCenter(x, y, { zoom, duration: 1000 });
  };

  const handleNodesAnimation = (
    pollData,
    feCfg,
    showValidationError = false,
    validationErrorNodes = []
  ) => {
    if (!reactFlowInstance && !feCfg) {
      return;
    }
    let flow = null;
    if (reactFlowInstance) {
      flow = reactFlowInstance.toObject();
    } else {
      flow = feCfg.clientMetaData.flow;
    }
    let nodes = flow.nodes;
    for (let node of nodes) {
      if (!node.className.includes("error")) {
        node.className = node.className.split(" ")[0];
        if (pollData.completedNodes.includes(node.id)) {
          node.className += " completed";
        } else if (pollData.inProgressNodes.includes(node.id)) {
          node.className += " in-progress";
        }
      }
    }
    if (pollData.inProgressNodes.length) {
      const focusNode = getNode(pollData.inProgressNodes[0]);
      focusOnNode(focusNode);
    }
    if (showValidationError) {
      nodes = handleNodeValidationError(validationErrorNodes, true, nodes);
    }
    setNodes(nodes);
    let newPipeline = null;
    if (!isEmpty(feCfg)) {
      newPipeline = structuredClone(feCfg);
    } else if (!isEmpty(pipeline)) {
      newPipeline = structuredClone(pipeline);
    } else {
      return;
    }
    newPipeline.clientMetaData.flow = structuredClone(flow);
    updatePipeline(newPipeline);
  };

  const handleMLNodesPolling = (feCfg) => {
    if (feCfg?.mlNodes && feCfg.mlNodes.length > 0) {
      updateIsPolling(true);
      const mlNodesPollIntervalTime = 10 * 1000;
      const mlNodesPollInterval = setInterval(() => {
        mlNodesPollFunction(
          feCfg,
          mlNodesPollInterval,
          cpuAndMemoryUsagePollInterval
        );
      }, mlNodesPollIntervalTime);
      const cpuAndMemoryUsagePollInterval = setInterval(() => {
        getCpuAndMemoryUsage(cpuAndMemoryUsagePollInterval, true);
      }, PROCESSOR_USAGE_POLL_TIME);
      intervalIds.current.push(mlNodesPollInterval);
      intervalIds.current.push(cpuAndMemoryUsagePollInterval);
      mlNodesPollFunction(
        feCfg,
        mlNodesPollInterval,
        cpuAndMemoryUsagePollInterval
      );
    }
  };

  const mlNodesPollFunction = (
    feCfg,
    mlNodesPollInterval,
    cpuAndMemoryUsagePollInterval
  ) => {
    callPostAPIForMlNodes(
      feCfg,
      mlNodesPollInterval,
      cpuAndMemoryUsagePollInterval
    );
  };

  const callPostAPIForMlNodes = async (
    feCfg,
    mlNodesPollInterval,
    cpuAndMemoryUsagePollInterval
  ) => {
    try {
      const apiUrl = `${BASE_API_URL}pollapi`;
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        action: "automl-action",
        projectKey,
        projVersion,
        projFg: featureGroup,
      };
      let result = {};
      if (USING_TEST_DATA) {
        result = {
          data: modellingPollapiAPITestData,
        };
      } else {
        result = await api.post(apiUrl, payload, { headers });
      }
      if (!result) {
        handleFailure(
          "Null result in polling.",
          mlNodesPollInterval,
          cpuAndMemoryUsagePollInterval
        );
        return;
      }
      result = result.data;
      if (result?.status === 404) {
        handleFailure(
          result.data?.reason,
          mlNodesPollInterval,
          cpuAndMemoryUsagePollInterval
        );
        return;
      }
      if (!result || typeof result === "undefined") {
        handleFailure(
          "Null result in polling.",
          mlNodesPollInterval,
          cpuAndMemoryUsagePollInterval
        );
        return;
      }
      if (result.status === "Failure") {
        handleFailure(
          result.message,
          mlNodesPollInterval,
          cpuAndMemoryUsagePollInterval
        );
        return;
      }
      if (result.status === "Success" || result.status === "InProgress") {
        const nodesData = result.data.posts[0] || {};
        handleMultipleModellingNodesAnimation(nodesData, feCfg);
        if (result.status === "Success") {
          stopPollAPIPolling(
            mlNodesPollInterval,
            cpuAndMemoryUsagePollInterval
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFailure = (
    errorMessage,
    mlNodesPollInterval,
    cpuAndMemoryUsagePollInterval
  ) => {
    if (errorMessage) {
      updateAlertMessage(errorMessage);
      console.error(errorMessage);
    } else {
      console.error(
        "Received status failed in polling. Please contact administrator."
      );
    }
    stopPollAPIPolling(mlNodesPollInterval, cpuAndMemoryUsagePollInterval);
  };

  const stopPollAPIPolling = (
    mlNodesPollInterval,
    cpuAndMemoryUsagePollInterval
  ) => {
    updateIsPolling(false);
    if (mlNodesPollInterval) {
      intervalIds.current.splice(
        intervalIds.current.indexOf(mlNodesPollInterval),
        1
      );
      clearInterval(mlNodesPollInterval);
    }
    if (cpuAndMemoryUsagePollInterval) {
      intervalIds.current.splice(
        intervalIds.current.indexOf(cpuAndMemoryUsagePollInterval),
        1
      );
      clearInterval(cpuAndMemoryUsagePollInterval);
    }
  };

  const handleMultipleModellingNodesAnimation = (
    nodesData,
    feCfg,
    currentNodeId
  ) => {
    try {
      if (!reactFlowInstance && !feCfg) {
        return;
      }
      let flow = null;
      if (reactFlowInstance) {
        flow = reactFlowInstance.toObject();
      } else {
        flow = feCfg.clientMetaData.flow;
      }
      const newModellingNodesData = structuredClone(modellingNodesData);
      if (currentNodeId) {
        const nodeData = nodesData[currentNodeId];
        handleModellingNodeAnimation(nodeData, currentNodeId, flow);
        newModellingNodesData[currentNodeId] = nodeData;
      } else {
        const modellingNodes = Object.keys(nodesData);
        modellingNodes.forEach((nodeId) => {
          const nodeData = nodesData[nodeId];
          handleModellingNodeAnimation(nodeData, nodeId, flow);
          newModellingNodesData[nodeId] = nodeData;
        });
      }
      updateModellingNodesData(newModellingNodesData);
      let newPipeline = null;
      if (!isEmpty(feCfg)) {
        newPipeline = structuredClone(feCfg);
      } else if (!isEmpty(pipeline)) {
        newPipeline = structuredClone(pipeline);
      } else {
        return;
      }
      newPipeline.clientMetaData.flow = structuredClone(flow);
      updatePipeline(newPipeline);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModellingNodeAnimation = (data, currentNodeId, flow) => {
    try {
      const nodes = flow.nodes;
      const currentNode = nodes.find((node) => node.id === currentNodeId);
      if (currentNode) {
        currentNode.className = currentNode.className.split(" ")[0];
        if (data.state === "Completed") {
          currentNode.className += " completed";
        } else if (data.state === "InProgress") {
          currentNode.className += " in-progress";
          const focusNode = getNode(currentNodeId);
          focusOnNode(focusNode);
        } else if (data.state === "Failed") {
          if (data.msg) {
            updateAlertMessage(data.msg);
          }
        }
      }
      setNodes(nodes);
    } catch (error) {
      console.error(error);
    }
  };

  const handleStopAndResumeBtn = (pollStatus, result = null) => {
    if (pollStatus === "failed" && result.msg) {
      setShowStopBtn(false);
      setShowResumeBtn(false);
      setDisableBackupAndRestoreBtn(false);
      updateAlertMessage(result.msg);
    } else if (pollStatus === "running") {
      setShowStopBtn(true);
      setDisableStopBtn(false);
      setShowResumeBtn(false);
      setDisableBackupAndRestoreBtn(true);
    } else if (pollStatus === "stopping") {
      setShowStopBtn(true);
      setDisableStopBtn(true);
      setDisableBackupAndRestoreBtn(true);
    } else if (pollStatus === "stopped") {
      setShowStopBtn(false);
      setShowResumeBtn(true);
      setDisableBackupAndRestoreBtn(false);
    } else if (pollStatus === "completed") {
      setShowStopBtn(false);
      setShowResumeBtn(false);
      setDisableBackupAndRestoreBtn(false);
    }
  };

  const handlePollingStoppage = (
    pollInterval,
    cpuAndMemoryUsagePollInterval
  ) => {
    updateIsPolling(false);
    if (pollInterval) {
      intervalIds.current.splice(intervalIds.current.indexOf(pollInterval), 1);
      clearInterval(pollInterval);
    }
    if (cpuAndMemoryUsagePollInterval) {
      intervalIds.current.splice(
        intervalIds.current.indexOf(cpuAndMemoryUsagePollInterval),
        1
      );
      clearInterval(cpuAndMemoryUsagePollInterval);
    }
  };

  const performPolling = async (
    pollInterval = null,
    feCfg = null,
    cpuAndMemoryUsagePollInterval = null
  ) => {
    try {
      const apiUrl = BASE_API_URL + "poll-prog";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: projectKey,
        projVersion: projVersion,
        projFg: featureGroup,
      };
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: pollProgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
          withCredentials: true,
        });
      }
      setShowDisabledButtons(false);
      if (response.data.status === 200) {
        let result = response.data.data.posts[0];
        const pollStatus = result.status.toLowerCase();
        let showValidationError =
          pollStatus === "validationerror" ? true : false;
        let validationErrorNodes = result.errMsg?.length ? result.errMsg : [];
        if (pollStatus === "running" || pollStatus === "stopping") {
          pollFurther.current = true;
        } else {
          pollFurther.current = false;
        }
        handleStopAndResumeBtn(pollStatus, result);
        const pollData = result;
        if (showValidationError) {
          if (result.msg) {
            updateAlertMessage(result.msg);
          } else {
            updateAlertMessage("Something went wrong. Please try again later");
          }
        }
        handleNodesAnimation(
          pollData,
          feCfg,
          showValidationError,
          validationErrorNodes
        );
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        pollFurther.current = false;
        handleStopAndResumeBtn("completed");
      }
      if (!pollFurther.current) {
        handlePollingStoppage(pollInterval, cpuAndMemoryUsagePollInterval);
        handleMLNodesPolling(feCfg);
      }
    } catch (error) {
      console.log(error);
      pollFurther.current = false;
      handleStopAndResumeBtn("completed");
      handlePollingStoppage(pollInterval, cpuAndMemoryUsagePollInterval);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const updateCpuAndMemoryUsageLists = (procStats) => {
    const newCpuUsageArr = [...cpuUsageArr.current, procStats.CpuUsage];
    const newMemoryUsageArr = [...memoryUsageArr.current, procStats.MemUsage];
    if (newCpuUsageArr.length > 50) {
      newCpuUsageArr.shift();
    }
    if (newMemoryUsageArr.length > 50) {
      newMemoryUsageArr.shift();
    }
    cpuUsageArr.current = newCpuUsageArr;
    memoryUsageArr.current = newMemoryUsageArr;
    updateCpuUsage(newCpuUsageArr);
    updateMemoryUsage(newMemoryUsageArr);
  };

  const getCpuAndMemoryUsage = async (
    cpuAndMemoryUsagePollInterval,
    mlNodesPolling = false
  ) => {
    if (!mlNodesPolling && !pollFurther.current) {
      clearInterval(cpuAndMemoryUsagePollInterval);
    }
    try {
      const apiUrl = BASE_API_URL + "fetchresusage";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: projectKey,
        projVersion: projVersion,
        projFg: featureGroup,
      };
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: fetchresusageAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      if (response.data.status === 200) {
        const processorStats = response.data.data.posts[0].ProcessorUtilization;
        updateCpuAndMemoryUsageLists(processorStats);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const executePipeline = async (
    terminator = null,
    isResumed = false,
    skipExecutionList = []
  ) => {
    try {
      const apiUrl = BASE_API_URL + "startexecution";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = structuredClone(pipeline);
      payload["projectKey"] = projectKey;
      payload["projVersion"] = projVersion;
      payload["projFg"] = featureGroup;
      payload["saveType"] = "runmode";
      payload["runType"] = isResumed || startDs ? "resume" : "full";
      payload["terminator"] = terminator;
      payload["skipExecutionList"] = skipExecutionList;
      payload["startOffset"] = startDs;
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: startexecutionAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateStartDs(null);
      if (response.data.status === 200) {
        updateIsPolling(true);
        await performPolling();
        if (pollFurther.current) {
          const cpuAndMemoryUsagePollInterval = setInterval(() => {
            getCpuAndMemoryUsage(cpuAndMemoryUsagePollInterval);
          }, PROCESSOR_USAGE_POLL_TIME);
          const pollInterval = setInterval(() => {
            performPolling(pollInterval, null, cpuAndMemoryUsagePollInterval);
          }, PROGRESS_POLL_TIME);
          intervalIds.current.push(pollInterval);
          intervalIds.current.push(cpuAndMemoryUsagePollInterval);
        } else {
          updateIsPolling(false);
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsPlaygroundLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const getSelectedProjectState = (pKey, pVersion, pFg, allProjects) => {
    const proj = allProjects.find((obj) => obj.projectKey === pKey);
    const version = proj.versionInfo.find((obj) => obj.vname === pVersion);
    const fg = version.fgInfo.find((obj) => obj.projFg === pFg);
    return fg.state;
  };

  const updateChecklist = (allProj, checkList, pipelineNode) => {
    const pipelineCfg = pipeline[pipelineNode].dataCfg.cfg;
    const pipelineStatus = getSelectedProjectState(
      pipelineCfg.sourceProjectKey,
      pipelineCfg.sourceProjVersion,
      pipelineCfg.sourceProjFg,
      allProj
    );
    if (pipelineStatus.toLowerCase() === "completed") {
      const obj = {
        id: pipelineNode,
        name: pipeline.clientMetaData.dsMap[pipelineNode],
        checked: true,
      };
      checkList.push(obj);
    }
  };

  const checkCompletedPipelines = (allProj, terminator) => {
    let checkList = [];
    if (startDs) {
      const datasources = pipeline.dataSourceOrder;
      for (
        let i = datasources.indexOf(startDs);
        i <= datasources.indexOf(terminator.dSource);
        i++
      ) {
        if (pipeline.pipelineNodes.includes(datasources[i])) {
          updateChecklist(allProj, checkList, datasources[i]);
        }
      }
    } else {
      if (pipeline.pipelineNodes) {
        for (let pipelineNode of pipeline.pipelineNodes) {
          updateChecklist(allProj, checkList, pipelineNode);
        }
      }
    }
    setCheckboxList(checkList);
    if (checkList.length) {
      updateShowPipelineExecutionDialog(true);
    } else {
      updateShowPipelineExecutionDialog(false);
      executePipeline(terminator);
    }
  };

  const getProjects = async (terminator) => {
    try {
      const apiUrl = BASE_API_URL + "listprojects";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {};
      updateIsPlaygroundLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: listprojectsAPITestData,
        };
      } else {
        if (IS_LIST_PROJECTS_GET_TYPE) {
          response = await api.get(apiUrl, {
            headers: headers,
          });
        } else {
          response = await api.post(apiUrl, payload, { headers: headers });
        }
      }
      updateIsPlaygroundLoading(false);
      if (response.data.status === 200) {
        const projects = response.data.data.posts[0];
        setAllProjectsInfo(projects);
        if (terminator) {
          checkCompletedPipelines(projects, terminator);
        } else {
          return projects;
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsPlaygroundLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const runConfiguration = (datasource = null, stageId = null) => {
    if (errorNodes.length > 0) {
      updateAlertMessage(
        "There is an error in pipeline — Please save the configurations for the highlighted " +
          errorNodes[0].name +
          " node and run again"
      );
    } else if (isEmpty(pipeline)) {
      updateAlertMessage(
        "Pipeline is empty — Please do some configurations and then try to run again"
      );
    } else {
      updateAlertMessage(null);
      setToolbarPosition(null);
      console.log(pipeline);
      const terminator = {
        dSource: datasource,
        stageId: stageId,
      };
      setExecutionTerminator(terminator);
      if (pipeline.pipelineNodes?.length) {
        getProjects(terminator);
      } else {
        executePipeline(terminator, false, null);
      }
    }
  };

  const getConnectedDatasourceId = (node) => {
    const flow = reactFlowInstance.toObject();
    return getPreviousUnStagedNode(flow, node)?.id;
  };

  const handleSearchChange = (evt) => {
    const flowNodes = pipeline.clientMetaData.flow.nodes;
    const searchableNode = flowNodes.find(
      (nd) => nd.ariaLabel.toLowerCase() === evt.target.value.toLowerCase()
    );
    if (searchableNode) {
      focusOnNode(searchableNode);
    }
  };

  const onEdgeClick = (evt, edge) => {
    const delEdge = [edge];
    setDeletableEdge(delEdge);
  };

  const closeNodeDeleteDialog = () => {
    setShowNodeDeleteDialog(false);
  };

  const updateCheckboxList = (newCheckboxList) => {
    setCheckboxList(newCheckboxList);
  };

  const updateNodesOpacity = (updatedNodes) => {
    setNodes(updatedNodes);
  };

  const pasteNodes = async () => {
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    const newFlowNodes = structuredClone(nodes);
    copyNodesList.forEach((copiedNode) => {
      const newNode = structuredClone(copiedNode);
      newNode.position.y -= 60;
      newNode.id = getId(newNode.data.isStage);
      newNode.ariaLabel =
        copiedNode.ariaLabel.split("_")[0] + "_" + newNode.id.split("_")[1];
      if (copiedNode.className.includes("error")) {
        let newErrorNodes = structuredClone(errorNodes);
        newErrorNodes.push(newNode);
        updateErrorNodes(newErrorNodes);
      }
      newNodeConfigurations[newNode.id] = structuredClone(
        nodeConfigurations[copiedNode.id]
      );
      if (["fileUpload", "pySpark", "python"].includes(newNode.data.nodeType)) {
        newNodeConfigurations[newNode.id].fileName = newNode.ariaLabel;
      } else if (newNode.data.nodeType === "filter") {
        newNodeConfigurations[newNode.id].filterCfg[0].dataCfg.cfg.name =
          newNode.ariaLabel;
      } else {
        newNodeConfigurations[newNode.id].name = newNode.ariaLabel;
      }
      newNodeConfigurations[newNode.id].inputDs = "";
      newNodeConfigurations[newNode.id].sourceInfo = {};
      if (newNode.data.nodeType === "join") {
        newNodeConfigurations[newNode.id].leftData = "";
        newNodeConfigurations[newNode.id].rightData = [];
      } else if (newNode.data.nodeType === "split") {
        if (newNodeConfigurations[newNode.id].splitType === "split-excl") {
          if (!newNodeConfigurations[newNode.id].excluDs) {
            newNodeConfigurations[newNode.id].excluDs = "";
          }
        }
      }
      newFlowNodes.push(newNode);
    });
    updateNodeConfigurations(newNodeConfigurations);
    setNodes(newFlowNodes);
    setTimeout(() => {
      saveConfig(null, null, null, newNodeConfigurations);
    }, 1);
  };

  return (
    <div
      className="reactflow-wrapper"
      onClick={handleSingleClickOnPage}
      ref={reactFlowWrapper}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodesDelete={onNodesDelete}
        onConnect={onConnect}
        onInit={setReactFlowInstance}
        onDrop={onDrop}
        onDragOver={onDragOver}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        onNodeDoubleClick={onNodeDoubleClick}
        onEdgeUpdate={onEdgeUpdate}
        onEdgesDelete={onEdgeDelete}
        onNodeClick={onNodeClick}
        onEdgeClick={onEdgeClick}
        deleteKeyCode={["Backspace", "Delete"]}
        fitView
      >
        <Background color="#ccc" variant={BackgroundVariant.Dots} />
        <PlaygroundToolbar
          showDisabledButtons={showDisabledButtons}
          nodesBeforeCollapse={nodesBeforeCollapse}
          setNodesBeforeCollapse={setNodesBeforeCollapse}
          intervalIds={intervalIds}
          showStopBtn={showStopBtn}
          disableStopBtn={disableStopBtn}
          disableBackupAndRestoreBtn={disableBackupAndRestoreBtn}
          showResumeBtn={showResumeBtn}
          setShowStopBtn={setShowStopBtn}
          handleSearchChange={handleSearchChange}
          saveConfigurations={saveConfigurations}
          executePipeline={executePipeline}
          runConfiguration={runConfiguration}
          updateNodesOpacity={updateNodesOpacity}
          flowInfo={reactFlowInstance}
          pasteNodes={pasteNodes}
          handleMultiNodeDeletion={handleMultiNodeDeletion}
          collapseStages={collapseStages}
          nodes={nodes}
          edges={edges}
          updateNodes={updateNodes}
          updateEdges={updateEdges}
        />
        <Controls></Controls>
        <MiniMap
          nodeStrokeWidth={3}
          zoomable
          pannable
          nodeStrokeColor={(n) => {
            if (n.style?.background) return n.style.background;
            return "rgb(244, 247, 247)";
          }}
          nodeColor={(n) => {
            if (n.style?.background) return n.style.background;
            return "rgb(23, 80, 98)";
          }}
        />
      </ReactFlow>
      {activeModal}
      {showImportConfigDialog && (
        <ImportConfigDialog restoreUI={restoreUI}></ImportConfigDialog>
      )}
      {isConfigureVariablesDialogOpen && (
        <ConfigureVariablesDialog></ConfigureVariablesDialog>
      )}
      {showBackupDialog && <BackupDialog restoreUI={restoreUI}></BackupDialog>}
      {showRestoreDialog && (
        <RestoreDialog restoreUI={restoreUI}></RestoreDialog>
      )}
      {showProjectInfoDialog && <ProjectInfoDialog />}
      {toolbarPosition && (
        <Toolbar
          toolbarPosition={toolbarPosition}
          currentNode={deletableNode}
          onNodesDelete={onNodesDelete}
          addDuplicatedNode={addDuplicatedNode}
          runConfiguration={runConfiguration}
          getConnectedDatasourceId={getConnectedDatasourceId}
          collapseStages={collapseStages}
          nodes={nodes}
          edges={edges}
          nodesBeforeCollapse={nodesBeforeCollapse}
          setNodesBeforeCollapse={setNodesBeforeCollapse}
          updateNodes={updateNodes}
          updateEdges={updateEdges}
        ></Toolbar>
      )}
      {showNodeDeleteDialog && (
        <DeleteDialog
          deletableItem={deletableNode}
          deleteHandler={onNodesDelete}
          deletionType={"node-backspace"}
          closeNodeDeleteDialog={closeNodeDeleteDialog}
        />
      )}
      {showPipelineExecutionDialog && (
        <PipelineExecutionDialog
          executePipeline={executePipeline}
          checkboxList={checkboxList}
          updateCheckboxList={updateCheckboxList}
          executionTerminator={executionTerminator}
        />
      )}
      {showDatasourcesDialog && (
        <DatasourcesDialog loadPlayground={loadPlayground} />
      )}
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default DnDFlow;
