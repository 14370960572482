const buttonStyles = {
  color: "white",
  background: "#69c2d2",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "28px",
  textTransform: "none",
  ":hover": {
    background: "#40bcd2",
  },
  ":disabled": {
    background: "rgb(213 205 205 / 59%)",
  },
};
const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#69C2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#69C2D2",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#69C2D2",
  },
  "& .MuiInputBase-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D9D9D9",
  },
};
const chipTextFieldStyles = {
  "& .MuiOutlinedInput-root": {
    overflow: "hidden",
    maxHeight: "200px",
    "&.Mui-focused": {
      overflow: "scroll",
      maxHeight: "fit-content",
      marginBottom: "200px",
    },
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#69C2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#69C2D2",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#69C2D2",
  },
  "& .MuiInputBase-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D9D9D9",
  },
};
const labelStyles = {
  color: "#69C2D2",
  "&.Mui-focused": {
    color: "#69C2D2",
  },
};

export { buttonStyles, textFieldStyles, chipTextFieldStyles, labelStyles };
