import Stack from "@mui/material/Stack";
import "./dashboard.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "state";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import NewProjectDialog from "modals/newProjectDialog/newProjectDialog";
import { ReactComponent as DotsIcon } from "../../assets/icons/menuDots.svg";
import { ReactComponent as SortIcon } from "../../assets/icons/sortChevronIcon.svg";
import { useNavigate } from "react-router-dom";
import api from "../../apiInterceptor";
import DeleteDialog from "../../modals/deleteDialog/deleteDialog";
import {
  menuItemStyles,
  menuStyles,
  buttonStyles,
  actionButtonStyles,
  textFieldStyles,
} from "./dashboardStyle";
import { ReactComponent as AddIcon } from "../../assets/icons/plusIcon.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/blueColorCrossIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/dashboardEditIcon.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/checkIcon.svg";
import { isEmpty } from "lodash";
const tableData = require("../../assets/testdata/dashboard-test-data.json");
const listprojectsAPITestData = require("../../assets/apiTestData/listprojects-test-data.json");
const delprojectAPITestData = require("../../assets/apiTestData/success-test-data.json");
const updatedescrAPITestData = require("../../assets/apiTestData/success-test-data.json");

const Dashboard = () => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  const showCreatedByColumn =
    userAccessType === "view" || userAccessType === "admin";
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  let IS_LIST_PROJECTS_GET_TYPE = localStorage.getItem(
    "IS_LIST_PROJECTS_GET_TYPE"
  );
  IS_LIST_PROJECTS_GET_TYPE =
    IS_LIST_PROJECTS_GET_TYPE === "true" || IS_LIST_PROJECTS_GET_TYPE === true
      ? true
      : false;
  const navigate = useNavigate();
  const [creationType, setCreationType] = useState(null);
  const [allProjects, setAllProjects] = useState([]);
  const [sortableAllProjects, setSortableAllProjects] = useState([]);
  const [selectedProjectKey, setSelectedProjectKey] = useState(null);
  const [versionsToShow, setVersionsToShow] = useState({});
  const [featureGroupsToShow, setfeatureGroupsToShow] = useState({});
  const isDeleteDialogOpen = useSelector((state) => state.isDeleteDialogOpen);
  const scrollData = useSelector((state) => state.exploredProjectInfo);
  const [projectAnchorEl, setProjectAnchorEl] = useState(null);
  const [versionAnchorEl, setVersionAnchorEl] = useState(null);
  const [featureAnchorEl, setFeatureAnchorEl] = useState(null);
  const [currentProjectName, setCurrentProjectName] = useState(null);
  const [currentProjVersion, setCurrentProjVersion] = useState(null);
  const [currentFeatureGroup, setCurrentFeatureGroup] = useState(null);
  const [showDelete, setShowDelete] = useState(true);
  const [isDescending, setIsDescending] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableProjectKey, setEditableProjectKey] = useState(null);
  const [editableProjectVersion, setEditableProjectVersion] = useState(null);
  const [editableProjectFeatureGroup, setEditableProjectFeatureGroup] =
    useState(null);
  const [editedProjectName, setEditedProjectName] = useState("");
  const [editedFgDescription, setEditedFgDescription] = useState("");
  const [versionFgs, setVersionFgs] = useState(null);
  const openProjectMenu = Boolean(projectAnchorEl);
  const openVersionMenu = Boolean(versionAnchorEl);
  const openFeatureMenu = Boolean(featureAnchorEl);

  const dispatch = useDispatch();
  const {
    updateShowCreationDialog,
    updateDeleteDialogStatus,
    updateProjectKey,
    updateProjVersion,
    updateFeatureGroup,
    updateTable,
    updateModalForm,
    updatecurrentFormType,
    updateModalUI,
    updatePipeline,
    updateNodeConfigurations,
    updateVisitedStageNodes,
    updateErrorNodes,
    updateIsLoading,
    updateIsPlaygroundLoading,
    updateCurrentProjectStatus,
    updateAlertMessage,
    updateInfoAlertMessage,
    updateShowImportConfigDialog,
    updateIsPolling,
    updatePivotStatCfg,
    updateShowPipelineExecutionDialog,
    updateModellingNodesData,
    updateCurrentProjectName,
    updateCurrentFgDesc,
    updateEnableCopyMode,
    updateCopyNodesList,
    updateCollapsedDs,
    updateGlobalCollapse,
    updateExploredProject,
    updateShowProjectInfoDialog,
    updateAlertErrorDialogStatus,
  } = bindActionCreators(actionCreators, dispatch);
  const showCreationDialog = useSelector((state) => state.showCreationDialog);

  useEffect(() => {
    initializeStore();
    listProjects();
  }, []);

  useEffect(() => {
    if (!isEmpty(scrollData) && allProjects.length > 0) {
      scrollToTargetProject();
    }
  }, [allProjects]);

  const initializeStore = () => {
    updateCurrentProjectStatus(null);
    updateProjectKey(null);
    updateProjVersion(null);
    updateFeatureGroup(null);
    updateTable(null);
    updateModalForm(null);
    updatecurrentFormType("fileUpload");
    updateModalUI(null);
    updatePipeline({});
    updateNodeConfigurations(null);
    updateVisitedStageNodes([]);
    updateErrorNodes([]);
    updateAlertMessage(null);
    updateInfoAlertMessage(null);
    updateShowImportConfigDialog(false);
    updateIsPolling(false);
    updatePivotStatCfg([]);
    updateIsPlaygroundLoading(false);
    updateShowPipelineExecutionDialog(false);
    updateModellingNodesData({});
    updateCurrentProjectName(null);
    updateCurrentFgDesc(null);
    updateEnableCopyMode(false);
    updateCopyNodesList([]);
    updateCollapsedDs([]);
    updateGlobalCollapse(false);
    updateShowProjectInfoDialog(false);
    updateAlertErrorDialogStatus(false);
    resetEditDetails();
  };

  const listProjects = async (projectDeleted = false) => {
    const apiUrl = BASE_API_URL + "listprojects";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {};
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: listprojectsAPITestData,
        };
      } else {
        if (IS_LIST_PROJECTS_GET_TYPE) {
          response = await api.get(apiUrl, {
            headers: headers,
          });
        } else {
          response = await api.post(apiUrl, payload, { headers: headers });
        }
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const projects = response.data.data.posts[0];
        // const projects = tableData;
        const sortedProjects = getSortedProjects(
          projects,
          "creation_ts",
          projectDeleted
        );
        setIsDescending(!(isDescending || projectDeleted));
        setAllProjects(sortedProjects);
        setSortableAllProjects(sortedProjects);
        initializeVersionsToShow(sortedProjects);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
      console.log(error);
    }
  };

  const scrollToTargetProject = () => {
    const combinedRestoreKey =
      scrollData.projectKey +
      "_" +
      scrollData.projVersion +
      "_" +
      scrollData.projFg;
    showMoreVersions(scrollData.projectKey, true);
    setTimeout(() => {
      document
        .getElementById(combinedRestoreKey)
        ?.scrollIntoView({ block: "center" });
      updateExploredProject({});
    }, 10);
  };

  const getFeatureGroups = (projects, pkey, versionId, project = null) => {
    let targetProj = null;
    if (project) {
      targetProj = project;
    } else {
      targetProj = projects.find((proj) => proj.projectKey === pkey);
    }
    const targetVersion = targetProj?.versionInfo.find(
      (ver) => ver.vname === versionId
    );
    return targetVersion.fgInfo;
  };

  const intializeFeatureGroupsToShow = (
    versions,
    projects,
    restoreScrollPosition
  ) => {
    let featureGroups = {};
    Object.keys(versions).forEach((pkey) => {
      featureGroups[pkey] = {};
      versions[pkey].forEach((version) => {
        featureGroups[pkey][version.vname] = [];
        let versionFeatureGroups = getFeatureGroups(
          projects,
          pkey,
          version.vname
        );
        for (let i = 0; i < 2; i++) {
          if (i >= versionFeatureGroups.length) {
            break;
          }
          featureGroups[pkey][version.vname].push(versionFeatureGroups[i]);
        }
      });
    });
    setfeatureGroupsToShow(featureGroups);
    if (restoreScrollPosition)
      showMoreFeatureGroups(
        scrollData.projectKey,
        scrollData.projVersion,
        featureGroups
      );
  };

  const initializeVersionsToShow = (projects) => {
    let versions = {};
    projects.forEach((project) => {
      versions[project.projectKey] = [];
      let versionInfo = project.versionInfo;
      for (let i = 0; i < 2; i++) {
        if (i >= versionInfo.length) {
          break;
        }
        versions[project.projectKey].push(versionInfo[i]);
      }
    });
    setVersionsToShow(versions);
    intializeFeatureGroupsToShow(versions, projects);
  };

  const getProjectObject = (pKey, projects = allProjects) => {
    return projects?.find((obj) => obj.projectKey === pKey);
  };

  const handleMenuClick = (
    event,
    projectKey,
    menuType,
    projName = null,
    projVersion = null,
    featureGroup = null
  ) => {
    handleAccordianCollapsing(event);
    setSelectedProjectKey(projectKey);
    setCurrentProjectName(projName);
    setCurrentProjVersion(projVersion);
    setCurrentFeatureGroup(featureGroup);
    if (menuType === "project") {
      setProjectAnchorEl(event.currentTarget);
    } else if (menuType === "version") {
      setVersionAnchorEl(event.currentTarget);
    } else {
      if (featureGroup === "base") {
        setShowDelete(false);
      } else {
        setShowDelete(true);
      }
      setFeatureAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setProjectAnchorEl(null);
    setVersionAnchorEl(null);
    setFeatureAnchorEl(null);
    setCurrentProjectName(null);
  };

  const handleShowCreationDialog = (type) => {
    setCreationType(type);
    updateShowCreationDialog(true);
    if (type === "feature-group") {
      let fgNames = [];
      const fgs = getFeatureGroups(
        allProjects,
        selectedProjectKey,
        currentProjVersion
      );
      for (let fg of fgs) {
        fgNames.push(fg.projFg);
      }
      setVersionFgs(fgNames);
    }
  };

  const handleProjNameAndFgDesc = (
    projectKey,
    projVersion,
    projFg,
    project,
    pname,
    fgDesc
  ) => {
    if (pname) {
      updateCurrentProjectName(pname);
    } else {
      updateCurrentProjectName(project.pname);
    }
    if (fgDesc) {
      updateCurrentFgDesc(fgDesc);
    } else {
      const featureGroups = getFeatureGroups(
        null,
        projectKey,
        projVersion,
        project
      );
      const currentFg = featureGroups.find((fg) => fg.projFg === projFg);
      updateCurrentFgDesc(currentFg.description);
    }
  };

  const handleExplore = (
    projectKey,
    projVersion,
    projFg,
    pname = null,
    fgDesc = null
  ) => {
    const project = getProjectObject(projectKey);
    if (
      project &&
      project.versionInfo[projVersion] &&
      project.versionInfo[projVersion][projFg]
    ) {
      updateCurrentProjectStatus(
        project.versionInfo[projVersion][projFg].state
      );
    } else {
      updateCurrentProjectStatus("Created");
    }
    handleProjNameAndFgDesc(
      projectKey,
      projVersion,
      projFg,
      project,
      pname,
      fgDesc
    );
    navigate(`/playground/${projectKey}/${projVersion}/${projFg}`);
  };

  const handleJobScheduler = (projectKey, projVersion, projFg) => {
    const project = getProjectObject(projectKey);
    if (
      project &&
      project.versionInfo[projVersion] &&
      project.versionInfo[projVersion][projFg]
    ) {
      updateCurrentProjectStatus(
        project.versionInfo[projVersion][projFg].state
      );
      updateCurrentProjectName(project.pname);
      const featureGroups = getFeatureGroups(
        null,
        projectKey,
        projVersion,
        project
      );
      const currentFg = featureGroups.find((fg) => fg.projFg === projFg);
      updateCurrentFgDesc(currentFg.description);
    }
    navigate(`/job-scheduler/${projectKey}/${projVersion}/${projFg}`);
  };

  const showDeleteConfirmationDialog = () => {
    updateDeleteDialogStatus(true);
  };

  const handleProjectOrVersionDeletion = async (
    projectKey,
    projVersion = null
  ) => {
    const apiUrl = BASE_API_URL + "delproject";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: projectKey,
      projVersion: projVersion,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: delprojectAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      handleMenuClose();
      if (response.data.status === 200) {
        listProjects(true);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleFeatureGroupDeletion = async (
    projectKey,
    projVersion,
    projFg
  ) => {
    const apiUrl = BASE_API_URL + "delfg";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: projectKey,
      projVersion: projVersion,
      projFg: projFg,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: delprojectAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      handleMenuClose();
      if (response.data.status === 200) {
        listProjects(true);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const exportProjectOrVersion = async () => {
    try {
      const apiUrl = BASE_API_URL + "dwnld-data";
      const timestamp = new Date().getTime();
      const exportUrl =
        apiUrl +
        `?projectKey=${selectedProjectKey}&docType=project${
          currentProjVersion ? "&projVersion=" + currentProjVersion : ""
        }&timestamp=${timestamp}`;
      window.open(exportUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const showMoreVersions = (pkey, restoreScrollPosition = false) => {
    const project = getProjectObject(pkey);
    if (project) {
      const newVersionsToShow = structuredClone(versionsToShow);
      newVersionsToShow[pkey] = project.versionInfo;
      setVersionsToShow(newVersionsToShow);
      intializeFeatureGroupsToShow(
        newVersionsToShow,
        allProjects,
        restoreScrollPosition
      );
    }
  };

  const showLessVersions = (pkey) => {
    const project = getProjectObject(pkey);
    const newVersionsToShow = structuredClone(versionsToShow);
    newVersionsToShow[pkey] = [];
    let versionInfo = project.versionInfo;
    for (let i = 0; i < 2; i++) {
      if (i >= versionInfo.length) {
        break;
      }
      newVersionsToShow[pkey].push(versionInfo[i]);
    }
    setVersionsToShow(newVersionsToShow);
  };

  const getVersion = (project, versionId) => {
    return project?.versionInfo?.find((ver) => ver.vname === versionId);
  };

  const showMoreFeatureGroups = (
    pkey,
    versionId,
    featureGroupsFromScrollRestore = null
  ) => {
    const project = getProjectObject(pkey);
    if (project) {
      let newFeatureGroupsToShow = structuredClone(featureGroupsToShow);
      if (featureGroupsFromScrollRestore)
        newFeatureGroupsToShow = featureGroupsFromScrollRestore;
      newFeatureGroupsToShow[pkey][versionId] = getVersion(
        project,
        versionId
      ).fgInfo;
      setfeatureGroupsToShow(newFeatureGroupsToShow);
    }
  };

  const showLessFeatureGroups = (pkey, versionId) => {
    const project = getProjectObject(pkey);
    const newFeatureGroupsToShow = structuredClone(featureGroupsToShow);
    newFeatureGroupsToShow[pkey][versionId] = [];
    let versionFeatureGroups = getFeatureGroups(null, pkey, versionId, project);
    for (let i = 0; i < 2; i++) {
      if (i >= versionFeatureGroups.length) {
        break;
      }
      newFeatureGroupsToShow[pkey][versionId].push(versionFeatureGroups[i]);
    }
    setfeatureGroupsToShow(newFeatureGroupsToShow);
  };

  const getAllFeaturesInCurrentVersion = (project, version) => {
    const currentVersion = project.versionInfo.find(
      (ver) => ver.vname === version.vname
    );
    const allFeatureGroupsInCurrentVersion = currentVersion.fgInfo;
    return allFeatureGroupsInCurrentVersion.length;
  };

  const showMoreFeaturesBtn = (project, version) => {
    if (
      featureGroupsToShow[project.projectKey] &&
      featureGroupsToShow[project.projectKey][version.vname]
    ) {
      if (
        featureGroupsToShow[project.projectKey][version.vname].length <
        getAllFeaturesInCurrentVersion(project, version)
      ) {
        return true;
      }
    }
    return false;
  };

  const showLessFeaturesBtn = (project, version) => {
    if (
      featureGroupsToShow[project.projectKey] &&
      featureGroupsToShow[project.projectKey][version.vname]
    ) {
      const featuresAvailableToShow =
        featureGroupsToShow[project.projectKey][version.vname].length;
      if (
        featuresAvailableToShow ===
          getAllFeaturesInCurrentVersion(project, version) &&
        featuresAvailableToShow > 2
      ) {
        return true;
      }
    }
    return false;
  };

  const sortFeatureGroup = (featureGroup, sortKey, projectDeleted = false) => {
    return featureGroup.fgInfo.sort((currentFg, nextFg) => {
      if (sortKey === "creation_ts") {
        return new Date(currentFg[sortKey]).getTime() >
          new Date(nextFg[sortKey]).getTime()
          ? isDescending || projectDeleted
            ? -1
            : 1
          : isDescending || projectDeleted
          ? 1
          : -1;
      } else {
        return currentFg[sortKey] > nextFg[sortKey]
          ? isDescending || projectDeleted
            ? -1
            : 1
          : isDescending || projectDeleted
          ? 1
          : -1;
      }
    });
  };

  const sortVersion = (version, sortKey, projectDeleted = false) => {
    return version.versionInfo.sort((currentVersion, nextVersion) => {
      let direction = 0;
      currentVersion.fgInfo = sortFeatureGroup(
        currentVersion,
        sortKey,
        projectDeleted
      );
      nextVersion.fgInfo = sortFeatureGroup(
        nextVersion,
        sortKey,
        projectDeleted
      );
      if (sortKey === "creation_ts") {
        direction =
          new Date(currentVersion.fgInfo[0][sortKey]).getTime() >
          new Date(nextVersion.fgInfo[0][sortKey]).getTime()
            ? -1
            : 1;
      } else {
        direction =
          currentVersion.fgInfo[0][sortKey] > nextVersion.fgInfo[0][sortKey]
            ? -1
            : 1;
      }
      return isDescending || projectDeleted ? direction : direction * -1;
    });
  };

  const getSortedProjects = (
    sortableProjectData,
    sortKey,
    projectDeleted = false
  ) => {
    return sortableProjectData.sort((currentProject, nextProject) => {
      let direction = 0;
      if (
        sortKey === "pname" ||
        sortKey === "projectkey" ||
        sortKey === "uname"
      ) {
        direction = currentProject[sortKey] > nextProject[sortKey] ? -1 : 1;
      } else {
        currentProject.versionInfo = sortVersion(
          currentProject,
          sortKey,
          projectDeleted
        );
        nextProject.versionInfo = sortVersion(
          nextProject,
          sortKey,
          projectDeleted
        );
        if (sortKey === "creation_ts") {
          direction =
            new Date(
              currentProject.versionInfo[0].fgInfo[0][sortKey]
            ).getTime() >
            new Date(nextProject.versionInfo[0].fgInfo[0][sortKey]).getTime()
              ? -1
              : 1;
        } else {
          direction =
            currentProject.versionInfo[0].fgInfo[0][sortKey] >
            nextProject.versionInfo[0].fgInfo[0][sortKey]
              ? -1
              : 1;
        }
      }
      return isDescending || projectDeleted ? direction : direction * -1;
    });
  };

  const sortProjects = (sortKey) => {
    const sortableProjectData = structuredClone(sortableAllProjects);
    const sortedData = getSortedProjects(sortableProjectData, sortKey);
    setAllProjects(sortedData);
    initializeVersionsToShow(sortedData);
  };

  const sortTable = (sortingParameter) => {
    sortProjects(sortingParameter);
    setIsDescending(!isDescending);
  };

  const handleAccordianCollapsing = (event) => {
    event.stopPropagation();
  };

  const handleEditNameAndDescChange = (event, editType) => {
    const value = event.target.value;
    if (value || value === "") {
      if (editType === "pname") {
        setEditedProjectName(value);
      } else {
        setEditedFgDescription(value);
      }
    }
  };

  const handleEditClick = (
    event,
    projectKey,
    projName = "",
    projVersion = null,
    featureGroup = null,
    fgDescription = ""
  ) => {
    handleAccordianCollapsing(event);
    setIsEditMode(true);
    setEditableProjectKey(projectKey);
    setEditedProjectName(projName);
    setEditableProjectVersion(projVersion);
    setEditableProjectFeatureGroup(featureGroup);
    setEditedFgDescription(fgDescription);
  };

  const handleSaveEditClick = async (event) => {
    handleAccordianCollapsing(event);
    const apiUrl = BASE_API_URL + "updatedescr";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: editableProjectKey,
      projVersion: editableProjectVersion,
      projFg: editableProjectFeatureGroup,
      pname: editedProjectName,
      description: editedFgDescription,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: updatedescrAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      handleMenuClose();
      resetEditDetails();
      if (response.data.status === 200) {
        listProjects(true);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleCancelEditClick = (event) => {
    handleAccordianCollapsing(event);
    resetEditDetails();
  };

  const resetEditDetails = () => {
    setIsEditMode(false);
    setEditableProjectKey(null);
    setEditedProjectName("");
    setEditableProjectVersion(null);
    setEditableProjectFeatureGroup(null);
    setEditedFgDescription("");
  };

  return (
    <div className="dashboard-container">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height="70px"
      >
        <Box className="dashboard-header">Dashboard</Box>
        <Button
          variant="contained"
          onClick={() => handleShowCreationDialog("project")}
          sx={buttonStyles}
          disabled={userAccessRestricted}
        >
          <AddIcon className="add-icon" />
          New Project
        </Button>
      </Stack>

      <Stack className="dashboard-table">
        <Stack direction="row" className="table-header">
          <Stack
            sx={{ width: "20%" }}
            className="header-item"
            direction="row"
            gap="10px"
            alignItems="center"
            onClick={() => sortTable("pname")}
          >
            <Box>Project Name</Box>
            <SortIcon className="sort-icon"></SortIcon>
          </Stack>
          <Stack
            sx={{ width: showCreatedByColumn ? "22%" : "20%" }}
            className="header-item"
            direction="row"
            gap="10px"
            alignItems="center"
            onClick={() => sortTable("description")}
          >
            <Box>Description</Box>
            <SortIcon className="sort-icon"></SortIcon>
          </Stack>
          <Stack
            sx={{ width: showCreatedByColumn ? "11%" : "12.5%" }}
            className="header-item"
            direction="row"
            gap="10px"
            alignItems="center"
            onClick={() => sortTable("pkey")}
          >
            <Box>Project Id</Box>
            <SortIcon className="sort-icon"></SortIcon>
          </Stack>
          <Stack
            sx={{ width: showCreatedByColumn ? "11%" : "13.5%" }}
            className="header-item"
            direction="row"
            gap="10px"
            alignItems="center"
            onClick={() => sortTable("creation_ts")}
          >
            <Box>Created On</Box>
            <SortIcon className="sort-icon"></SortIcon>
          </Stack>
          {showCreatedByColumn ? (
            <Stack
              sx={{ width: "12%" }}
              className="header-item"
              direction="row"
              gap="10px"
              alignItems="center"
              onClick={() => sortTable("uname")}
            >
              <Box>Created by</Box>
              <SortIcon className="sort-icon"></SortIcon>
            </Stack>
          ) : (
            <></>
          )}
          <Stack
            sx={{ width: showCreatedByColumn ? "10%" : "13%" }}
            className="header-item"
            direction="row"
            gap="10px"
            alignItems="center"
            onClick={() => sortTable("status")}
          >
            <Box>Status</Box>
            <SortIcon className="sort-icon"></SortIcon>
          </Stack>
          <Stack sx={{ width: "15%" }} className="header-item">
            <Box>Actions</Box>
          </Stack>
        </Stack>
        {allProjects.map((project, index) => (
          <Accordion key={index} className="project-card" defaultExpanded>
            <AccordionSummary
              expandIcon={<ChevronDown className="icon" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="accordion-header"
            >
              <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
                <Box
                  sx={{ width: showCreatedByColumn ? "88%" : "86.5%" }}
                  className="highlighted-name"
                >
                  {isEditMode &&
                  editableProjectKey === project.projectKey &&
                  !editableProjectFeatureGroup &&
                  !editableProjectVersion ? (
                    <FormControl
                      sx={{ width: "20%" }}
                      key={project.projectKey + "input" + index}
                      size="small"
                    >
                      <TextField
                        type="text"
                        name="pname"
                        size="small"
                        placeholder="Project Name"
                        value={editedProjectName}
                        onChange={(e) =>
                          handleEditNameAndDescChange(e, "pname")
                        }
                        onClick={handleAccordianCollapsing}
                        variant="outlined"
                        sx={textFieldStyles}
                        autoComplete="off"
                      />
                    </FormControl>
                  ) : (
                    project.pname
                  )}
                </Box>
                <Box sx={{ width: showCreatedByColumn ? "12%" : "13.5%" }}>
                  <Box
                    onClick={handleAccordianCollapsing}
                    className="actions-container"
                    sx={{ width: "fit-content" }}
                  >
                    {!(
                      isEditMode &&
                      editableProjectKey === project.projectKey &&
                      !editableProjectFeatureGroup &&
                      !editableProjectVersion
                    ) ? (
                      <Tooltip title="Edit project name" placement="top">
                        <Button
                          sx={actionButtonStyles}
                          disabled={isEditMode || userAccessRestricted}
                          onClick={(evt) =>
                            handleEditClick(
                              evt,
                              project.projectKey,
                              project.pname
                            )
                          }
                        >
                          <EditIcon className="edit-name-icon"></EditIcon>
                        </Button>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                    {isEditMode &&
                    editableProjectKey === project.projectKey &&
                    !editableProjectFeatureGroup &&
                    !editableProjectVersion ? (
                      <Box className="close-save-buttons-container">
                        <Tooltip
                          title="Stop editing project name"
                          placement="top"
                        >
                          <Button
                            sx={actionButtonStyles}
                            onClick={handleCancelEditClick}
                          >
                            <CrossIcon className="cancel-edit-icon"></CrossIcon>
                          </Button>
                        </Tooltip>
                        <Tooltip title="Save project name" placement="top">
                          <Button
                            sx={actionButtonStyles}
                            disabled={!editedProjectName}
                            onClick={(evt) => handleSaveEditClick(evt)}
                          >
                            <CheckIcon className="save-edit-name-icon"></CheckIcon>
                          </Button>
                        </Tooltip>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Button
                      sx={actionButtonStyles}
                      id="project-button"
                      aria-controls={
                        openProjectMenu ? "project-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openProjectMenu ? "true" : undefined}
                      onClick={(evt) =>
                        handleMenuClick(
                          evt,
                          project.projectKey,
                          "project",
                          project.pname
                        )
                      }
                    >
                      <DotsIcon className="menu-icon"></DotsIcon>
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </AccordionSummary>
            <Stack direction="column">
              <Stack className="version-card" sx={{ width: 1 }}>
                {versionsToShow[project.projectKey] &&
                  versionsToShow[project.projectKey].map((version) => (
                    <Stack
                      key={version.vname}
                      direction="row"
                      sx={{ width: 1 }}
                      className="version-box"
                    >
                      <Stack className="ladder-box">
                        <Box className="ladder-item-1"></Box>
                        <Box className="ladder-item-2"></Box>
                      </Stack>
                      <Stack
                        className="version-info"
                        direction="column"
                        sx={{ width: 1 }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          className="version-info-header"
                        >
                          <Box
                            sx={{
                              width: showCreatedByColumn ? "87.8%" : "86.2%",
                            }}
                            className="highlighted-name version-name"
                          >
                            {version.vname}
                          </Box>
                          <Box
                            sx={{
                              width: showCreatedByColumn ? "12.2%" : "13.8%",
                            }}
                          >
                            <Button
                              sx={actionButtonStyles}
                              id="version-button"
                              aria-controls={
                                openVersionMenu ? "version-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                openVersionMenu ? "true" : undefined
                              }
                              onClick={(evt) =>
                                handleMenuClick(
                                  evt,
                                  project.projectKey,
                                  "version",
                                  project.pname,
                                  version.vname
                                )
                              }
                            >
                              <DotsIcon className="menu-icon"></DotsIcon>
                            </Button>
                          </Box>
                        </Stack>
                        <Stack direction="column">
                          <Stack className="feature-card" sx={{ width: 1 }}>
                            {featureGroupsToShow[project.projectKey] &&
                              featureGroupsToShow[project.projectKey][
                                version.vname
                              ] &&
                              featureGroupsToShow[project.projectKey][
                                version.vname
                              ].map((featureGroup) => (
                                <Stack
                                  key={featureGroup.projFg}
                                  direction="row"
                                  sx={{ width: 1 }}
                                  className="feature-box"
                                >
                                  <Stack className="ladder-box">
                                    <Box className="ladder-item-1"></Box>
                                    <Box className="ladder-item-2"></Box>
                                  </Stack>
                                  <Stack
                                    className="feature-info"
                                    direction="row"
                                    alignItems="center"
                                    sx={{ width: 1 }}
                                    id={
                                      project.projectKey +
                                      "_" +
                                      version.vname +
                                      "_" +
                                      featureGroup.projFg
                                    }
                                  >
                                    <Box
                                      sx={{
                                        width: showCreatedByColumn
                                          ? "16%"
                                          : "17%",
                                      }}
                                      className="feature-group-name"
                                      onClick={() =>
                                        handleExplore(
                                          project.projectKey,
                                          version.vname,
                                          featureGroup.projFg
                                        )
                                      }
                                    >
                                      {featureGroup.projFg}
                                    </Box>
                                    <Box
                                      sx={{
                                        width: showCreatedByColumn
                                          ? "22%"
                                          : "23%",
                                      }}
                                    >
                                      {isEditMode &&
                                      editableProjectKey ===
                                        project.projectKey &&
                                      editableProjectVersion ===
                                        version.vname &&
                                      editableProjectFeatureGroup ===
                                        featureGroup.projFg ? (
                                        <FormControl
                                          sx={{ width: "70%" }}
                                          key={
                                            project.projectKey +
                                            version.vname +
                                            featureGroup.projFg +
                                            "input" +
                                            index
                                          }
                                          size="small"
                                        >
                                          <TextField
                                            type="text"
                                            name="description"
                                            size="small"
                                            placeholder="Description"
                                            value={editedFgDescription}
                                            onChange={(e) =>
                                              handleEditNameAndDescChange(
                                                e,
                                                "fgDesc"
                                              )
                                            }
                                            variant="outlined"
                                            sx={textFieldStyles}
                                            autoComplete="off"
                                          />
                                        </FormControl>
                                      ) : (
                                        <Box className="feature-group-description">
                                          <Tooltip
                                            title={featureGroup.description}
                                          >
                                            {featureGroup.description}
                                          </Tooltip>
                                        </Box>
                                      )}
                                    </Box>
                                    <Box
                                      sx={{
                                        width: showCreatedByColumn
                                          ? "14%"
                                          : "16%",
                                      }}
                                    >
                                      {project.projectKey}
                                    </Box>
                                    <Box
                                      sx={{
                                        width: showCreatedByColumn
                                          ? "13%"
                                          : "16%",
                                      }}
                                    >
                                      {featureGroup.creation_ts
                                        ? featureGroup.creation_ts.split(" ")[0]
                                        : ""}
                                    </Box>
                                    {showCreatedByColumn ? (
                                      <Box sx={{ width: "12%" }}>
                                        {featureGroup.uname
                                          ? featureGroup.uname
                                          : ""}
                                      </Box>
                                    ) : (
                                      <></>
                                    )}
                                    <Box
                                      sx={{
                                        width: showCreatedByColumn
                                          ? "11.7%"
                                          : "15%",
                                      }}
                                    >
                                      {featureGroup.state}
                                    </Box>
                                    <Box
                                      sx={{
                                        width: showCreatedByColumn
                                          ? "11.3%"
                                          : "13%",
                                      }}
                                    >
                                      <Box
                                        className="actions-container"
                                        sx={{ width: "fit-content" }}
                                      >
                                        {!(
                                          isEditMode &&
                                          editableProjectKey ===
                                            project.projectKey &&
                                          editableProjectVersion ===
                                            version.vname &&
                                          editableProjectFeatureGroup ===
                                            featureGroup.projFg
                                        ) ? (
                                          <Tooltip
                                            title="Edit description"
                                            placement="top"
                                          >
                                            <Button
                                              sx={actionButtonStyles}
                                              disabled={
                                                isEditMode ||
                                                userAccessRestricted
                                              }
                                              onClick={(evt) =>
                                                handleEditClick(
                                                  evt,
                                                  project.projectKey,
                                                  "",
                                                  version.vname,
                                                  featureGroup.projFg,
                                                  featureGroup.description
                                                )
                                              }
                                            >
                                              <EditIcon className="edit-name-icon"></EditIcon>
                                            </Button>
                                          </Tooltip>
                                        ) : (
                                          <></>
                                        )}
                                        {isEditMode &&
                                        editableProjectKey ===
                                          project.projectKey &&
                                        editableProjectVersion ===
                                          version.vname &&
                                        editableProjectFeatureGroup ===
                                          featureGroup.projFg ? (
                                          <Box className="close-save-buttons-container">
                                            <Tooltip
                                              title="Stop editing description"
                                              placement="top"
                                            >
                                              <Button
                                                sx={actionButtonStyles}
                                                onClick={handleCancelEditClick}
                                              >
                                                <CrossIcon className="cancel-edit-icon"></CrossIcon>
                                              </Button>
                                            </Tooltip>
                                            <Tooltip
                                              title="Save description"
                                              placement="top"
                                            >
                                              <Button
                                                sx={actionButtonStyles}
                                                disabled={!editedFgDescription}
                                                onClick={(evt) =>
                                                  handleSaveEditClick(evt)
                                                }
                                              >
                                                <CheckIcon className="save-edit-name-icon"></CheckIcon>
                                              </Button>
                                            </Tooltip>
                                          </Box>
                                        ) : (
                                          <></>
                                        )}
                                        <Button
                                          sx={actionButtonStyles}
                                          id="feature-button"
                                          aria-controls={
                                            openFeatureMenu
                                              ? "feature-group-menu"
                                              : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                            openFeatureMenu ? "true" : undefined
                                          }
                                          onClick={(evt) =>
                                            handleMenuClick(
                                              evt,
                                              project.projectKey,
                                              "feature",
                                              project.pname,
                                              version.vname,
                                              featureGroup.projFg
                                            )
                                          }
                                        >
                                          <DotsIcon className="menu-icon"></DotsIcon>
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>
                          {showMoreFeaturesBtn(project, version) ? (
                            <Stack
                              onClick={() =>
                                showMoreFeatureGroups(
                                  project.projectKey,
                                  version.vname
                                )
                              }
                              className="expand-btn"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {`Show +${
                                getAllFeaturesInCurrentVersion(
                                  project,
                                  version
                                ) -
                                featureGroupsToShow[project.projectKey][
                                  version.vname
                                ].length
                              } more feature groups`}
                            </Stack>
                          ) : showLessFeaturesBtn(project, version) ? (
                            <Stack
                              onClick={() =>
                                showLessFeatureGroups(
                                  project.projectKey,
                                  version.vname
                                )
                              }
                              className="expand-btn"
                              justifyContent="center"
                              alignItems="center"
                            >
                              Show less feature groups
                            </Stack>
                          ) : (
                            <></>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
              </Stack>
              {versionsToShow[project.projectKey] &&
              versionsToShow[project.projectKey].length <
                Object.keys(project.versionInfo).length ? (
                <Stack
                  onClick={() => showMoreVersions(project.projectKey)}
                  className="expand-btn"
                  justifyContent="center"
                  alignItems="center"
                >
                  {`Show +${
                    Object.keys(project.versionInfo).length -
                    versionsToShow[project.projectKey].length
                  } more versions`}
                </Stack>
              ) : versionsToShow[project.projectKey] &&
                versionsToShow[project.projectKey].length ===
                  Object.keys(project.versionInfo).length &&
                versionsToShow[project.projectKey].length > 2 ? (
                <Stack
                  onClick={() => showLessVersions(project.projectKey)}
                  className="expand-btn"
                  justifyContent="center"
                  alignItems="center"
                >
                  Show less versions
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </Accordion>
        ))}
        <Menu
          id="feature-group-menu"
          anchorEl={featureAnchorEl}
          open={openFeatureMenu}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "feature-button",
          }}
          sx={menuStyles}
        >
          <MenuItem
            sx={menuItemStyles}
            onClick={(e) =>
              handleExplore(
                selectedProjectKey,
                currentProjVersion,
                currentFeatureGroup
              )
            }
          >
            Explore
          </MenuItem>
          <MenuItem
            sx={menuItemStyles}
            onClick={(e) =>
              handleJobScheduler(
                selectedProjectKey,
                currentProjVersion,
                currentFeatureGroup
              )
            }
          >
            Job Scheduler
          </MenuItem>
          <MenuItem
            sx={menuItemStyles}
            disabled={userAccessRestricted}
            onClick={(e) => handleShowCreationDialog("feature-group")}
          >
            Manage
          </MenuItem>
          {/* <MenuItem onClick={handleMenuClose}>Edit</MenuItem> */}
          {showDelete && (
            <MenuItem
              sx={menuItemStyles}
              disabled={userAccessRestricted}
              onClick={(e) => showDeleteConfirmationDialog()}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
        <Menu
          id="version-menu"
          anchorEl={versionAnchorEl}
          open={openVersionMenu}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "version-button",
          }}
          sx={menuStyles}
        >
          <MenuItem
            sx={menuItemStyles}
            disabled={userAccessRestricted}
            onClick={() => handleShowCreationDialog("feature-group")}
          >
            Create feature group
          </MenuItem>
          <MenuItem
            sx={menuItemStyles}
            disabled={userAccessRestricted}
            onClick={() => exportProjectOrVersion()}
          >
            Export version
          </MenuItem>
          {/* <MenuItem onClick={handleMenuClose}>Edit</MenuItem> */}
          <MenuItem
            sx={menuItemStyles}
            disabled={userAccessRestricted}
            onClick={(e) => showDeleteConfirmationDialog()}
          >
            Delete version
          </MenuItem>
        </Menu>
        <Menu
          id="project-menu"
          anchorEl={projectAnchorEl}
          open={openProjectMenu}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "project-button",
          }}
          sx={menuStyles}
        >
          <MenuItem
            sx={menuItemStyles}
            disabled={userAccessRestricted}
            onClick={() => handleShowCreationDialog("version")}
          >
            Create version
          </MenuItem>
          <MenuItem
            sx={menuItemStyles}
            disabled={userAccessRestricted}
            onClick={() => exportProjectOrVersion()}
          >
            Export project
          </MenuItem>
          {/* <MenuItem onClick={handleMenuClose}>Edit</MenuItem> */}
          {/* <MenuItem
            sx={menuItemStyles}
            onClick={(e) => showDeleteConfirmationDialog()}
          >
            Delete project
          </MenuItem> */}
        </Menu>
      </Stack>
      {showCreationDialog ? (
        <NewProjectDialog
          creationType={creationType}
          handleExplore={handleExplore}
          projName={currentProjectName}
          projVersion={currentProjVersion}
          projKey={selectedProjectKey}
          projFg={currentFeatureGroup}
          versionFgs={versionFgs}
          handleMenuClose={handleMenuClose}
          listProjects={listProjects}
        ></NewProjectDialog>
      ) : (
        <></>
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          deletableItem={selectedProjectKey}
          deleteHandler={
            currentFeatureGroup
              ? handleFeatureGroupDeletion
              : handleProjectOrVersionDeletion
          }
          deletionType={"project"}
          projectName={
            getProjectObject(selectedProjectKey)
              ? getProjectObject(selectedProjectKey).pname
              : ""
          }
          projVersion={currentProjVersion}
          projFg={currentFeatureGroup}
          handleMenuClose={handleMenuClose}
        />
      )}
    </div>
  );
};
export default Dashboard;
