import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./apiDialog.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionMark.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import {
  buttonStyles,
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  textFieldStyles,
} from "./apiDialogStyles";
import api from "../../apiInterceptor";
import CustomTooltip from "components/Tooltip/CustomTooltip";
const uploadDataAPITestData = require("../../assets/apiTestData/success-test-data.json");

const ApiDialog = (modalProps) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const modalType = "apiDialog";
  const formData = {
    name: "",
    sourceType: "api",
    subType: "incoming",
    loginRequired: "no",
    loginAPI: {
      url: "",
      method: "",
      params: [],
      body: "",
      authentication: "",
      bodyParams: [],
    },
    dataAPI: {
      url: "",
      method: "",
      params: [],
      respKey: "",
      dataKey: "",
      body: "",
      bodyParams: [],
    },
  };
  const selectedNode = modalProps.selectedNode;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [loginApiParamsArray, setLoginApiParamsArray] = useState([]);
  const [apiParamsArray, setApiParamsArray] = useState([]);
  const [bodyParamsArray, setBodyParamsArray] = useState([]);
  const [loginBodyParamsArray, setLoginBodyParamsArray] = useState([]);
  const [isBodyParam, setIsBodyParam] = useState("no");
  const [isLoginBodyParam, setIsLoginBodyParam] = useState("no");
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const modalForm = useSelector((state) => state.modalForm);
  const pipeline = useSelector((state) => state.pipeline);
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const detailedMsgTooltipData = useSelector(
    (state) => state.detailedMsgTooltipData
  );
  const dispatch = useDispatch();
  const {
    updateModalForm,
    updateNodeConfigurations,
    updateSnackbarMsg,
    updateIsLoading,
    updateAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    if (modalForm === null) {
      initializeModalForm();
    }
  }, []);

  useEffect(() => {
    let disableSave = false;
    if (!modalForm) {
      disableSave = true;
    } else {
      disableSave = handleSaveDisable();
    }
    if (disableSave) {
      setIsSaveDisabled(true);
      return;
    }
    disableSave = false;
    setIsSaveDisabled(false);
  }, [
    modalForm,
    loginApiParamsArray,
    apiParamsArray,
    isBodyParam,
    isLoginBodyParam,
    bodyParamsArray,
    loginBodyParamsArray,
  ]);

  const initializeModalForm = () => {
    if (nodeConfigurations[selectedNode.id]) {
      const clonedNodeConfigs = structuredClone(
        nodeConfigurations[selectedNode.id]
      );
      if (clonedNodeConfigs.subType === "incoming") {
        setIncomingConfigurations(clonedNodeConfigs);
      } else {
        if (nodeConfigurations[selectedNode.id].loginRequired === "yes") {
          const newLoginApiParamsArray = structuredClone(
            nodeConfigurations[selectedNode.id].loginAPI.params
          );
          const newLoginBodyParamsArray = structuredClone(
            nodeConfigurations[selectedNode.id].loginAPI.bodyParams
          );
          if (newLoginBodyParamsArray.length) {
            setIsLoginBodyParam("yes");
          } else {
            clonedNodeConfigs.loginAPI.body = JSON.stringify(
              clonedNodeConfigs.loginAPI.body
            );
          }
          setLoginApiParamsArray(newLoginApiParamsArray);
          setLoginBodyParamsArray(newLoginBodyParamsArray);
        } else {
          clonedNodeConfigs.loginAPI = {
            url: "",
            method: "",
            params: [],
            body: "",
            authentication: "",
            bodyParams: [],
          };
        }
        const newApiParamsArray = structuredClone(
          nodeConfigurations[selectedNode.id].dataAPI.params
        );
        const newBodyParamsArray = structuredClone(
          nodeConfigurations[selectedNode.id].dataAPI.bodyParams
        );
        if (newBodyParamsArray.length) {
          setIsBodyParam("yes");
        } else {
          clonedNodeConfigs.dataAPI.body = JSON.stringify(
            clonedNodeConfigs.dataAPI.body
          );
        }
        setApiParamsArray(newApiParamsArray);
        setBodyParamsArray(newBodyParamsArray);
      }
      setTimeout(() => updateModalForm(clonedNodeConfigs), 1);
    } else {
      setTimeout(() => updateModalForm(formData), 1);
    }
  };

  const setIncomingConfigurations = (clonedNodeConfigs) => {
    clonedNodeConfigs["loginRequired"] = "no";
    clonedNodeConfigs["loginAPI"] = {
      url: "",
      method: "",
      params: [],
      body: "",
      authentication: "",
      bodyParams: [],
    };
    clonedNodeConfigs["dataAPI"] = {
      url: "",
      method: "",
      params: [],
      respKey: "",
      dataKey: "",
      body: "",
      bodyParams: [],
    };
  };

  const handleSaveDisable = () => {
    if (modalForm.name === "" || modalForm.subType === "") {
      return true;
    }
    if (modalForm.subType === "outgoing") {
      if (modalForm.loginRequired === "yes") {
        if (
          modalForm.loginAPI.url === "" ||
          modalForm.loginAPI.method === "" ||
          modalForm.loginAPI.authentication === ""
        ) {
          return true;
        }
        for (let loginApiParam of loginApiParamsArray) {
          if (loginApiParam.name === "" || loginApiParam.source === "") {
            return true;
          } else if (
            loginApiParam.source === "constant" &&
            loginApiParam.constant === ""
          ) {
            return true;
          }
        }
        if (modalForm.loginAPI.method === "post") {
          if (isLoginBodyParam === "yes") {
            for (let loginBodyParam of loginBodyParamsArray) {
              if (loginBodyParam.name === "" || loginBodyParam.source === "") {
                return true;
              } else if (
                loginBodyParam.source === "constant" &&
                loginBodyParam.constant === ""
              ) {
                return true;
              }
            }
          }
        }
      }
      if (
        modalForm.dataAPI.url === "" ||
        modalForm.dataAPI.method === "" ||
        modalForm.dataAPI.respKey === ""
      ) {
        return true;
      }
      for (let apiParam of apiParamsArray) {
        if (apiParam.name === "" || apiParam.source === "") {
          return true;
        } else if (apiParam.source === "constant" && apiParam.constant === "") {
          return true;
        }
      }
      if (modalForm.dataAPI.method === "post") {
        if (isBodyParam === "yes") {
          for (let bodyParam of bodyParamsArray) {
            if (bodyParam.name === "" || bodyParam.source === "") {
              return true;
            } else if (
              bodyParam.source === "constant" &&
              bodyParam.constant === ""
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const handleBodyParamChange = (e, index, forLogin = false) => {
    const value = e.target.value;
    const name = e.target.name;
    if (forLogin) {
      const paramsArray = structuredClone(loginBodyParamsArray);
      paramsArray[index][name] = value;
      setLoginBodyParamsArray(paramsArray);
    } else {
      const paramsArray = structuredClone(bodyParamsArray);
      paramsArray[index][name] = value;
      setBodyParamsArray(paramsArray);
    }
  };

  const handleChange = (
    e,
    parentLevelInput = false,
    isDataApi = false,
    isApiParamInput = false,
    index = null
  ) => {
    const value = e.target.value;
    const name = e.target.name;
    if (e?.target?.type === "text" && e.target.name === "name") {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    const newFormData = structuredClone(modalForm);
    if (isApiParamInput) {
      if (!isDataApi) {
        const paramsArray = structuredClone(loginApiParamsArray);
        paramsArray[index][name] = value;
        setLoginApiParamsArray(paramsArray);
      } else {
        const paramsArray = structuredClone(apiParamsArray);
        paramsArray[index][name] = value;
        setApiParamsArray(paramsArray);
      }
    } else {
      if (parentLevelInput) {
        newFormData[name] = value;
      } else {
        if (!isDataApi) {
          newFormData.loginAPI[name] = value;
        } else {
          newFormData.dataAPI[name] = value;
        }
      }
      updateModalForm(newFormData);
    }
  };

  const addLoginBodyParams = () => {
    const newLoginBodyParamsArray = structuredClone(loginBodyParamsArray);
    newLoginBodyParamsArray.push({
      name: "",
      source: "",
      constant: "",
    });
    setLoginBodyParamsArray(newLoginBodyParamsArray);
  };

  const removeLoginBodyParam = (index) => {
    const newLoginBodyParamsArray = structuredClone(loginBodyParamsArray);
    newLoginBodyParamsArray.splice(index, 1);
    setLoginBodyParamsArray(newLoginBodyParamsArray);
  };

  const addBodyParams = () => {
    const newBodyParamsArray = structuredClone(bodyParamsArray);
    newBodyParamsArray.push({
      name: "",
      source: "",
      constant: "",
    });
    setBodyParamsArray(newBodyParamsArray);
  };

  const removeBodyParam = (index) => {
    const newBodyParamsArray = structuredClone(bodyParamsArray);
    newBodyParamsArray.splice(index, 1);
    setBodyParamsArray(newBodyParamsArray);
  };

  const addLoginApiParams = () => {
    const newloginApiParamsArray = structuredClone(loginApiParamsArray);
    newloginApiParamsArray.push({
      name: "",
      source: "",
      constant: "",
    });
    setLoginApiParamsArray(newloginApiParamsArray);
  };

  const addApiParams = () => {
    const newApiParamsArray = structuredClone(apiParamsArray);
    newApiParamsArray.push({
      name: "",
      source: "",
      constant: "",
    });
    setApiParamsArray(newApiParamsArray);
  };

  const removeLoginApiParam = (index) => {
    const newloginApiParamsArray = structuredClone(loginApiParamsArray);
    newloginApiParamsArray.splice(index, 1);
    setLoginApiParamsArray(newloginApiParamsArray);
  };

  const removeApiParam = (index) => {
    const newApiParamsArray = structuredClone(apiParamsArray);
    newApiParamsArray.splice(index, 1);
    setApiParamsArray(newApiParamsArray);
  };

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    const dsMap = pipeline.clientMetaData.dsMap;
    if (nodeConfigs.name || nodeConfigs.fileName) {
      for (let nodeId of Object.keys(dsMap)) {
        if (
          selectedNode.id !== nodeId &&
          ((nodeConfigs.name && dsMap[nodeId] === nodeConfigs.name) ||
            (nodeConfigs.fileName && nodeConfigs.fileName === dsMap[nodeId]))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const checkJsonValidity = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const clickSubmit = (e) => {
    saveUploadedFile(e);
  };

  const saveUploadedFile = async (e) => {
    const apiUrl = BASE_API_URL + "upload-data";
    const headers = {};
    let payload = new FormData();
    let newModalForm = structuredClone(modalForm);
    payload.append("sourceType", "api");
    newModalForm["sourceType"] = "api";
    newModalForm["dsName"] = newModalForm.name;
    payload.append("dSource", selectedNode.id);
    payload.append("projectKey", selectedProjectKey);
    payload.append("projVersion", selectedProjVersion);
    payload.append("projFg", selectedFeatureGroup);
    payload.append("dataCfg", JSON.stringify(newModalForm));
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: uploadDataAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
          data: null,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        handleSubmit(e);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    const newFormData = structuredClone(modalForm);
    if (newFormData.subType === "outgoing") {
      if (newFormData.loginRequired === "yes") {
        newFormData.loginAPI.params = loginApiParamsArray;
        if (isLoginBodyParam === "yes") {
          newFormData.loginAPI.bodyParams = loginBodyParamsArray;
          newFormData.loginAPI.body = "";
        } else {
          if (modalForm.loginAPI.method === "post") {
            const isValidJSON = checkJsonValidity(newFormData.loginAPI.body);
            if (!isValidJSON) {
              updateSnackbarMsg("Please enter a valid JSON in login api body");
              return;
            }
            newFormData.loginAPI.body = JSON.parse(newFormData.loginAPI.body);
          } else {
            newFormData.loginAPI.body = "";
          }
          newFormData.loginAPI.bodyParams = [];
        }
      }
      newFormData.dataAPI.params = apiParamsArray;
      if (isBodyParam === "yes") {
        newFormData.dataAPI.bodyParams = bodyParamsArray;
        newFormData.dataAPI.body = "";
      } else {
        if (modalForm.dataAPI.method === "post") {
          const isValidJSON = checkJsonValidity(newFormData.dataAPI.body);
          if (!isValidJSON) {
            updateSnackbarMsg("Please enter a valid JSON in data api body");
            return;
          }
          newFormData.dataAPI.body = JSON.parse(newFormData.dataAPI.body);
        } else {
          newFormData.dataAPI.body = "";
        }
        newFormData.dataAPI.bodyParams = [];
      }
    }
    const duplicatedName = checkNodeNameDuplicacy(newFormData);
    if (duplicatedName) {
      updateSnackbarMsg("Name already taken. Please enter a unique name");
      return;
    }
    updateModalForm(newFormData);
    newNodeConfigurations[selectedNode.id] = structuredClone(newFormData);
    updateNodeConfigurations(newNodeConfigurations);
    modalProps.unHighlightProblematicNode(selectedNode);
    setTimeout(
      () => modalProps.saveConfig(newNodeConfigurations, selectedNode.id),
      1
    );
    modalProps.closeModal(modalType);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      {modalForm && (
        <div>
          <Dialog
            open={selectedNode !== null}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
            fullWidth
            PaperProps={{
              style: {
                background: "#212B35",
                border: "1px solid #818181",
                boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                color: "#EFF1F1",
                maxHeight: "calc(100% - 122px)",
              },
            }}
          >
            <DialogTitle id="responsive-dialog-title">
              <div>Add data from API</div>
              <div>
                {detailedMsgTooltipData[selectedNode.data.nodeType] ? (
                  <IconButton>
                    <CustomTooltip
                      title={detailedMsgTooltipData[selectedNode.data.nodeType]}
                    >
                      <QuestionMarkIcon
                        width={25}
                        height={25}
                      ></QuestionMarkIcon>
                    </CustomTooltip>
                  </IconButton>
                ) : (
                  <></>
                )}
                <IconButton onClick={() => modalProps.closeModal(modalType)}>
                  <CloseIcon></CloseIcon>
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: "20px !important" }}>
              <FormControl id="name" className="text-field" size="small">
                <TextField
                  type="text"
                  name="name"
                  size="small"
                  label="Name"
                  value={modalForm ? modalForm.name : ""}
                  onChange={(evt) => handleChange(evt, true)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>

              <FormControl id="subType" sx={{ marginTop: "15px !important" }}>
                <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
                  API direction
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="subType"
                  value={modalForm ? modalForm.subType : ""}
                  onChange={(e) => handleChange(e, true)}
                >
                  {[
                    {
                      name: "Inbound",
                      value: "incoming",
                    },
                    {
                      name: "Outbound",
                      value: "outgoing",
                    },
                  ].map((radio, radioIndex) => (
                    <FormControlLabel
                      key={radioIndex}
                      value={radio.value}
                      control={<Radio style={{ color: "#69C2D2" }} />}
                      label={radio.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {modalForm && modalForm.subType === "outgoing" && (
                <Stack>
                  <FormControl
                    id="loginRequired"
                    sx={{ marginTop: "15px !important" }}
                  >
                    <Stack direction="row" alignItems="center" gap="20px">
                      <FormLabel
                        id="row-radio-buttons-group-label"
                        sx={labelStyles}
                      >
                        Enable authentication
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="loginRequired"
                        value={modalForm ? modalForm.loginRequired : ""}
                        onChange={(e) => handleChange(e, true)}
                      >
                        {[
                          {
                            name: "Yes",
                            value: "yes",
                          },
                          {
                            name: "No",
                            value: "no",
                          },
                        ].map((radio, radioIndex) => (
                          <FormControlLabel
                            key={radioIndex}
                            value={radio.value}
                            control={<Radio style={{ color: "#69C2D2" }} />}
                            label={radio.name}
                          />
                        ))}
                      </RadioGroup>
                    </Stack>
                  </FormControl>
                  {modalForm.loginRequired === "yes" && (
                    <Accordion
                      sx={{
                        background: "#151c24;",
                        color: "rgb(239, 241, 241) !important",
                        marginTop: "10px",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ChevronDown className="icon" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#191818",
                        }}
                      >
                        <Typography>Login Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControl
                          id="url"
                          className="text-field"
                          size="small"
                        >
                          <TextField
                            type="text"
                            name="url"
                            size="small"
                            label="Url/Endpoint"
                            value={modalForm ? modalForm.loginAPI.url : ""}
                            onChange={(evt) => handleChange(evt)}
                            variant="outlined"
                            sx={textFieldStyles}
                            autoComplete="off"
                            required
                          />
                        </FormControl>
                        <FormControl
                          id="method"
                          sx={formControlStyles}
                          className="w-100"
                          size="small"
                        >
                          <InputLabel sx={labelStyles}>Method</InputLabel>
                          <Select
                            placeholder="Select option"
                            name="method"
                            label="Method"
                            size="small"
                            value={modalForm ? modalForm.loginAPI.method : ""}
                            onChange={(evt) => handleChange(evt)}
                            required
                            sx={selectStyles}
                            inputProps={{ sx: selectStyles }}
                            MenuProps={{ sx: selectStyles }}
                            autoWidth={true}
                          >
                            <MenuItem value="get" sx={menuItemStyles}>
                              <Tooltip title="Get" placement="right">
                                <Typography noWrap>Get</Typography>
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value="post" sx={menuItemStyles}>
                              <Tooltip title="Post" placement="right">
                                <Typography noWrap>Post</Typography>
                              </Tooltip>
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <Box marginTop={"15px"}>
                          <Box className="add-more-parms-button-container">
                            <Button
                              sx={buttonStyles}
                              className="add-more-parms-button"
                              onClick={addLoginApiParams}
                            >
                              Add Login Api parameters
                            </Button>
                          </Box>
                          {loginApiParamsArray.length > 0 && (
                            <Stack className="params-box">
                              {loginApiParamsArray.map((input, index) => (
                                <Box
                                  className="params-container"
                                  key={"loginParam" + index}
                                >
                                  <Box className="w-100">
                                    <FormControl
                                      id={"name" + index}
                                      className="text-field"
                                      size="small"
                                    >
                                      <TextField
                                        type="text"
                                        name="name"
                                        size="small"
                                        label="Name"
                                        value={input ? input.name : ""}
                                        onChange={(evt) =>
                                          handleChange(
                                            evt,
                                            false,
                                            false,
                                            true,
                                            index
                                          )
                                        }
                                        autoComplete="off"
                                        variant="outlined"
                                        sx={textFieldStyles}
                                        required
                                      />
                                    </FormControl>
                                    <FormControl
                                      id={"source" + index}
                                      sx={formControlStyles}
                                      className="w-100"
                                      size="small"
                                    >
                                      <InputLabel sx={labelStyles}>
                                        Source
                                      </InputLabel>
                                      <Select
                                        placeholder="Select option"
                                        name="source"
                                        label="Source"
                                        size="small"
                                        value={input ? input.source : ""}
                                        onChange={(evt) =>
                                          handleChange(
                                            evt,
                                            false,
                                            false,
                                            true,
                                            index
                                          )
                                        }
                                        required
                                        sx={selectStyles}
                                        inputProps={{ sx: selectStyles }}
                                        MenuProps={{ sx: selectStyles }}
                                        autoWidth={true}
                                      >
                                        <MenuItem
                                          value="stream"
                                          sx={menuItemStyles}
                                        >
                                          <Tooltip
                                            title="Stream"
                                            placement="right"
                                          >
                                            <Typography noWrap>
                                              Stream
                                            </Typography>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem
                                          value="constant"
                                          sx={menuItemStyles}
                                        >
                                          <Tooltip
                                            title="constant"
                                            placement="right"
                                          >
                                            <Typography noWrap>
                                              Constant
                                            </Typography>
                                          </Tooltip>
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    {input.source === "constant" && (
                                      <FormControl
                                        id={"constant" + index}
                                        className="value-field"
                                        size="small"
                                      >
                                        <TextField
                                          type="text"
                                          name="constant"
                                          size="small"
                                          label="constant"
                                          value={input ? input.constant : ""}
                                          onChange={(evt) =>
                                            handleChange(
                                              evt,
                                              false,
                                              false,
                                              true,
                                              index
                                            )
                                          }
                                          variant="outlined"
                                          sx={textFieldStyles}
                                          autoComplete="off"
                                          required
                                        />
                                      </FormControl>
                                    )}
                                  </Box>
                                  <IconButton
                                    aria-label="close"
                                    className="close-icon"
                                    onClick={() => removeLoginApiParam(index)}
                                  >
                                    <CloseIcon></CloseIcon>
                                  </IconButton>
                                </Box>
                              ))}
                            </Stack>
                          )}
                        </Box>
                        {modalForm.loginAPI.method === "post" && (
                          <Stack>
                            <FormControl
                              id="isLoginBodyParam"
                              sx={{ marginTop: "15px !important" }}
                            >
                              <FormLabel
                                id="row-radio-buttons-group-label"
                                sx={labelStyles}
                              >
                                Choose one
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="row-radio-buttons-group-label"
                                name="loginRequired"
                                value={isLoginBodyParam}
                                onChange={(e) => {
                                  setIsLoginBodyParam(e.target.value);
                                }}
                              >
                                {[
                                  {
                                    name: "Body params",
                                    value: "yes",
                                  },
                                  {
                                    name: "Api body",
                                    value: "no",
                                  },
                                ].map((radio, radioIndex) => (
                                  <FormControlLabel
                                    key={radioIndex}
                                    value={radio.value}
                                    control={
                                      <Radio style={{ color: "#69C2D2" }} />
                                    }
                                    label={radio.name}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                            {isLoginBodyParam === "yes" ? (
                              <Box marginTop={"15px"}>
                                <Box className="add-more-parms-button-container">
                                  <Button
                                    sx={buttonStyles}
                                    className="add-more-parms-button"
                                    onClick={addLoginBodyParams}
                                  >
                                    Add login body parameters
                                  </Button>
                                </Box>
                                {loginBodyParamsArray.length > 0 && (
                                  <Stack className="params-box">
                                    {loginBodyParamsArray.map(
                                      (input, index) => (
                                        <Box
                                          className="params-container"
                                          key={index}
                                        >
                                          <Box className="w-100">
                                            <FormControl
                                              id={"name" + index}
                                              className="text-field"
                                              size="small"
                                            >
                                              <TextField
                                                type="text"
                                                name="name"
                                                size="small"
                                                label="Name"
                                                value={input ? input.name : ""}
                                                onChange={(evt) =>
                                                  handleBodyParamChange(
                                                    evt,
                                                    index,
                                                    true
                                                  )
                                                }
                                                autoComplete="off"
                                                variant="outlined"
                                                sx={textFieldStyles}
                                                required
                                              />
                                            </FormControl>
                                            <FormControl
                                              id={"source" + index}
                                              sx={formControlStyles}
                                              className="w-100"
                                              size="small"
                                            >
                                              <InputLabel sx={labelStyles}>
                                                Source
                                              </InputLabel>
                                              <Select
                                                placeholder="Select option"
                                                name="source"
                                                label="Source"
                                                size="small"
                                                value={
                                                  input ? input.source : ""
                                                }
                                                onChange={(evt) =>
                                                  handleBodyParamChange(
                                                    evt,
                                                    index,
                                                    true
                                                  )
                                                }
                                                required
                                                sx={selectStyles}
                                                inputProps={{
                                                  sx: selectStyles,
                                                }}
                                                MenuProps={{ sx: selectStyles }}
                                                autoWidth={true}
                                              >
                                                <MenuItem
                                                  value="stream"
                                                  sx={menuItemStyles}
                                                >
                                                  <Tooltip
                                                    title="Stream"
                                                    placement="right"
                                                  >
                                                    <Typography noWrap>
                                                      Stream
                                                    </Typography>
                                                  </Tooltip>
                                                </MenuItem>
                                                <MenuItem
                                                  value="constant"
                                                  sx={menuItemStyles}
                                                >
                                                  <Tooltip
                                                    title="constant"
                                                    placement="right"
                                                  >
                                                    <Typography noWrap>
                                                      Constant
                                                    </Typography>
                                                  </Tooltip>
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                            {input.source === "constant" && (
                                              <FormControl
                                                id={"constant" + index}
                                                className="value-field"
                                                size="small"
                                              >
                                                <TextField
                                                  type="text"
                                                  name="constant"
                                                  size="small"
                                                  label="constant"
                                                  value={
                                                    input ? input.constant : ""
                                                  }
                                                  onChange={(evt) =>
                                                    handleBodyParamChange(
                                                      evt,
                                                      index,
                                                      true
                                                    )
                                                  }
                                                  variant="outlined"
                                                  sx={textFieldStyles}
                                                  autoComplete="off"
                                                  required
                                                />
                                              </FormControl>
                                            )}
                                          </Box>
                                          <IconButton
                                            aria-label="close"
                                            className="close-icon"
                                            onClick={() =>
                                              removeLoginBodyParam(index)
                                            }
                                          >
                                            <CloseIcon></CloseIcon>
                                          </IconButton>
                                        </Box>
                                      )
                                    )}
                                  </Stack>
                                )}
                              </Box>
                            ) : (
                              <FormControl
                                id="body"
                                className="text-field"
                                size="small"
                              >
                                <TextField
                                  type="text"
                                  name="body"
                                  size="small"
                                  label="Api body"
                                  value={
                                    modalForm ? modalForm.loginAPI.body : ""
                                  }
                                  onChange={(evt) => handleChange(evt)}
                                  variant="outlined"
                                  sx={textFieldStyles}
                                  autoComplete="off"
                                  multiline
                                  minRows={2}
                                  maxRows={4}
                                  required
                                />
                              </FormControl>
                            )}
                          </Stack>
                        )}
                        <FormControl
                          id="authentication"
                          sx={formControlStyles}
                          className="w-100"
                          size="small"
                        >
                          <InputLabel sx={labelStyles}>
                            Response type
                          </InputLabel>
                          <Select
                            placeholder="Select option"
                            name="authentication"
                            label="Response type"
                            size="small"
                            value={
                              modalForm ? modalForm.loginAPI.authentication : ""
                            }
                            onChange={(evt) => handleChange(evt)}
                            required
                            sx={selectStyles}
                            inputProps={{ sx: selectStyles }}
                            MenuProps={{ sx: selectStyles }}
                            autoWidth={true}
                          >
                            <MenuItem value="header" sx={menuItemStyles}>
                              <Tooltip title="Header" placement="right">
                                <Typography noWrap>Header</Typography>
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value="cookie" sx={menuItemStyles}>
                              <Tooltip title="Cookie" placement="right">
                                <Typography noWrap>Cookie</Typography>
                              </Tooltip>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <Accordion
                    sx={{
                      background: "#151c24;",
                      color: "rgb(239, 241, 241) !important",
                      marginTop: "10px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ChevronDown className="icon" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor: "#191818",
                      }}
                    >
                      <Typography>Api Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl id="url" className="text-field" size="small">
                        <TextField
                          type="text"
                          name="url"
                          size="small"
                          label="Url/Endpoint"
                          value={modalForm ? modalForm.dataAPI.url : ""}
                          onChange={(evt) => handleChange(evt, false, true)}
                          variant="outlined"
                          sx={textFieldStyles}
                          autoComplete="off"
                          required
                        />
                      </FormControl>
                      <FormControl
                        id="method"
                        sx={formControlStyles}
                        className="w-100"
                        size="small"
                      >
                        <InputLabel sx={labelStyles}>Method</InputLabel>
                        <Select
                          placeholder="Select option"
                          name="method"
                          label="Method"
                          size="small"
                          value={modalForm ? modalForm.dataAPI.method : ""}
                          onChange={(evt) => handleChange(evt, false, true)}
                          required
                          sx={selectStyles}
                          inputProps={{ sx: selectStyles }}
                          MenuProps={{ sx: selectStyles }}
                          autoWidth={true}
                        >
                          <MenuItem value="get" sx={menuItemStyles}>
                            <Tooltip title="Get" placement="right">
                              <Typography noWrap>Get</Typography>
                            </Tooltip>
                          </MenuItem>
                          <MenuItem value="post" sx={menuItemStyles}>
                            <Tooltip title="Post" placement="right">
                              <Typography noWrap>Post</Typography>
                            </Tooltip>
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <Box marginTop={"15px"}>
                        <Box className="add-more-parms-button-container">
                          <Button
                            sx={buttonStyles}
                            className="add-more-parms-button"
                            onClick={addApiParams}
                          >
                            Add Api parameters
                          </Button>
                        </Box>
                        {apiParamsArray.length > 0 && (
                          <Stack className="params-box">
                            {apiParamsArray.map((input, index) => (
                              <Box className="params-container" key={index}>
                                <Box className="w-100">
                                  <FormControl
                                    id={"name" + index}
                                    className="text-field"
                                    size="small"
                                  >
                                    <TextField
                                      type="text"
                                      name="name"
                                      size="small"
                                      label="Name"
                                      value={input ? input.name : ""}
                                      onChange={(evt) =>
                                        handleChange(
                                          evt,
                                          false,
                                          true,
                                          true,
                                          index
                                        )
                                      }
                                      autoComplete="off"
                                      variant="outlined"
                                      sx={textFieldStyles}
                                      required
                                    />
                                  </FormControl>
                                  <FormControl
                                    id={"source" + index}
                                    sx={formControlStyles}
                                    className="w-100"
                                    size="small"
                                  >
                                    <InputLabel sx={labelStyles}>
                                      Source
                                    </InputLabel>
                                    <Select
                                      placeholder="Select option"
                                      name="source"
                                      label="Source"
                                      size="small"
                                      value={input ? input.source : ""}
                                      onChange={(evt) =>
                                        handleChange(
                                          evt,
                                          false,
                                          true,
                                          true,
                                          index
                                        )
                                      }
                                      required
                                      sx={selectStyles}
                                      inputProps={{ sx: selectStyles }}
                                      MenuProps={{ sx: selectStyles }}
                                      autoWidth={true}
                                    >
                                      <MenuItem
                                        value="stream"
                                        sx={menuItemStyles}
                                      >
                                        <Tooltip
                                          title="Stream"
                                          placement="right"
                                        >
                                          <Typography noWrap>Stream</Typography>
                                        </Tooltip>
                                      </MenuItem>
                                      <MenuItem
                                        value="constant"
                                        sx={menuItemStyles}
                                      >
                                        <Tooltip
                                          title="constant"
                                          placement="right"
                                        >
                                          <Typography noWrap>
                                            Constant
                                          </Typography>
                                        </Tooltip>
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  {input.source === "constant" && (
                                    <FormControl
                                      id={"constant" + index}
                                      className="value-field"
                                      size="small"
                                    >
                                      <TextField
                                        type="text"
                                        name="constant"
                                        size="small"
                                        label="constant"
                                        value={input ? input.constant : ""}
                                        onChange={(evt) =>
                                          handleChange(
                                            evt,
                                            false,
                                            true,
                                            true,
                                            index
                                          )
                                        }
                                        variant="outlined"
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        required
                                      />
                                    </FormControl>
                                  )}
                                </Box>
                                <IconButton
                                  aria-label="close"
                                  className="close-icon"
                                  onClick={() => removeApiParam(index)}
                                >
                                  <CloseIcon></CloseIcon>
                                </IconButton>
                              </Box>
                            ))}
                          </Stack>
                        )}
                      </Box>
                      {modalForm.dataAPI.method === "post" && (
                        <Stack>
                          <FormControl
                            id="isBodyParam"
                            sx={{ marginTop: "15px !important" }}
                          >
                            <FormLabel
                              id="row-radio-buttons-group-label"
                              sx={labelStyles}
                            >
                              Choose one
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="row-radio-buttons-group-label"
                              name="isBodyParam"
                              value={isBodyParam}
                              onChange={(e) => {
                                setIsBodyParam(e.target.value);
                              }}
                            >
                              {[
                                {
                                  name: "Body params",
                                  value: "yes",
                                },
                                {
                                  name: "Api body",
                                  value: "no",
                                },
                              ].map((radio, radioIndex) => (
                                <FormControlLabel
                                  key={radioIndex}
                                  value={radio.value}
                                  control={
                                    <Radio style={{ color: "#69C2D2" }} />
                                  }
                                  label={radio.name}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                          {isBodyParam === "yes" ? (
                            <Box marginTop={"15px"}>
                              <Box className="add-more-parms-button-container">
                                <Button
                                  sx={buttonStyles}
                                  className="add-more-parms-button"
                                  onClick={addBodyParams}
                                >
                                  Add body parameters
                                </Button>
                              </Box>
                              {bodyParamsArray.length > 0 && (
                                <Stack className="params-box">
                                  {bodyParamsArray.map((input, index) => (
                                    <Box
                                      className="params-container"
                                      key={index}
                                    >
                                      <Box className="w-100">
                                        <FormControl
                                          id={"name" + index}
                                          className="text-field"
                                          size="small"
                                        >
                                          <TextField
                                            type="text"
                                            name="name"
                                            size="small"
                                            label="Name"
                                            value={input ? input.name : ""}
                                            onChange={(evt) =>
                                              handleBodyParamChange(evt, index)
                                            }
                                            autoComplete="off"
                                            variant="outlined"
                                            sx={textFieldStyles}
                                            required
                                          />
                                        </FormControl>
                                        <FormControl
                                          id={"source" + index}
                                          sx={formControlStyles}
                                          className="w-100"
                                          size="small"
                                        >
                                          <InputLabel sx={labelStyles}>
                                            Source
                                          </InputLabel>
                                          <Select
                                            placeholder="Select option"
                                            name="source"
                                            label="Source"
                                            size="small"
                                            value={input ? input.source : ""}
                                            onChange={(evt) =>
                                              handleBodyParamChange(evt, index)
                                            }
                                            required
                                            sx={selectStyles}
                                            inputProps={{ sx: selectStyles }}
                                            MenuProps={{ sx: selectStyles }}
                                            autoWidth={true}
                                          >
                                            <MenuItem
                                              value="stream"
                                              sx={menuItemStyles}
                                            >
                                              <Tooltip
                                                title="Stream"
                                                placement="right"
                                              >
                                                <Typography noWrap>
                                                  Stream
                                                </Typography>
                                              </Tooltip>
                                            </MenuItem>
                                            <MenuItem
                                              value="constant"
                                              sx={menuItemStyles}
                                            >
                                              <Tooltip
                                                title="constant"
                                                placement="right"
                                              >
                                                <Typography noWrap>
                                                  Constant
                                                </Typography>
                                              </Tooltip>
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                        {input.source === "constant" && (
                                          <FormControl
                                            id={"constant" + index}
                                            className="value-field"
                                            size="small"
                                          >
                                            <TextField
                                              type="text"
                                              name="constant"
                                              size="small"
                                              label="constant"
                                              value={
                                                input ? input.constant : ""
                                              }
                                              onChange={(evt) =>
                                                handleBodyParamChange(
                                                  evt,
                                                  index
                                                )
                                              }
                                              variant="outlined"
                                              sx={textFieldStyles}
                                              autoComplete="off"
                                              required
                                            />
                                          </FormControl>
                                        )}
                                      </Box>
                                      <IconButton
                                        aria-label="close"
                                        className="close-icon"
                                        onClick={() => removeBodyParam(index)}
                                      >
                                        <CloseIcon></CloseIcon>
                                      </IconButton>
                                    </Box>
                                  ))}
                                </Stack>
                              )}
                            </Box>
                          ) : (
                            <FormControl
                              id="body"
                              className="text-field"
                              size="small"
                            >
                              <TextField
                                type="text"
                                name="body"
                                size="small"
                                label="Api body"
                                value={modalForm ? modalForm.dataAPI.body : ""}
                                onChange={(evt) =>
                                  handleChange(evt, false, true)
                                }
                                variant="outlined"
                                sx={textFieldStyles}
                                autoComplete="off"
                                multiline
                                minRows={2}
                                maxRows={4}
                                required
                              />
                            </FormControl>
                          )}
                        </Stack>
                      )}
                      <FormControl
                        id="respKey"
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="respKey"
                          size="small"
                          label="Response key"
                          value={modalForm ? modalForm.dataAPI.respKey : ""}
                          onChange={(evt) => handleChange(evt, false, true)}
                          variant="outlined"
                          sx={textFieldStyles}
                          autoComplete="off"
                          required
                        />
                      </FormControl>
                      <FormControl
                        id="dataKey"
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="dataKey"
                          size="small"
                          label="Data key"
                          value={modalForm ? modalForm.dataAPI.dataKey : ""}
                          onChange={(evt) => handleChange(evt, false, true)}
                          variant="outlined"
                          sx={textFieldStyles}
                          autoComplete="off"
                        />
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              )}
            </DialogContent>
            <DialogActions>
              <Stack direction="row" gap="10px" paddingX="40px" paddingY="20px">
                <Button
                  variant="contained"
                  onClick={clickSubmit}
                  autoFocus
                  sx={buttonStyles}
                  disabled={isSaveDisabled || userAccessRestricted}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  onClick={() => modalProps.closeModal(modalType)}
                  autoFocus
                  sx={buttonStyles}
                >
                  Cancel
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={snackbarMsg}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMsg}
            action={action}
          />
        </div>
      )}
    </div>
  );
};

export default ApiDialog;
