import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./ViewRunningJobsDialog.scss";
import api from "../../apiInterceptor";
import { Box, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
const getrunningjobdetailsAPITestData = require("../../assets/apiTestData/getrunningjobdetails-test-data.json");

const ViewRunningJobsDialog = ({ jobDetails = null }) => {
  const COMMON_API_URL = localStorage.getItem("COMMON_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [jobDetail, setJobDetail] = useState(null);
  const [jobDetailId, setJobDetailId] = useState("");
  const [jobDetailDesc, setJobDetailDesc] = useState("");
  const [jobDetailType, setJobDetailType] = useState("");
  const dispatch = useDispatch();
  const {
    updateIsLoading,
    updateViewRunningJobsDialogStatus,
    updateAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);
  const isViewRunningJobsDialogOpen = useSelector(
    (state) => state.isViewRunningJobsDialogOpen
  );

  useEffect(() => {
    if (jobDetails) {
      getRunningJobs(jobDetails.job_id);
      fillRunningJobsDetials(jobDetails);
    }
  }, [jobDetails]);

  const fillRunningJobsDetials = (jobDetails) => {
    setJobDetailId(jobDetails.job_id);
    setJobDetailDesc(jobDetails.job_desc);
    setJobDetailType(jobDetails.job_type);
  };

  const getRunningJobs = async (job_id) => {
    const apiUrl = COMMON_API_URL + "getrunningjobdetails";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = { job_id: job_id };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = getrunningjobdetailsAPITestData;
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.status === 200) {
        const runningJobDetails = response.data.run_job_list;
        if (runningJobDetails && runningJobDetails.length > 0) {
          setJobDetail(runningJobDetails);
        }
      } else if (response.status === 404) {
        if (response.data.reason) {
          updateAlertMessage(response.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleClose = () => {
    updateViewRunningJobsDialogStatus(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isViewRunningJobsDialogOpen}
      aria-labelledby="Set Up New Job"
      className="setup-new-job-dialog"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
          maxHeight: "calc(100% - 122px)",
        },
      }}
    >
      <DialogTitle id="setup-new-job-title">
        <Box>Running Jobs</Box>
        <IconButton
          aria-label="close"
          className="close-icon"
          onClick={handleClose}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="stretch" gap={5}>
          <div className="job-common-details">
            <div className="top-row">
              <span className="job-key ">Job ID</span>
              <br />
              <span className="job-data">{jobDetailId}</span>
            </div>
            <div className="top-row job-detail">
              <span className="job-key ">Job Desc</span>
              <br />
              <span className="job-data">{jobDetailDesc}</span>
            </div>
            <div className="top-row job-detail">
              <span className="job-key ">Job Type</span>
              <br />
              <span className="job-data">{jobDetailType}</span>
            </div>
          </div>
          <Stack className="table">
            <Stack className="table-header" direction="row">
              <Box className="header-item" sx={{ width: "10%" }}>
                S.no
              </Box>
              <Box className="header-item" sx={{ width: "25%" }}>
                Start Time
              </Box>
              <Box className="header-item" sx={{ width: "20%" }}>
                Status
              </Box>
              <Box className="header-item" sx={{ width: "45%" }}>
                Description
              </Box>
            </Stack>
            {jobDetail &&
              jobDetail.map((element, index) => (
                <Stack key={index} className="table-row " direction="row">
                  <Box className="row-item" sx={{ width: "10%" }}>
                    {index + 1}
                  </Box>
                  <Box className="row-item" sx={{ width: "25%" }}>
                    {element.start_time}
                  </Box>
                  <Box className="row-item" sx={{ width: "20%" }}>
                    {element.status}
                  </Box>
                  <Box className="row-item" sx={{ width: "45%" }}>
                    {element.output_path}
                  </Box>
                </Stack>
              ))}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ViewRunningJobsDialog;
