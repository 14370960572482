import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SidebarGraph from "components/sidebarGraph/sidebarGraph";
import Sidebar from "components/sideBar/Sidebar";
import "./tabularSidebar.scss";
import { useState } from "react";
// import { ReactComponent as LeftArrowIcon } from "../../assets/icons/leftArrowIcon.svg";
// import { ReactComponent as RightArrowIcon } from "../../assets/icons/rightArrowIcon.svg";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ marginTop: "15px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TabularSidebar = () => {
  const [value, setValue] = React.useState(0);
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // const handleSidebarToggle = () => {
  //   setIsSidebarOpen(!isSidebarOpen);
  // };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="tabular-sidebar">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: { backgroundColor: "#69c2d2" }, // Change this to the desired color for the indicator
          }}
        >
          <Tab className="tab-item" label="Nodes" {...a11yProps(0)} />
          <Tab className="tab-item" label="Resource" {...a11yProps(1)} />={" "}
        </Tabs>
      </Box>
      <Box sx={{ marginTop: "15px" }}>
        {value === 0 && <Sidebar />}
        {value === 1 && <SidebarGraph />}
      </Box>
      {/* <div className="toggle-button" onClick={handleSidebarToggle}>
        <div className="toggle-button-title">
          {isSidebarOpen ? "Collapse sidebar" : "Expand sidebar"}
        </div>
        {isSidebarOpen ? (
          <RightArrowIcon></RightArrowIcon>
        ) : (
          <LeftArrowIcon></LeftArrowIcon>
        )}
      </div> */}
    </Box>
  );
};

export default TabularSidebar;
