import React, { useEffect } from "react";
import api from "../../apiInterceptor";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../state";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const loginViaAccessTokenTestData = require("../../assets/apiTestData/login-via-access-token-test-data.json");

const UserManagement = () => {
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const userManagementUrl = searchParams.get("userManagementUrl");
  localStorage.setItem("USER_MANAGEMENT_URL", userManagementUrl);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateIsLoading, updateAlertMessage } = bindActionCreators(
    actionCreators,
    dispatch
  );

  useEffect(() => {
    if (!token) return;
    localStorage.setItem("token", token);
    const loginViaAccessToken = async () => {
      const apiUrl = BASE_API_URL + "login-via-access-token";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        access_token: token,
      };
      updateIsLoading(true);
      try {
        let response = {};
        if (USING_TEST_DATA) {
          response = {
            data: loginViaAccessTokenTestData,
          };
        } else {
          response = await api.post(apiUrl, payload, { headers: headers })
        }
        updateIsLoading(false);
        if (response.data.status >= 200 && response.data.status < 300) {
          const accessType =
            response.data.data.posts[0].role_access_info &&
            response.data.data.posts[0].role_access_info.accessType
              ? response.data.data.posts[0].role_access_info.accessType
              : "normal";
          const userName = response.data.data.posts[0].username || "";
          localStorage.setItem("ACCESS_TYPE", accessType);
          localStorage.setItem("USER_NAME", userName);
          setTimeout(() => navigate("/"), 1);
        } else if (response.data.status === 404) {
          if (response.data.data.reason) {
            updateAlertMessage(response.data.data.reason);
          } else {
            updateAlertMessage("Something went wrong. Please try again later");
          }
        }
      } catch (error) {
        console.error(error);
        updateIsLoading(false);
        const errorMessage =
          "Something went wrong. Please contact the administrator";
        updateAlertMessage(errorMessage);
      }
    };
    loginViaAccessToken();
  }, [token]);

  return <div></div>;
};
export default UserManagement;
