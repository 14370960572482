import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./multiFilterDialog.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import {
  Checkbox,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionMark.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { useReactFlow } from "reactflow";
import Chip from "@mui/material/Chip";
import Filter from "components/filter/filter";
import {
  fetchFeatures,
  getConnectedDataSources,
  getConnectedNodes,
  handleDependentInputs,
  handleFilterSaveDisable,
  handleGeneralSaveButtonDisabling,
  initializeFilterInfo,
  prepareColumns,
  restoreDialogUI,
} from "utils/utils";
import {
  buttonStyles,
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  textFieldStyles,
  chipTextFieldStyles,
  toggleButtonStyles,
} from "./multiFilterDialogStyle";
import { styled } from "@mui/system";
import api from "apiInterceptor";
import CustomTooltip from "components/Tooltip/CustomTooltip";
const nodeInfo = require("../../assets/testdata/node-info-testdata.json");
const getprojcfgAPITestData = require("../../assets/apiTestData/getprojcfg-test-data.json");

const MultiFilterDialog = (modalProps) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const modalType = "multiFilterDialog";
  const { getNode } = useReactFlow();
  let formData = {};
  let autoCompleteRef = {};
  const selectedNode = modalProps.selectedNode;
  const nodeType = selectedNode?.name;
  let currentModalUI = structuredClone(modalProps.uiConfig[nodeType]);
  const nodesFlow = modalProps.nodesFlow;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const filteredOptions = useRef({});
  const [selectAllCheckboxes, setSelectAllCheckboxes] = useState({});
  const columnsInfo = [];
  const [maxColsAllowed, setMaxColsAllowed] = useState(null);
  const [allColumns, setAllColumns] = useState([]);
  const [commonColumns, setCommonColumns] = useState([]);
  const [numColumns, setNumColumns] = useState([]);
  const [catColumns, setCatColumns] = useState([]);
  const [dateColumns, setDateColumns] = useState([]);
  const [mixedColumns, setMixedColumns] = useState([]);
  const [multiFilters, setMultiFilters] = useState([]);
  const [windowOptionsData, setWindowOptionsData] = useState();
  const [isWordCountTextFieldError, setIsWordCountTextFieldError] =
    useState(false);
  const isInitialMount = useRef(true);
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const modalForm = useSelector((state) => state.modalForm);
  const modalUI = useSelector((state) => state.modalUI);
  const pipeline = useSelector((state) => state.pipeline);
  const filterCfg = useSelector((state) => state.filterCfg);
  const statementNames = useSelector((state) => state.statementNames);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const detailedMsgTooltipData = useSelector(
    (state) => state.detailedMsgTooltipData
  );
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const dispatch = useDispatch();
  const {
    updateModalForm,
    updateModalUI,
    updateNodeConfigurations,
    updateFilterCfg,
    updateStatementNames,
    updateIsLoading,
    updateAlertMessage,
    updateSnackbarMsg,
  } = bindActionCreators(actionCreators, dispatch);

  const GroupHeader = styled("div")(({ theme, padding = "4px 10px" }) => ({
    position: "sticky",
    top: "-8px",
    zIndex: 10000,
    padding: padding,
    color: "#FFFFFF",
    backgroundColor: "rgb(68 79 91)",
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });

  useEffect(() => {
    const absentDs = handleDataSourceAbsence();
    if (absentDs) {
      return;
    }
    updateFilterCfg([]);
    getNodeLevelInfo();
    if (isInitialMount.current && connectedDataSources.length) {
      updateStatementNames([]);
      isInitialMount.current = false;
      if (selectedNode.data.nodeType === "binaryFeatures") {
        setMultiFilters((multiFilters) => [...multiFilters, {}]);
        updateStatementNames([{}]);
      }
    }
    if (modalForm === null) {
      initializeModalForm();
    }

    if (modalUI === null) {
      initializeModalUI();
    }
  }, []);

  useEffect(() => {
    if (modalUI) {
      handleSaveButtonDisabling();
    }
  }, [modalUI, modalForm, multiFilters, filterCfg]);

  useEffect(() => {
    if (modalUI) {
      initializeFilteredOptionsAndSelectAllCheckboxes();
    }
  }, [modalUI]);

  const initializeFilteredOptionsAndSelectAllCheckboxes = () => {
    let filteredOpts = {};
    const clonedSelectAllCheckboxes = {};
    modalUI.input.forEach((input) => {
      if (input.type === "chipsDropdown") {
        filteredOpts[input.identifier] = [];
        clonedSelectAllCheckboxes[input.identifier] = false;
      }
    });
    filteredOptions.current = filteredOpts;
    setSelectAllCheckboxes(clonedSelectAllCheckboxes);
  };

  const handleDataSourceAbsence = () => {
    if (
      connectedDataSources.length === 0 &&
      selectedNode.data.nodeKind !== "add"
    ) {
      updateAlertMessage("Datasource is not present");
      modalProps.closeModal(modalType);
      return true;
    }
    return false;
  };

  const getNodeLevelInfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        nodeType: selectedNode.data.nodeType,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        const nodeColsInfo = result.columnsInfo
          ? result.columnsInfo
          : nodeInfo.columnsInfo;
        initializeColumns(nodeColsInfo);
        if (result.maxCols) {
          setMaxColsAllowed(result.maxCols);
        }
        setWindowOptionsData(result);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        modalProps.closeModal(modalType);
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleWindowFeaturesSaveDisable = (inputs, modalForm) => {
    if (!modalForm) {
      return true;
    }
    for (let input of inputs) {
      if (
        ["startOffset", "offset", "endOffset"].includes(input.identifier) &&
        (modalForm[input.identifier] === "" ||
          modalForm[input.identifier] === undefined)
      ) {
        return true;
      } else if (
        input.identifier === "windCol" &&
        modalForm["includePrevNodeOps"]
      ) {
        continue;
      } else if (
        (!modalForm[input.identifier] ||
          modalForm[input.identifier] === "" ||
          (Array.isArray(modalForm[input.identifier]) &&
            modalForm[input.identifier]?.length === 0)) &&
        !(
          input.identifier === "description" ||
          input.identifier === "name" ||
          input.identifier === "endOffset" ||
          input.identifier === "startOffset"
        )
      ) {
        return true;
      } else if (Array.isArray(modalForm[input.identifier])) {
        // Condition to check if any array does not contain any empty strings
        const hasEmptyString = modalForm[input.identifier].some(
          (element) => element.length === 0
        );
        if (hasEmptyString) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSaveButtonDisabling = () => {
    let disableSave = false;
    const inputs = modalUI.input.filter(
      (inputField) =>
        !inputField.hidden &&
        inputField.type !== "multiFilter" &&
        inputField.type !== "binaryFilter" &&
        inputField.identifier !== "includePrevNodeOps"
    );
    if (nodeType === "windowFeatures") {
      disableSave = handleWindowFeaturesSaveDisable(inputs, modalForm);
    } else {
      disableSave = handleGeneralSaveButtonDisabling(
        inputs,
        modalForm,
        selectedNode
      );
    }
    if (!disableSave && multiFilters.length) {
      disableSave = handleFilterSaveDisable(filterCfg);
    }
    if (disableSave) {
      setIsSaveDisabled(true);
      return;
    }
    disableSave = false;
    setIsSaveDisabled(false);
  };

  const initializeModalForm = () => {
    if (nodeConfigurations[selectedNode.id]) {
      let clonedNodeConfigs = structuredClone(
        nodeConfigurations[selectedNode.id]
      );
      if (clonedNodeConfigs["includePrevNodeOps"] === undefined) {
        clonedNodeConfigs["includePrevNodeOps"] = false;
      }
      if (clonedNodeConfigs["endOffset"] === "") {
        clonedNodeConfigs["endOffset"] = 0;
      }
      setTimeout(() => updateModalForm(clonedNodeConfigs), 1);
      if (nodeConfigurations[selectedNode.id].filterPres) {
        setMultiFilters(nodeConfigurations[selectedNode.id].filterCfg);
        const initializedFilterInfo = initializeFilterInfo(
          nodeConfigurations,
          filterCfg,
          statementNames,
          selectedNode
        );
        updateStatementNames(initializedFilterInfo.newStatementNames);
        updateFilterCfg(initializedFilterInfo.newFilterCfg);
      }
    } else {
      currentModalUI?.input.forEach((input) => {
        if (input.type === "inputWithSelect") {
          input.input.forEach((innerInput) => {
            formData[innerInput.identifier] = "";
          });
        } else {
          if (input.identifier === "endOffset") {
            formData[input.identifier] = 0;
          } else {
            formData[input.identifier] = "";
          }
        }
      });
      setTimeout(() => updateModalForm(formData), 1);
    }
  };

  const initializeModalUI = () => {
    const allInputs = restoreDialogUI(
      currentModalUI,
      selectedNode,
      nodeConfigurations
    );
    currentModalUI.input = structuredClone(allInputs);
    setTimeout(() => {
      updateModalUI(currentModalUI);
    }, 1);
  };

  const initializeColumns = async (nodeColsInfo) => {
    for (let connectedNode of connectedNodes) {
      const features = await fetchFeatures(
        connectedNode.dSource,
        connectedNode.stageId,
        selectedProjectKey,
        selectedProjVersion,
        selectedFeatureGroup,
        updateIsLoading,
        updateAlertMessage
      );
      if (!features) {
        updateSnackbarMsg("Couldn't fetch features. Please save and try again");
        return;
      }
      columnsInfo.push(features);
    }
    const allColumnValues = prepareColumns(columnsInfo, nodeColsInfo);
    setAllColumns(allColumnValues.columns);
    setNumColumns(allColumnValues.allColumnsInfo.numerical);
    setCatColumns(allColumnValues.allColumnsInfo.categorical);
    setDateColumns(allColumnValues.allColumnsInfo.date);
    setMixedColumns(allColumnValues.allColumnsInfo.mixed);
    setCommonColumns(allColumnValues.commonFeatures);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (
      e?.target?.type === "text" &&
      (e.target.name === "name" ||
        e.target.name === "fileName" ||
        e.target.name === "opColName")
    ) {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    if (e?.target?.type === "text" && e.target.name === "description") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        return;
      }
    }

    if (e?.target?.type === "number") {
      if (e.target.name === "startOffset" || e.target.name === "endOffset") {
        if (!/^-?\d+$/.test(value)) {
          return;
        }
      } else {
        if (!/^[0-9]+$/.test(value)) {
          return;
        }
      }
    }
    const newFormData = structuredClone(modalForm);
    if (e.target.name === "falseValType") {
      newFormData["falseValue"] = "";
    }
    if (e?.target?.type === "number") {
      newFormData[e.target.name] = Number(value);
    } else {
      newFormData[e.target.name] = value;
    }
    updateModalForm(newFormData);
  };

  const handleWordCountChange = (evt) => {
    const value = evt.target.value.split(",");
    const newFormData = structuredClone(modalForm);
    newFormData[evt.target.name] = value;
    updateModalForm(newFormData);
  };

  const getUpdatedFormData = () => {
    const newFormData = structuredClone(modalForm);
    if (selectedNode.data.isStage) {
      if (multiFilters.length) {
        newFormData["filterPres"] = true;
        newFormData["filterCfg"] = [];
        multiFilters.forEach((item, index) => {
          let intermediateObj = {};
          intermediateObj["dataCfg"] = filterCfg[index];
          newFormData.filterCfg.push(intermediateObj);
        });
      } else {
        newFormData["filterPres"] = false;
      }
    }
    if (selectedNode.data.nodeType === "windowFeatures") {
      if (newFormData.includePrevNodeOps === "") {
        newFormData.includePrevNodeOps = false;
      }
      if (!newFormData.windCol || newFormData.includePrevNodeOps) {
        newFormData.windCol = [];
      }
    }
    return newFormData;
  };

  const getUpdatedNodeConfigurations = (newFormData) => {
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    newNodeConfigurations[selectedNode.id] = structuredClone(newFormData);
    newNodeConfigurations[selectedNode.id]["inputDs"] = connectedDataSources[0];
    if (!selectedNode.data.isStage) {
      newNodeConfigurations[selectedNode.id]["sourceInfo"] = {};
      for (let connectedNode of connectedNodes) {
        if (connectedNode.stageId) {
          newNodeConfigurations[selectedNode.id].sourceInfo[
            connectedNode.dSource
          ] = "output";
        } else {
          newNodeConfigurations[selectedNode.id].sourceInfo[
            connectedNode.dSource
          ] = "input";
        }
      }
    }
    return newNodeConfigurations;
  };

  const connectedDataSources = getConnectedDataSources(
    nodesFlow,
    selectedNode,
    getNode
  );
  const connectedNodes = getConnectedNodes(nodesFlow, selectedNode, getNode);

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    const dsMap = pipeline.clientMetaData.dsMap;
    if (nodeConfigs.name || nodeConfigs.fileName) {
      for (let nodeId of Object.keys(dsMap)) {
        if (
          selectedNode.id !== nodeId &&
          ((nodeConfigs.name && dsMap[nodeId] === nodeConfigs.name) ||
            (nodeConfigs.fileName && nodeConfigs.fileName === dsMap[nodeId]))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const checkColumnNameDuplicacy = (formData) => {
    if (nodeType === "binaryFeatures") {
      if (allColumns.includes(formData.opColName)) {
        return true;
      }
    }
    return false;
  };

  const checkSortOrderEquality = (formData) => {
    if (nodeType === "windowFeatures") {
      if (
        (formData.subtype === "moving" || formData.subtype === "default") &&
        formData.sortOrder.length !== formData.orderByCol.length
      ) {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newFormData = getUpdatedFormData();
    const duplicatedName = checkNodeNameDuplicacy(newFormData);
    if (duplicatedName) {
      updateSnackbarMsg("Name already taken. Please enter a unique name");
      return;
    }
    const duplicatedColumn = checkColumnNameDuplicacy(newFormData);
    if (duplicatedColumn) {
      updateSnackbarMsg(
        "Column name already exists. Please enter a unique output column name"
      );
      return;
    }
    const unequalSortOrder = checkSortOrderEquality(newFormData);
    if (unequalSortOrder) {
      updateSnackbarMsg("Column names to order by and sort order should align");
      return;
    }
    updateModalForm(newFormData);
    const newNodeConfigurations = getUpdatedNodeConfigurations(newFormData);
    updateNodeConfigurations(newNodeConfigurations);
    modalProps.unHighlightProblematicNode(selectedNode);
    setTimeout(
      () => modalProps.saveConfig(newNodeConfigurations, selectedNode.id),
      1
    );
    modalProps.closeModal(modalType);
  };

  const handleParentInputChange = (evt, mappedInput) => {
    let newValue;
    if (evt.target.type === "checkbox" && evt.target.name === "sourceType") {
      newValue = evt.target.checked ? "url" : "file";
    } else {
      newValue = evt.target.value;
    }
    if (mappedInput && mappedInput.length > 0 && modalUI) {
      const inputs = handleDependentInputs(
        modalUI,
        mappedInput,
        newValue,
        modalForm
      );
      updateModalUI({ ...modalUI, input: inputs });
    }
    const newFormData = structuredClone(modalForm);
    newFormData[evt.target.name] = newValue;
    updateModalForm(newFormData);
  };

  const getChipLabel = (opt) => {
    let tooltipTitle = null;
    if (opt === "exp_mean") {
      tooltipTitle = "Expanding mean";
    } else if (opt === "cum_sum") {
      tooltipTitle = "Cumulative sum";
    } else if (opt === "cum_count") {
      tooltipTitle = "Cumulative count";
    } else {
      tooltipTitle = opt;
    }
    return tooltipTitle;
  };

  const renderTags = (value, getTagProps) => {
    return value.map((option, index) => {
      let chipLabel = getChipLabel(option);
      return (
        <Tooltip key={option} title={chipLabel} placement="top">
          <Chip label={chipLabel} {...getTagProps({ index })} />
        </Tooltip>
      );
    });
  };

  const handleSortOrder = (elementAdded, newFormData, value) => {
    if (!newFormData.sortOrder) {
      newFormData["sortOrder"] = [];
    }
    if (elementAdded) {
      newFormData["sortOrder"].push("ascending");
    } else {
      let i = 0;
      while (i < modalForm["orderByCol"].length && i < value.length) {
        if (modalForm["orderByCol"][i] !== value[i]) {
          break;
        }
        i++;
      }
      newFormData["sortOrder"].splice(i, 1);
    }
    return newFormData;
  };

  const handleChipsAndSelectChange = (e, value, input) => {
    let inputId = input.identifier;
    let newFormData = structuredClone(modalForm);
    if (Array.isArray(value)) {
      if (checkIfMaxColsFilled(newFormData, input, inputId, value)) {
        return;
      }
      for (let index = 0; index < value.length; index++) {
        if (typeof value[index] === "object") {
          if (value[index]?.item) {
            value[index] = value[index]?.item;
          } else {
            value[index] = value[index]?.value;
          }
        }
      }
      if (inputId !== "sortOrder") {
        for (let index = 0; index < value.length - 1; index++) {
          if (value[index] === value[value.length - 1]) return;
        }
      }
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      if (value?.item) {
        value = value?.item;
      } else {
        value = value?.value;
      }
    }
    newFormData[inputId] = value;
    if (
      selectedNode.data.nodeType === "windowFeatures" &&
      inputId === "orderByCol" &&
      (newFormData.subtype === "moving" || newFormData.subtype === "default")
    ) {
      newFormData = handleSortOrder(e.target.outerText, newFormData, value);
    }
    updateModalForm(newFormData);
  };

  const checkIfMaxColsFilled = (newFormData, input, inputId, value) => {
    if (
      !selectedNode.data.isStage ||
      !maxColsAllowed ||
      !input.showColumns ||
      value.length <= maxColsAllowed
    ) {
      return false;
    }
    if (
      Array.isArray(newFormData[inputId]) &&
      newFormData[inputId].length > value.length
    ) {
      return false;
    }
    return true;
  };
  const handleInputInSelectChange = (
    e,
    value,
    inputId,
    isInputEvent = false
  ) => {
    const newFormData = structuredClone(modalForm);
    if (isInputEvent) {
      value = e.target.value;
    }
    newFormData[inputId] = value;
    updateModalForm(newFormData);
  };

  const handleToggleChange = (evt, inputId) => {
    const newFormData = structuredClone(modalForm);
    if (evt.target.checked) {
      newFormData[evt.target.name] = true;
    } else {
      newFormData[evt.target.name] = false;
    }
    updateModalForm(newFormData);
  };

  const handleAddFilter = () => {
    const newStatementNames = structuredClone(statementNames);
    newStatementNames[multiFilters.length] = {};
    updateStatementNames(newStatementNames);
    setMultiFilters((multiFilters) => [...multiFilters, {}]);
  };

  const handleRemoveFilter = (index) => {
    const newMultiFilters = [...multiFilters];
    newMultiFilters.splice(index, 1);
    setMultiFilters(newMultiFilters);
    const newFilterCfg = structuredClone(filterCfg);
    newFilterCfg.splice(index, 1);
    updateFilterCfg(newFilterCfg);
    const newStatementNames = structuredClone(statementNames);
    newStatementNames.splice(index, 1);
    updateStatementNames(newStatementNames);
  };

  const isOptionEqualToValue = (option, value, options) => {
    let nameOptions = [];
    let valueOptions = [];
    if (options[0]?.name) {
      nameOptions = options.map((opt) => opt.name);
    }
    if (options[0]?.value) {
      valueOptions = options.map((opt) => opt.value);
    }
    if (
      nameOptions.includes(value) ||
      valueOptions.includes(value) ||
      options.includes(value)
    ) {
      if (typeof option === "object") {
        return (
          option.value === value ||
          option.item === value ||
          option.name === value ||
          value === ""
        );
      } else {
        return option === value || value === "";
      }
    } else {
      if (selectedNode.data.nodeType === "statisticalTransformation") {
        const newModalForm = structuredClone(modalForm);
        newModalForm["statsInfo"] = [];
        updateModalForm(newModalForm);
      }
    }
    return value === "";
  };

  const getColumns = (columns) => {
    let groupedOptions = [];
    const num = columns.filter((col) => numColumns.includes(col));
    const cat = columns.filter((col) => catColumns.includes(col));
    const dat = columns.filter((col) => dateColumns.includes(col));
    const mixed = columns.filter((col) => mixedColumns.includes(col));
    num.forEach((col) => {
      let obj = { item: col, title: "Numerical" };
      groupedOptions.push(obj);
    });
    cat.forEach((col) => {
      let obj = { item: col, title: "Categorical" };
      groupedOptions.push(obj);
    });
    dat.forEach((col) => {
      let obj = { item: col, title: "Date" };
      groupedOptions.push(obj);
    });
    mixed.forEach((col) => {
      let obj = { item: col, title: "Mixed" };
      groupedOptions.push(obj);
    });
    return groupedOptions;
  };

  const getOptions = (input) => {
    if (input.showColumns && input.showAllCols) {
      return getColumns(allColumns);
    } else if (input.showColumns && input.showCommonCols) {
      return getColumns(commonColumns);
    } else if (input.showColumns && input.showNumCols) {
      return numColumns;
    } else if (input.showColumns && input.showCatCols) {
      return catColumns;
    } else if (input.showColumns && input.showDateCols) {
      return dateColumns;
    } else if (
      input.identifier === "statFuncs" &&
      windowOptionsData?.statFuncs?.options.length > 0
    ) {
      return windowOptionsData.statFuncs.options;
    } else if (
      input.identifier === "stat" &&
      windowOptionsData?.stat?.options?.length > 0
    ) {
      return windowOptionsData.stat.options;
    } else {
      return input.options;
    }
  };

  const getOptionLabel = (input, option) => {
    if (typeof option !== "object") {
      return option;
    } else if (
      input.identifier === "leftDataCols" ||
      input.identifier === "rightDataCols" ||
      input.showCommonCols ||
      input.showAllCols
    ) {
      return option.item;
    } else if (typeof option === "object") {
      if (option.value === "exp_mean") {
        return "Expanding mean";
      } else if (option.value === "cum_sum") {
        return "Cumulative sum";
      } else if (option.value === "cum_count") {
        return "Cumulative count";
      } else if (option.name) {
        return option.name;
      } else if (option.item) {
        return option.item;
      }
      return option.value;
    } else {
      return option;
    }
  };

  const showGroupBy = (input) => {
    return (
      (input.showColumns && input.showAllCols) ||
      (input.showColumns && input.showCommonCols) ||
      input.identifier === "leftDataCols" ||
      input.identifier === "rightDataCols" ||
      input.identifier === "falseValue"
    );
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const getSelectedValueLabel = (input) => {
    if (!modalForm || !modalForm[input.identifier]) {
      return "";
    }
    const value = modalForm[input.identifier];
    if (
      input.identifier === "statFuncs" &&
      windowOptionsData?.statFuncs?.options.length > 0
    ) {
      return getNameFromValue(value, windowOptionsData.statFuncs.options);
    } else if (
      input.identifier === "stat" &&
      windowOptionsData?.stat?.options.length > 0
    ) {
      return getNameFromValue(value, windowOptionsData.stat.options);
    } else if (
      input.identifier === "leftDataCols" ||
      input.identifier === "rightDataCols" ||
      input.showAllCols ||
      input.showCommonCols
    ) {
      if (Array.isArray(value)) {
        return value[0];
      } else {
        return value;
      }
    } else {
      const options = input.options;
      if (!options || !Array.isArray(options)) {
        return value;
      } else if (typeof options[0] !== "object") {
        return value;
      } else {
        return getNameFromValue(value, options);
      }
    }
  };

  const getNameFromValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.name : value;
  };

  const getChipDropdownValue = (inputId) => {
    if (modalForm && modalForm[inputId]) {
      if (typeof modalForm[inputId] === "string") {
        return [modalForm[inputId]];
      } else {
        return modalForm[inputId];
      }
    } else {
      return [];
    }
  };

  const getMultiSelectColumns = (input) => {
    if (input.showColumns && input.showAllCols) {
      return allColumns;
    } else if (input.showColumns && input.showCommonCols) {
      return commonColumns;
    } else if (input.showColumns && input.showNumCols) {
      return numColumns;
    } else if (input.showColumns && input.showCatCols) {
      return catColumns;
    } else if (input.showColumns && input.showDateCols) {
      return dateColumns;
    } else if (
      input.identifier === "statFuncs" &&
      windowOptionsData?.statFuncs?.options.length > 0
    ) {
      return windowOptionsData.statFuncs.options;
    } else if (
      input.identifier === "stat" &&
      windowOptionsData?.stat?.options?.length > 0
    ) {
      return windowOptionsData.stat.options;
    } else {
      return input.options;
    }
  };

  const updateFilteredOptions = (filteredOpt, inputId) => {
    filteredOptions.current[inputId] = filteredOpt;
    const clonedSelectAllCheckboxes = structuredClone(selectAllCheckboxes);
    if (clonedSelectAllCheckboxes[inputId]) {
      clonedSelectAllCheckboxes[inputId] = false;
      setSelectAllCheckboxes(clonedSelectAllCheckboxes);
    }
  };
  const filterMultiSelectOptions = (options, inputValue, input) => {
    if (options.length) {
      const filteredOpts = options.filter((option) => {
        if (option.item) {
          return option.item.toLowerCase().includes(inputValue.toLowerCase());
        } else if (option.value) {
          return option.value.toLowerCase().includes(inputValue.toLowerCase());
        } else {
          return option.toLowerCase().includes(inputValue.toLowerCase());
        }
      });
      updateFilteredOptions(filteredOpts, input.identifier);
      return filteredOpts;
    }
    return [];
  };

  const handleSelectAllCheckboxChange = (evt, autoCompleteRef, input) => {
    const newModalForm = structuredClone(modalForm);
    if (evt.target.checked) {
      if (filteredOptions.current[input.identifier].length) {
        const filteredOptVal = filteredOptions.current[input.identifier].map(
          (opt) => {
            if (opt.item) {
              return opt.item;
            } else if (opt.value) {
              return opt.value;
            }
            return opt;
          }
        );
        if (newModalForm[input.identifier].length) {
          filteredOptVal.forEach((opt) => {
            if (!newModalForm[input.identifier].includes(opt)) {
              newModalForm[input.identifier].push(opt);
            }
          });
        } else {
          newModalForm[input.identifier] = filteredOptVal;
        }
      } else {
        const allOptions = getMultiSelectColumns(input);
        let optionsVal = [];
        if (allOptions[0]?.value) {
          optionsVal = allOptions.map((opt) => opt.value);
        } else {
          optionsVal = allOptions;
        }
        newModalForm[input.identifier] = optionsVal;
      }
      autoCompleteRef[input.identifier].focus();
    } else {
      newModalForm[input.identifier] = [];
      filteredOptions.current[input.identifier] = [];
    }
    if (
      selectedNode.data.isStage &&
      maxColsAllowed &&
      input.showColumns &&
      newModalForm[input.identifier].length > maxColsAllowed
    ) {
      let colsArray = newModalForm[input.identifier];
      colsArray = colsArray.slice(0, maxColsAllowed);
      newModalForm[input.identifier] = colsArray;
    } else {
      const clonedSelectAllCheckboxes = structuredClone(selectAllCheckboxes);
      clonedSelectAllCheckboxes[input.identifier] = evt.target.checked;
      setSelectAllCheckboxes(clonedSelectAllCheckboxes);
    }
    updateModalForm(newModalForm);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Dialog
        open={selectedNode !== null}
        aria-labelledby="responsive-dialog-title"
        maxWidth={modalUI?.size}
        fullWidth
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <div>{modalUI?.header}</div>
          <div>
            {detailedMsgTooltipData[selectedNode.data.nodeType] ? (
              <IconButton>
                <CustomTooltip
                  title={detailedMsgTooltipData[selectedNode.data.nodeType]}
                >
                  <QuestionMarkIcon width={25} height={25}></QuestionMarkIcon>
                </CustomTooltip>
              </IconButton>
            ) : (
              <></>
            )}
            <IconButton onClick={() => modalProps.closeModal(modalType)}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          {modalUI?.type === "form" ? (
            <form>
              <Stack spacing={4} alignItems="stretch">
                {modalUI?.input.map((input, index) =>
                  input.type === "select" && !input.hidden ? (
                    <Autocomplete
                      key={input.identifier}
                      autoHighlight
                      size="small"
                      id="tags-outlined"
                      options={getOptions(input)}
                      getOptionLabel={(option) => getOptionLabel(input, option)}
                      autoComplete
                      includeInputInList
                      disableClearable
                      className="select-dropdown"
                      ListboxProps={{
                        style: { maxHeight: 300, overflow: "auto" },
                      }}
                      isOptionEqualToValue={(option, value) => {
                        const options =
                          input.identifier === "leftData"
                            ? connectedDataSources
                            : input.showColumns && input.showAllCols
                            ? allColumns
                            : input.showColumns && input.showCommonCols
                            ? commonColumns
                            : input.showColumns && input.showNumCols
                            ? numColumns
                            : input.showColumns && input.showCatCols
                            ? catColumns
                            : input.showColumns && input.showDateCols
                            ? dateColumns
                            : input.identifier === "colName"
                            ? allColumns
                            : input.options;
                        return isOptionEqualToValue(option, value, options);
                      }}
                      value={getSelectedValueLabel(input)}
                      onChange={(e, value) => {
                        handleChipsAndSelectChange(e, value, input);
                      }}
                      renderTags={renderTags}
                      groupBy={(option) => option.title}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader
                            padding={showGroupBy(input) ? "4px 10px" : "0px"}
                          >
                            {params.group}
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            backgroundColor: "#212b35",
                            color: "#d9d9d9",
                            borderRadius: "4px",
                            boxShadow:
                              "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                            "li:hover": {
                              color: "#69c2d2",
                            },
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <Tooltip
                          title={getSelectedValueLabel(input)}
                          placement="right"
                        >
                          <TextField
                            {...params}
                            size="small"
                            label={input.name}
                            placeholder="Select option"
                            autoComplete="off"
                            sx={textFieldStyles}
                          />
                        </Tooltip>
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Tooltip
                            title={getOptionLabel(input, option)}
                            placement="right"
                          >
                            <Typography noWrap>
                              {getOptionLabel(input, option)}
                            </Typography>
                          </Tooltip>
                        </li>
                      )}
                    />
                  ) : input.type === "radio" && !input.hidden ? (
                    <FormControl
                      key={index}
                      id={input.identifier}
                      sx={{ marginTop: "15px !important" }}
                    >
                      <FormLabel
                        id="row-radio-buttons-group-label"
                        sx={labelStyles}
                      >
                        {input.name}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name={input.identifier}
                        value={modalForm ? modalForm[input?.identifier] : ""}
                        onChange={(e) =>
                          handleParentInputChange(e, input.mappedWith)
                        }
                      >
                        {input.radios.map((radio, radioIndex) => (
                          <FormControlLabel
                            key={radioIndex}
                            value={radio.value}
                            control={<Radio style={{ color: "#69C2D2" }} />}
                            label={radio.name}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  ) : input.type === "inputWithSelect" && !input.hidden ? (
                    <Stack
                      direction="row"
                      gap="10px"
                      alignItems="center"
                      key={index}
                    >
                      {input.input.map((inputWithSelect, index) =>
                        inputWithSelect.type !== "select" ? (
                          <FormControl
                            key={index}
                            id={inputWithSelect.identifier}
                            size="small"
                            sx={{ width: "70%" }}
                          >
                            <TextField
                              type={inputWithSelect.type}
                              name={inputWithSelect.identifier}
                              label={inputWithSelect.name}
                              size="small"
                              autoComplete="off"
                              value={
                                modalForm
                                  ? modalForm[inputWithSelect?.identifier]
                                  : ""
                              }
                              onChange={handleChange}
                              variant="outlined"
                              sx={textFieldStyles}
                              required
                            />
                          </FormControl>
                        ) : (
                          <FormControl
                            key={index}
                            id={inputWithSelect.identifier}
                            sx={formControlStyles}
                            size="small"
                            className="w-30"
                          >
                            <InputLabel sx={labelStyles}>
                              {inputWithSelect.name}
                            </InputLabel>
                            <Select
                              placeholder="Select option"
                              name={inputWithSelect.identifier}
                              label={inputWithSelect.name}
                              size="small"
                              value={
                                modalForm &&
                                modalForm[inputWithSelect?.identifier]
                                  ? modalForm[inputWithSelect?.identifier]
                                  : ""
                              }
                              onChange={handleChange}
                              required
                              sx={selectStyles}
                              inputProps={{ sx: selectStyles }}
                              MenuProps={{ sx: selectStyles }}
                              autoWidth={true}
                            >
                              {inputWithSelect.options.map(
                                (option, optionIndex) => (
                                  <MenuItem
                                    key={optionIndex}
                                    value={option.value}
                                    sx={menuItemStyles}
                                  >
                                    <Tooltip
                                      title={option.name}
                                      placement="right"
                                    >
                                      <Typography noWrap>
                                        {option.name}
                                      </Typography>
                                    </Tooltip>
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        )
                      )}
                    </Stack>
                  ) : input.type === "chipsDropdown" &&
                    !input.hidden &&
                    input.identifier === "sortOrder" ? (
                    <Autocomplete
                      multiple
                      id="multiple-selection-autocomplete"
                      className="chips-dropdown"
                      options={input.options}
                      value={getChipDropdownValue(input.identifier)}
                      onChange={(e, value) =>
                        handleChipsAndSelectChange(e, value, input)
                      }
                      getOptionLabel={(option) => getOptionLabel(input, option)}
                      renderTags={renderTags}
                      componentsProps={{
                        paper: {
                          sx: {
                            backgroundColor: "#212b35",
                            color: "#d9d9d9",
                            borderRadius: "4px",
                            boxShadow:
                              "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                            "li:hover": {
                              color: "#69c2d2",
                            },
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label={input.name}
                          placeholder={input.name}
                          autoComplete="off"
                          sx={chipTextFieldStyles}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Tooltip
                            title={getOptionLabel(input, option)}
                            placement="right"
                          >
                            <Typography noWrap>
                              {getOptionLabel(input, option)}
                            </Typography>
                          </Tooltip>
                        </li>
                      )}
                    />
                  ) : input.type === "chipsDropdown" && !input.hidden ? (
                    <Stack direction="row" gap="20px">
                      <Autocomplete
                        key={index}
                        multiple
                        size="small"
                        id={input.identifier}
                        disableCloseOnSelect
                        autoHighlight
                        options={getOptions(input)}
                        getOptionLabel={(option) =>
                          getOptionLabel(input, option)
                        }
                        filterSelectedOptions
                        className="chips-dropdown"
                        ListboxProps={{
                          style: { maxHeight: 300, overflow: "auto" },
                        }}
                        isOptionEqualToValue={(option, value) => {
                          const options = getMultiSelectColumns(input);
                          return isOptionEqualToValue(option, value, options);
                        }}
                        value={getChipDropdownValue(input.identifier)}
                        onChange={(e, value) =>
                          handleChipsAndSelectChange(e, value, input)
                        }
                        renderTags={renderTags}
                        groupBy={(option) => option.title}
                        renderGroup={(params) => (
                          <li key={params.key}>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                          </li>
                        )}
                        filterOptions={(options, { inputValue }) =>
                          filterMultiSelectOptions(options, inputValue, input)
                        }
                        componentsProps={{
                          paper: {
                            sx: {
                              backgroundColor: "#212b35",
                              color: "#d9d9d9",
                              borderRadius: "4px",
                              boxShadow:
                                "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                              "li:hover": {
                                color: "#69c2d2",
                              },
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label={input.name}
                            placeholder={input.name}
                            autoComplete="off"
                            sx={chipTextFieldStyles}
                            inputRef={(input) => {
                              if (input) {
                                autoCompleteRef[input.id] = input;
                              }
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Tooltip
                              title={getOptionLabel(input, option)}
                              placement="right"
                            >
                              <Typography noWrap>
                                {getOptionLabel(input, option)}
                              </Typography>
                            </Tooltip>
                          </li>
                        )}
                      />
                      <FormControlLabel
                        className="checkbox-label"
                        key={"select-all-" + input.identifier}
                        control={
                          <Checkbox
                            sx={{
                              color: "#7ec0d0",
                              "&.Mui-checked": {
                                color: "#7ec0d0",
                              },
                            }}
                            checked={
                              selectAllCheckboxes &&
                              selectAllCheckboxes[input.identifier]
                                ? true
                                : false
                            }
                            onChange={(evt) =>
                              handleSelectAllCheckboxChange(
                                evt,
                                autoCompleteRef,
                                input
                              )
                            }
                          />
                        }
                        sx={{
                          height: "40px",
                        }}
                        label="Select all"
                      />
                    </Stack>
                  ) : input.type === "inputInSelect" && !input.hidden ? (
                    <FormControl
                      key={index}
                      id={input.identifier}
                      className="text-field"
                      size="small"
                    >
                      <Autocomplete
                        freeSolo
                        options={input?.options}
                        getOptionLabel={(option) => option}
                        value={modalForm ? modalForm[input?.identifier] : ""}
                        ListboxProps={{
                          style: { maxHeight: 300, overflow: "auto" },
                        }}
                        onChange={(e, value) =>
                          handleInputInSelectChange(e, value, input.identifier)
                        }
                        componentsProps={{
                          paper: {
                            sx: {
                              backgroundColor: "#212b35",
                              color: "#d9d9d9",
                              borderRadius: "4px",
                              boxShadow:
                                "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                              "li:hover": {
                                color: "#69c2d2",
                              },
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label={input.name}
                            placeholder={input.name}
                            sx={textFieldStyles}
                            autoComplete="off"
                            onChange={(e) =>
                              handleInputInSelectChange(
                                e,
                                "",
                                input.identifier,
                                true
                              )
                            }
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Tooltip
                              title={getOptionLabel(input, option)}
                              placement="right"
                            >
                              <Typography noWrap>
                                {getOptionLabel(input, option)}
                              </Typography>
                            </Tooltip>
                          </li>
                        )}
                      />
                    </FormControl>
                  ) : input.type === "countedWordsInput" && !input.hidden ? (
                    <FormControl
                      key={index}
                      id={input.identifier}
                      className="text-field"
                      size="small"
                    >
                      <TextField
                        type={input.type}
                        name={input.identifier}
                        size="small"
                        label={input.name}
                        value={modalForm ? modalForm[input?.identifier] : ""}
                        onChange={(evt) => handleWordCountChange(evt)}
                        error={
                          isWordCountTextFieldError &&
                          isSaveDisabled &&
                          input.identifier !== "ipList"
                        }
                        helperText={
                          isWordCountTextFieldError &&
                          isSaveDisabled &&
                          input.identifier !== "ipList"
                            ? "*Please write number of comma seprated values equal to above field"
                            : ""
                        }
                        autoComplete="off"
                        variant="outlined"
                        sx={textFieldStyles}
                        required
                      />
                    </FormControl>
                  ) : input.type === "binaryFilter" && !input.hidden ? (
                    <Stack sx={{ marginTop: "0px !important" }}>
                      <Filter
                        connectedDataSources={connectedDataSources}
                        allColumns={allColumns}
                        numColumns={numColumns}
                        catColumns={catColumns}
                        dateColumns={dateColumns}
                        mixedColumns={mixedColumns}
                        dsMap={pipeline.clientMetaData.dsMap}
                        isMultiFilter={true}
                        index={0}
                        handleRemoveFilter={handleRemoveFilter}
                        isBinaryFilter={true}
                        showRemove={false}
                      ></Filter>
                    </Stack>
                  ) : input.type === "multiFilter" &&
                    !input.hidden &&
                    !(
                      selectedNode.data.nodeType === "windowFeatures" &&
                      modalForm?.type === "win-lag" &&
                      modalForm?.subtype === "default"
                    ) ? (
                    <div key={index}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        className="add-filter-btn-container"
                        sx={{ marginTop: "1rem" }}
                      >
                        <Button
                          variant="contained"
                          onClick={handleAddFilter}
                          aria-labelledby="row-toggle-button-label"
                          name="filter-toggle"
                          sx={buttonStyles}
                        >
                          Add Filter
                        </Button>
                      </Stack>
                      <Stack>
                        {multiFilters.map((item, filterIndex) =>
                          selectedNode.data.nodeType === "binaryFeatures" &&
                          filterIndex === 0 ? (
                            <></>
                          ) : (
                            <div key={filterIndex} className="multi-filter">
                              <Filter
                                connectedDataSources={connectedDataSources}
                                allColumns={allColumns}
                                numColumns={numColumns}
                                catColumns={catColumns}
                                dateColumns={dateColumns}
                                mixedColumns={mixedColumns}
                                dsMap={pipeline.clientMetaData.dsMap}
                                isMultiFilter={true}
                                index={filterIndex}
                                handleRemoveFilter={handleRemoveFilter}
                                isBinaryFilter={
                                  selectedNode.data.nodeType ===
                                  "binaryFeatures"
                                }
                                showRemove={true}
                              ></Filter>
                            </div>
                          )
                        )}
                      </Stack>
                    </div>
                  ) : input.type === "multiFilter" &&
                    !input.hidden &&
                    selectedNode.data.nodeType === "windowFeatures" &&
                    modalForm?.type === "win-lag" &&
                    modalForm?.subtype === "default" ? (
                    <div key={index}></div>
                  ) : input.identifier === "falseValue" &&
                    modalForm &&
                    modalForm?.falseValType &&
                    modalForm?.falseValType === "column" ? (
                    <Autocomplete
                      key={input.identifier}
                      autoHighlight
                      size="small"
                      id="tags-outlined"
                      options={getColumns(allColumns)}
                      getOptionLabel={(option) => getOptionLabel(input, option)}
                      autoComplete
                      includeInputInList
                      disableClearable
                      className="select-dropdown"
                      ListboxProps={{
                        style: { maxHeight: 300, overflow: "auto" },
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return isOptionEqualToValue(option, value, allColumns);
                      }}
                      value={modalForm ? modalForm[input?.identifier] : ""}
                      onChange={(e, value) => {
                        handleChipsAndSelectChange(e, value, input);
                      }}
                      renderTags={renderTags}
                      groupBy={(option) => option.title}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader
                            padding={showGroupBy(input) ? "4px 10px" : "0px"}
                          >
                            {params.group}
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            backgroundColor: "#212b35",
                            color: "#d9d9d9",
                            borderRadius: "4px",
                            boxShadow:
                              "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                            "li:hover": {
                              color: "#69c2d2",
                            },
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <Tooltip
                          title={modalForm ? modalForm[input?.identifier] : ""}
                          placement="right"
                        >
                          <TextField
                            {...params}
                            size="small"
                            label="Column Name"
                            placeholder="Select option"
                            autoComplete="off"
                            sx={textFieldStyles}
                          />
                        </Tooltip>
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Tooltip
                            title={getOptionLabel(input, option)}
                            placement="right"
                          >
                            <Typography noWrap>
                              {getOptionLabel(input, option)}
                            </Typography>
                          </Tooltip>
                        </li>
                      )}
                    />
                  ) : !input.hidden && input.type === "toggle" ? (
                    <FormControl
                      key={input.identifier}
                      id={input.identifier}
                      className="toggle-input"
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Switch
                          onChange={(e) =>
                            handleToggleChange(e, input.identifier)
                          }
                          aria-labelledby="row-toggle-button-label"
                          name={input.identifier}
                          sx={toggleButtonStyles}
                          checked={
                            modalForm &&
                            (modalForm[input?.identifier] === "" ||
                              modalForm[input?.identifier] === false)
                              ? false
                              : true
                          }
                        />
                        <Typography>Include previous node output</Typography>
                      </Stack>
                    </FormControl>
                  ) : (
                    !input.hidden && (
                      <FormControl
                        key={index}
                        id={input.identifier}
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type={input.type}
                          name={input.identifier}
                          size="small"
                          label={input.name}
                          value={modalForm ? modalForm[input?.identifier] : ""}
                          onChange={handleChange}
                          variant="outlined"
                          sx={textFieldStyles}
                          autoComplete="off"
                          required={
                            !(
                              (input.identifier === "dbTable" &&
                                modalForm.rowSelInfo === "query") ||
                              input.identifier === "description" ||
                              (input.identifier === "name" &&
                                selectedNode.data.isStage)
                            )
                          }
                        />
                      </FormControl>
                    )
                  )
                )}
              </Stack>
            </form>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Stack direction="row" gap="10px" paddingX="40px" paddingY="20px">
            <Button
              variant="contained"
              onClick={handleSubmit}
              autoFocus
              sx={buttonStyles}
              disabled={isSaveDisabled || userAccessRestricted}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={() => modalProps.closeModal(modalType)}
              autoFocus
              sx={buttonStyles}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default MultiFilterDialog;
