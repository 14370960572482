import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./uploadWithTable.scss";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as FileUploadIcon } from "../../assets/icons/fileUploadIcon.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import {
  labelStyles,
  textFieldStyles,
  toggleButtonStyles,
} from "./uploadWithTableStyle.jsx";
import { handleDependentInputs } from "utils/utils";

const UploadWithTable = ({
  updateFileDetails,
  localUploadOptions,
  remoteUploadOptions,
}) => {
  const inputRef = useRef(null);
  const table = useSelector((state) => state.table);
  const modalForm = useSelector((state) => state.modalForm);
  const currentForm = useSelector((state) => state.currentFormType);
  const modalUI = useSelector((state) => state.modalUI);
  const dispatch = useDispatch();
  const switchRef = useRef(null);
  const { updateModalForm, updateModalUI } = bindActionCreators(
    actionCreators,
    dispatch
  );

  useEffect(() => {
    setTimeout(() => {
      handleCustomToggleButtonClick();
    }, 1);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    if (
      e?.target?.type === "text" &&
      (e.target.name === "name" ||
        e.target.name === "fileName" ||
        e.target.name === "opColName")
    ) {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    if (e?.target?.type === "text" && e.target.name === "description") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        return;
      }
    }
    if (e?.target?.type === "number" && !/^[0-9]+$/.test(value)) {
      return;
    }
    const newFormData = structuredClone(modalForm);
    if (e?.target?.type === "number") {
      newFormData[e.target.name] = Number(value);
    } else {
      newFormData[e.target.name] = value;
    }
    updateModalForm(newFormData);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileRemove = (inputId) => {
    updateFileDetails(null);
    const newFormData = structuredClone(modalForm);
    newFormData[inputId] = "";
    updateModalForm(newFormData);
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const handleFileChange = async (e, inputId, fileDropped = false) => {
    let selectedFile = null;
    if (fileDropped) {
      e.preventDefault();
      selectedFile = e.dataTransfer.files[0];
    } else {
      selectedFile = e.target.files[0];
    }
    updateFileDetails(selectedFile);
    const newFormData = structuredClone(modalForm);
    const newfileDetails = {
      name: selectedFile.name,
      type: selectedFile.type,
      size: selectedFile.size,
    };
    newFormData[inputId] = newfileDetails;
    updateModalForm(newFormData);
  };

  const handleCustomToggleButtonClick = () => {
    if (switchRef?.current?.firstChild) {
      switchRef.current.firstChild?.click();
      switchRef.current.firstChild?.click();
    }
  };

  const handleParentInputChange = (evt, mappedInput) => {
    let newValue;
    if (evt.target.type === "checkbox" && evt.target.name === "hostType") {
      newValue = evt.target.checked ? "remote" : "local";
    } else {
      newValue = evt.target.value;
    }
    if (mappedInput && mappedInput.length > 0 && modalUI) {
      const inputs = handleDependentInputs(
        modalUI,
        mappedInput,
        newValue,
        modalForm
      );
      updateModalUI({ ...modalUI, input: inputs });
    }
    const newFormData = structuredClone(modalForm);
    newFormData[evt.target.name] = newValue;
    updateModalForm(newFormData);
  };

  const handleSelectChange = (e, value, inputId, mappedInput = null) => {
    if (typeof value === "object") {
      if (value?.item) {
        value = value?.item;
      } else {
        value = value?.value;
      }
    }
    const newFormData = structuredClone(modalForm);
    newFormData[inputId] = value;
    if (value === "adls") {
      newFormData["pathType"] = "url";
    }
    updateModalForm(newFormData);
    if (mappedInput && mappedInput.length > 0 && modalUI) {
      const inputs = handleDependentInputs(
        modalUI,
        mappedInput,
        value,
        newFormData
      );
      updateModalUI({ ...modalUI, input: inputs });
    }
  };

  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      return option.name;
    } else {
      return option;
    }
  };

  const getSelectedValueLabel = (input) => {
    if (!modalForm || !modalForm[input.identifier]) {
      return "";
    }
    const value = modalForm[input.identifier];
    const options = getOptions(input);
    if (!options || !Array.isArray(options)) {
      return value;
    }
    if (typeof options[0] !== "object") {
      return value;
    }
    return getNameFromValue(value, options);
  };

  const getNameFromValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.name : value;
  };

  const getOptions = (input) => {
    if (input.identifier === "dataFormat" && localUploadOptions.length > 0) {
      return localUploadOptions;
    } else if (
      input.identifier === "storageType" &&
      remoteUploadOptions.length > 0
    ) {
      return remoteUploadOptions;
    } else {
      return input.options;
    }
  };

  return (
    <Box>
      {currentForm === "fileUpload" ? (
        modalUI.input.map((input, index) =>
          input.type === "radio" && !input.hidden ? (
            <FormControl
              key={input.identifier}
              id={input.identifier}
              className="file-upload-radio"
            >
              <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
                {input.name}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name={input.identifier}
                value={modalForm ? modalForm[input?.identifier] : ""}
                onChange={(e) => handleParentInputChange(e, input.mappedWith)}
              >
                {input.radios.map((radio, radioIndex) => (
                  <FormControlLabel
                    key={radio.value}
                    value={radio.value}
                    control={<Radio style={{ color: "#69C2D2" }} />}
                    label={radio.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : input.type === "toggle" ? (
            <FormControl
              key={input.identifier}
              id={input.identifier}
              className="file-upload-radio"
            >
              <FormLabel id="row-toggle-button-label" sx={labelStyles}>
                {input.name}
              </FormLabel>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Local</Typography>
                <Switch
                  onChange={(e) => handleParentInputChange(e, input.mappedWith)}
                  ref={switchRef}
                  aria-labelledby="row-toggle-button-label"
                  name={input.identifier}
                  sx={toggleButtonStyles}
                  checked={
                    modalForm && modalForm[input?.identifier] === "remote"
                      ? true
                      : false
                  }
                />
                <Typography>Remote</Typography>
              </Stack>
            </FormControl>
          ) : !input.hidden && input.type === "file" ? (
            <Box key={input.identifier}>
              <input
                type="file"
                onChange={(evt) => handleFileChange(evt, "file")}
                onClick={onInputClick}
                className="file-upload-input"
                ref={inputRef}
              />
              <div
                className="select-file-button"
                onClick={() => inputRef.current.click()}
              >
                <FileUploadIcon className="file-upload-icon"></FileUploadIcon>
                <span>Select File</span>
              </div>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="file-upload-area"
                onDragOver={handleDragOver}
                onDrop={(evt) => handleFileChange(evt, "file", true)}
              >
                <Stack gap="10px">
                  <Stack direction="row" gap="5px">
                    <p>
                      {modalForm.file
                        ? modalForm.file.name
                        : "Drop your files here"}
                    </p>
                    <Tooltip title="Remove file" placement="top">
                      {modalForm.file ? (
                        <Box
                          className="remove-file-icon"
                          onClick={(evt) => handleFileRemove("file")}
                        />
                      ) : (
                        <Box />
                      )}
                    </Tooltip>
                  </Stack>
                </Stack>
                {modalForm.file && <p>{modalForm.file.size} bytes</p>}
              </Stack>
            </Box>
          ) : (!input.hidden ||
              (input.name === "Url" &&
                modalForm.storageType === "adls" &&
                modalForm.hostType === "remote" &&
                modalForm.pathType === "url")) &&
            (input.type === "text" || input.type === "password") ? (
            <TextField
              key={input.identifier}
              m={2}
              type={input.type}
              label={input.name}
              variant="outlined"
              size="small"
              className="url-input"
              name={input.identifier}
              value={modalForm[input.identifier]}
              sx={textFieldStyles}
              autoComplete="off"
              onChange={handleChange}
            />
          ) : input.type === "select" && !input.hidden ? (
            <Autocomplete
              key={input.identifier}
              autoHighlight
              size="small"
              id="tags-outlined"
              options={getOptions(input)}
              getOptionLabel={(option) => getOptionLabel(option)}
              autoComplete
              includeInputInList
              disableClearable
              className="select-dropdown"
              ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
              isOptionEqualToValue={(option, value) => {
                return option.name === value || value === "";
              }}
              value={getSelectedValueLabel(input)}
              onChange={(e, value) => {
                handleSelectChange(
                  e,
                  value,
                  input.identifier,
                  input.mappedWith
                );
              }}
              componentsProps={{
                paper: {
                  sx: {
                    backgroundColor: "#212b35",
                    color: "#d9d9d9",
                    borderRadius: "4px",
                    boxShadow:
                      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                    "li:hover": {
                      color: "#69c2d2",
                    },
                  },
                },
              }}
              renderInput={(params) => (
                <Tooltip title={getSelectedValueLabel(input)} placement="right">
                  <TextField
                    {...params}
                    size="small"
                    label={input.name}
                    placeholder="Select option"
                    autoComplete="off"
                    sx={textFieldStyles}
                  />
                </Tooltip>
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Tooltip title={getOptionLabel(option)} placement="right">
                    <Typography noWrap>{getOptionLabel(option)}</Typography>
                  </Tooltip>
                </li>
              )}
            />
          ) : (
            <div />
          )
        )
      ) : currentForm === "table" ? (
        <TableContainer className="modal-table">
          <Table size={table?.size}>
            <TableHead>
              <TableRow>
                {table?.columns.map((column, columnIndex) => (
                  <TableCell className="table-cell" key={columnIndex}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table?.rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {table?.columns.map((col, colIndex) => (
                    <TableCell className="table-cell" key={colIndex}>
                      {table?.rows[rowIndex][col]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div />
      )}
    </Box>
  );
};

export default UploadWithTable;
