import axios from "axios";
import PollAPI from "./polling";
import { isEmpty } from "lodash";

let USER_MANAGEMENT_ENABLED = localStorage.getItem("USER_MANAGEMENT_ENABLED");
USER_MANAGEMENT_ENABLED =
  USER_MANAGEMENT_ENABLED === "true" || USER_MANAGEMENT_ENABLED === true
    ? true
    : false;
const USER_MANAGEMENT_URL = localStorage.getItem("USER_MANAGEMENT_URL");
const requestMap = {};
const api = axios.create({
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    if (config.url) {
      requestMap[config.url] = {
        currentApiUrl: config.url,
        currentPayload: config.data,
        currentApiMode: config.method,
        headers: config.headers,
        params: config.params,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    try {
      const resp = await getResponse(response);
      return resp;
    } catch (error) {
      console.log("Error in getResponse:", error);
      return Promise.reject(error);
    }
  },
  async (error) => {
    if (USER_MANAGEMENT_ENABLED) {
      const currentAPIInfo = requestMap[error.config.url] || {};
      if (
        isEmpty(currentAPIInfo) ||
        !error.config.url.includes(currentAPIInfo.currentApiUrl)
      ) {
        return;
      }
      if (error.response && error.response.status === 401) {
        if (currentAPIInfo.currentApiUrl.includes("actokrefresh")) {
          logout();
        }
        const refreshResponse = await refreshTokenAndContinueCall(
          currentAPIInfo
        );
        return refreshResponse;
      } else {
        console.log("Something went wrong. Please contact administrator");
      }
    }
    return Promise.reject(error);
  }
);

const refreshTokenAndContinueCall = async (currentAPIInfo) => {
  const apiUrl = localStorage.getItem("BASE_API_URL") + "actokrefresh";
  const payload = {};
  const result = await api.post(apiUrl, payload, {
    headers: currentAPIInfo.headers,
  });
  if (result.status === 200) {
    if (currentAPIInfo.currentApiMode === "post") {
      try {
        const response = await api.post(
          currentAPIInfo.currentApiUrl,
          currentAPIInfo.currentPayload,
          {
            headers: currentAPIInfo.headers,
          }
        );
        return response;
      } catch (error) {
        console.log(error);
      }
    } else if (currentAPIInfo.currentApiMode === "get") {
      try {
        let response = null;
        if (currentAPIInfo.currentApiUrl.includes("listprojects")) {
          response = await api.get(currentAPIInfo.currentApiUrl, {
            headers: currentAPIInfo.headers,
          });
        } else {
          response = await api.get(currentAPIInfo.currentApiUrl, {
            headers: currentAPIInfo.headers,
            withCredentials: true,
            params: currentAPIInfo.params,
            responseType: "blob",
          });
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    }
  } else {
    logout();
  }
};

const getResponse = async (response) => {
  if (response.status === 200 && response.data["polling_info"]) {
    const currentRoute = window.location.pathname;
    const url = response.config.url;
    const data = response.config.data;
    const resp = await processResponseForPolling(
      response.data,
      url,
      data,
      currentRoute,
      response.config
    );
    return resp;
  } else {
    return response;
  }
};

var processResponseForPolling = async function (
  response,
  url,
  data,
  receivedRoute,
  config
) {
  let currentRoute = window.location.pathname;
  if (currentRoute !== receivedRoute) {
    return "ROUTES_MISMATCHED";
  }
  if (response.status === 200) {
    if (response["polling_info"] && response.polling_info["polling"] === true) {
      let url = localStorage.getItem("BASE_API_URL") + "pollapi";
      const pollAPIResponse = await PollAPI(
        url,
        {
          action: response.polling_info.action,
          ...response.data.posts[0],
        },
        10,
        null,
        receivedRoute
      );
      if (pollAPIResponse?.action === "automl-action") {
        return pollAPIResponse;
      }
    }
    if (
      response["polling_info"] &&
      response.polling_info["is_final_call"] === false
    ) {
      return await getResponseAfterPolling(url, data, config);
    }
    return { status: 200, data: { data: { ...response.data }, status: 200 } };
  }
};

const getResponseAfterPolling = async (url, payload, config) => {
  try {
    if (config.method === "post") {
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const response = await api.post(url, payload, { headers: headers });
      return response;
    } else if (config.method === "get") {
      const headers = config.headers;
      const responseType = config.responseType;
      const payload = config.params;
      payload.timestamp = new Date().getTime();
      const response = await api.get(url, {
        headers: headers,
        withCredentials: true,
        params: payload,
        responseType: responseType,
      });
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

const logout = () => {
  const userManagementLink = document.createElement("a");
  userManagementLink.href = USER_MANAGEMENT_URL;
  userManagementLink.click();
};
export default api;
