export const updateTable = (tableData) => {
  return (dispatch) => {
    dispatch({
      type: "updateTable",
      payload: tableData,
    });
  };
};

export const updateModalForm = (modalFormData) => {
  return (dispatch) => {
    dispatch({
      type: "updateModalForm",
      payload: modalFormData,
    });
  };
};

export const updatecurrentFormType = (currentFormType) => {
  return (dispatch) => {
    dispatch({
      type: "updatecurrentFormType",
      payload: currentFormType,
    });
  };
};

export const updateModalUI = (modalUI) => {
  return (dispatch) => {
    dispatch({
      type: "updateModalUI",
      payload: modalUI,
    });
  };
};

export const updateNodeConfigurations = (nodeConfigurations) => {
  return (dispatch) => {
    dispatch({
      type: "updateNodeConfigurations",
      payload: nodeConfigurations,
    });
  };
};

export const updatePipeline = (pipeline) => {
  return (dispatch) => {
    dispatch({
      type: "updatePipeline",
      payload: pipeline,
    });
  };
};

export const updateDeleteDialogStatus = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateDeleteDialogStatus",
      payload: open,
    });
  };
};

export const updateConfigureVariablesDialogStatus = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateConfigureVariablesDialogStatus",
      payload: open,
    });
  };
};

export const updateSummaryDialogStatus = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateSummaryDialogStatus",
      payload: open,
    });
  };
};

export const updateSetUpNewJobDialogStatus = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateSetUpNewJobDialogStatus",
      payload: open,
    });
  };
};

export const updateViewRunningJobsDialogStatus = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateViewRunningJobsDialogStatus",
      payload: open,
    });
  };
};

export const updateVisitedStageNodes = (stageNodeId) => {
  return (dispatch) => {
    dispatch({
      type: "updateVisitedStageNodes",
      payload: stageNodeId,
    });
  };
};

export const updateErrorNodes = (errorNode) => {
  return (dispatch) => {
    dispatch({
      type: "updateErrorNodes",
      payload: errorNode,
    });
  };
};

export const updateShowCreationDialog = (show) => {
  return (dispatch) => {
    dispatch({
      type: "updateShowCreationDialog",
      payload: show,
    });
  };
};

export const updateIsLoading = (isLoading) => {
  return (dispatch) => {
    dispatch({
      type: "updateIsLoading",
      payload: isLoading,
    });
  };
};

export const updateIsPlaygroundLoading = (isPlaygroundLoading) => {
  return (dispatch) => {
    dispatch({
      type: "updateIsPlaygroundLoading",
      payload: isPlaygroundLoading,
    });
  };
};

export const updateAlertMessage = (alertMessage) => {
  return (dispatch) => {
    dispatch({
      type: "updateAlertMessage",
      payload: alertMessage,
    });
  };
};

export const updateProjectKey = (projectKey) => {
  return (dispatch) => {
    dispatch({
      type: "updateProjectKey",
      payload: projectKey,
    });
  };
};

export const updateProjVersion = (projVersion) => {
  return (dispatch) => {
    dispatch({
      type: "updateProjVersion",
      payload: projVersion,
    });
  };
};

export const updateFeatureGroup = (featureGroup) => {
  return (dispatch) => {
    dispatch({
      type: "updateFeatureGroup",
      payload: featureGroup,
    });
  };
};

export const updateCurrentProjectStatus = (projectStatus) => {
  return (dispatch) => {
    dispatch({
      type: "updateCurrentProjectStatus",
      payload: projectStatus,
    });
  };
};

export const updateFilterCfg = (filterCfg) => {
  return (dispatch) => {
    dispatch({
      type: "updateFilterCfg",
      payload: filterCfg,
    });
  };
};

export const updateShowImportConfigDialog = (showImportConfigDialog) => {
  return (dispatch) => {
    dispatch({
      type: "updateShowImportConfigDialog",
      payload: showImportConfigDialog,
    });
  };
};

export const updateIsPolling = (isPolling) => {
  return (dispatch) => {
    dispatch({
      type: "updateIsPolling",
      payload: isPolling,
    });
  };
};

export const updateStatementNames = (statementName) => {
  return (dispatch) => {
    dispatch({
      type: "updateStatementNames",
      payload: statementName,
    });
  };
};

export const updateIsAuthenticated = (isAuthenticated) => {
  return (dispatch) => {
    dispatch({
      type: "updateIsAuthenticated",
      payload: isAuthenticated,
    });
  };
};

export const updateCpuUsage = (cpuUsage) => {
  return (dispatch) => {
    dispatch({
      type: "updateCpuUsage",
      payload: cpuUsage,
    });
  };
};

export const updateMemoryUsage = (memoryUsage) => {
  return (dispatch) => {
    dispatch({
      type: "updateMemoryUsage",
      payload: memoryUsage,
    });
  };
};

export const updateNodesErrorMsg = (nodesErrorMsg) => {
  return (dispatch) => {
    dispatch({
      type: "updateNodesErrorMsg",
      payload: nodesErrorMsg,
    });
  };
};

export const updateShortMsgTooltipData = (tooltipData) => {
  return (dispatch) => {
    dispatch({
      type: "updateShortMsgTooltipData",
      payload: tooltipData,
    });
  };
};

export const updateDetailedMsgTooltipData = (tooltipData) => {
  return (dispatch) => {
    dispatch({
      type: "updateDetailedMsgTooltipData",
      payload: tooltipData,
    });
  };
};

export const updateValidationErrorNodes = (validationErrorNodes) => {
  return (dispatch) => {
    dispatch({
      type: "updateValidationErrorNodes",
      payload: validationErrorNodes,
    });
  };
};

export const updateErrorDialogStatus = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateErrorDialogStatus",
      payload: open,
    });
  };
};

export const updateAlertErrorDialogStatus = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateAlertErrorDialogStatus",
      payload: open,
    });
  };
};

export const updateShowBackupDialog = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateShowBackupDialog",
      payload: open,
    });
  };
};

export const updateEnableCopyMode = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateEnableCopyMode",
      payload: open,
    });
  };
};

export const updateShowRestoreDialog = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateShowRestoreDialog",
      payload: open,
    });
  };
};

export const updateShowProjectInfoDialog = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateShowProjectInfoDialog",
      payload: open,
    });
  };
};

export const updateSnackbarMsg = (msg) => {
  return (dispatch) => {
    dispatch({
      type: "updateSnackbarMsg",
      payload: msg,
    });
  };
};

export const updateSuccessAlertMessage = (msg) => {
  return (dispatch) => {
    dispatch({
      type: "updateSuccessAlertMessage",
      payload: msg,
    });
  };
};

export const updateInfoAlertMessage = (msg) => {
  return (dispatch) => {
    dispatch({
      type: "updateInfoAlertMessage",
      payload: msg,
    });
  };
};

export const updateModellingNodesData = (msg) => {
  return (dispatch) => {
    dispatch({
      type: "updateModellingNodesData",
      payload: msg,
    });
  };
};

export const updatePivotStatCfg = (pvtStatCfg) => {
  return (dispatch) => {
    dispatch({
      type: "updatePivotStatCfg",
      payload: pvtStatCfg,
    });
  };
};

export const updateShowPipelineExecutionDialog = (open) => {
  return (dispatch) => {
    dispatch({
      type: "updateShowPipelineExecutionDialog",
      payload: open,
    });
  };
};

export const updateCurrentProjectName = (projName) => {
  return (dispatch) => {
    dispatch({
      type: "updateCurrentProjectName",
      payload: projName,
    });
  };
};

export const updateCurrentFgDesc = (currentFgDesc) => {
  return (dispatch) => {
    dispatch({
      type: "updateCurrentFgDesc",
      payload: currentFgDesc,
    });
  };
};

export const updateCopyNodesList = (copyNodesList) => {
  return (dispatch) => {
    dispatch({
      type: "updateCopyNodesList",
      payload: copyNodesList,
    });
  };
};

export const updateShowPartialExecutionDialog = (show) => {
  return (dispatch) => {
    dispatch({
      type: "updateShowPartialExecutionDialog",
      payload: show,
    });
  };
};

export const updateStartDs = (startDs) => {
  return (dispatch) => {
    dispatch({
      type: "updateStartDs",
      payload: startDs,
    });
  };
};

export const updateCollapsedDs = (collapsedDsList) => {
  return (dispatch) => {
    dispatch({
      type: "updateCollapsedDs",
      payload: collapsedDsList,
    });
  };
};

export const updateShowDatasourcesDialog = (show) => {
  return (dispatch) => {
    dispatch({
      type: "updateShowDatasourcesDialog",
      payload: show,
    });
  };
};

export const updateGlobalCollapse = (isEnabled) => {
  return (dispatch) => {
    dispatch({
      type: "updateGlobalCollapse",
      payload: isEnabled,
    });
  };
};

export const updateFileConfigs = (cfg) => {
  return (dispatch) => {
    dispatch({
      type: "updateFileConfigs",
      payload: cfg,
    });
  };
};

export const updateApiConfigs = (cfg) => {
  return (dispatch) => {
    dispatch({
      type: "updateApiConfigs",
      payload: cfg,
    });
  };
};

export const updateDatabaseConfigs = (cfg) => {
  return (dispatch) => {
    dispatch({
      type: "updateDatabaseConfigs",
      payload: cfg,
    });
  };
};

export const updateExploredProject = (projInfo) => {
  return (dispatch) => {
    dispatch({
      type: "updateScrollToProject",
      payload: projInfo,
    });
  };
};
